import cn from "classnames";
import routes from "../config/routes";
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "../hooks/user";
import useAuth from "../hooks/use-auth";
import { getPermissions } from "./permissions";
import { SignoutIcon } from "src/components/icons/sign-out-icon";
import { useModalAction } from "src/components/modal-views/context";
import GlobalContext from "src/context/global-context";
import { UserType } from "src/api/types";
import { getCollaboratorProfileFilledFlag } from "src/api/token-util";
import L from "i18n-react";
import { PlaneIcon } from "src/components/icons/plane-icon";

interface NavLinkProps {
  href: string;
  setCollapse?: any;
  title: string;
  icon: React.ReactNode;
  activeIcon: React.ReactNode;
  isCollapse?: boolean;
}

function NavLink({
  href,
  setCollapse,
  icon,
  activeIcon,
  title,
  isCollapse,
}: NavLinkProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { unauthorize, getRefresh } = useAuth();
  const { mutate: logout } = useLogout();
  const [isMobile, setIsMobile] = useState(false);
  const { openModal } = useModalAction();

  const { setAlertText, setAlertTitle, setIsAlertOpened } =
    useContext(GlobalContext);

  const { getCurrentUser } = useAuth();
  const userType = getCurrentUser().user_type ?? "";

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(false);
    if (window.innerWidth < 640) {
      setIsMobile(true);
    }
  }

  const onLogout = () => {
    openModal("CONFIRM", { callback: onConfirmLogout });
  };

  const onConfirmLogout = () => {
    logout({ token: getRefresh() ?? "" });
    setTimeout(() => {
      navigate(routes.home);
    }, 1200);
  };

  const onClick = () => {
    isMobile && setCollapse(false);

    if (href == routes.logout) {
      onLogout();
    } else if (href == routes.estimates) {
      window.open("https://preventivi.withyouagency.it/login", "_blank");
    } else if (
      href == routes.database &&
      userType == UserType.COLLAB &&
      !getCollaboratorProfileFilledFlag()
    ) {
      setAlertTitle("Alert.error");
      setAlertText(String(L.translate("Errors.fill_profile_warning")));
      setIsAlertOpened(true);
    } else {
      navigate(href);
    }
  };
  return (
    <a
      key={title}
      onClick={onClick}
      className="my-0.5 flex items-center gap-1 px-4 py-4 xs:px-6 sm:my-1 sm:gap-1.5 sm:px-7 lg:gap-2 cursor-pointer relative"
    >
      {location.pathname.includes(href) && (
        <div className="absolute top-[2px] bottom-[2px] left-[8px] right-[8px] sm:left-[12px] sm:right-[12px] rounded-[8px] bg-blue-dark-400" />
      )}
      <span
        className={cn(
          "flex flex-shrink-0 items-center justify-start z-10",
          isCollapse ? "w-[20px] xl:w-auto" : "w-auto xl:w-[20px]"
        )}
      >
        {location.pathname.includes(href) ? activeIcon : icon}
      </span>
      <span
        className={cn(
          "font-gilroy font-medium text-[14px] dark:text-light-400 transition-all ease-out duration-300 truncate z-10",
          isCollapse
            ? "inline-flex xl:opacity-0 opacity-100"
            : "opacity-0 xl:inline-flex xl:opacity-100",
          location.pathname.includes(href)
            ? "text-white"
            : href == routes.logout
            ? "text-error"
            : "text-primary"
        )}
      >
        {title}
      </span>
    </a>
  );
}

export default function Sidebar({
  isCollapse,
  setCollapse,
  className = "sm:flex fixed bottom-0 z-40 sm:z-20 pt-16 sm:pt-[70px]",
}: //className = "sm:flex fixed bottom-0 pb-[100px] sm:pb-[124px] 2xl:pb-[184px] z-20 pt-16",
{
  isCollapse?: boolean;
  setCollapse?: any;
  className?: string;
}) {
  const location = useLocation();
  const { getCurrentUser } = useAuth();

  const userType = getCurrentUser().user_type ?? "";
  const permissions = getCurrentUser().permissions ?? "";
  const menus = getPermissions(getCurrentUser().user_type ?? "", permissions);

  return (
    <aside
      className={cn(
        "h-full flex-col justify-between overflow-y-auto bg-whole-gray text-dark-900 transition-all duration-300 p-4",
        isCollapse
          ? "sm:w-60 xl:w-[108px] w-60 left-0"
          : "sm:w-[108px] xl:w-60 w-60 -left-[240px] sm:left-0",
        className
      )}
    >
      <div className="flex h-full w-full flex-col overflow-y-auto scrollbar-hide">
        <nav className="flex flex-col mb-[108px] sm:mb-[90px] rounded-[8px] bg-white py-2">
          {userType == UserType.SUPER_ADMIN && (
            <>
              <NavLink
                key={"Estimations"}
                title={String(L.translate("Sidebar.estimates"))}
                setCollapse={setCollapse}
                href={routes.estimates}
                isCollapse={isCollapse}
                icon={<PlaneIcon className="h-[20px] w-[20px] text-primary" />}
                activeIcon={
                  <PlaneIcon className="h-[20px] w-[20px] text-white" />
                }
              />
              <div className="my-0.5 px-4 xs:px-6 sm:my-1 sm:px-7 xl:my-0.5">
                <hr className="h-[1px] border-t-0 bg-[#BCBEAD] opacity-50 dark:opacity-50" />
              </div>
            </>
          )}
          {menus.map((item) => (
            <NavLink
              key={item.title}
              title={item.title}
              setCollapse={setCollapse}
              href={item.link}
              isCollapse={isCollapse}
              icon={item.icon}
              activeIcon={item.activeIcon}
            />
          ))}
          <div className="my-0.5 px-4 xs:px-6 sm:my-1 sm:px-7 xl:my-0.5">
            <hr className="h-[1px] border-t-0 bg-[#BCBEAD] opacity-50 dark:opacity-50" />
          </div>
          <NavLink
            key={"Log out"}
            title={String(L.translate("Sidebar.logout"))}
            setCollapse={setCollapse}
            href={routes.logout}
            isCollapse={isCollapse}
            icon={<SignoutIcon className="h-[20px] w-[20px] text-error" />}
            activeIcon={
              <SignoutIcon className="h-[20px] w-[20px] text-white" />
            }
          />
        </nav>
      </div>
    </aside>
  );
}
