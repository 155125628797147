import Datepicker from "react-tailwindcss-datepicker";
import classNames from "classnames";
import { forwardRef, Fragment, useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Select } from "antd";
import L from "i18n-react";

interface SelectInputProps {
  label?: string;
  placeholder: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  options: string[];
  value?: string;
  onChange?: any;
  required?: boolean;
  showInfo?: boolean;
  disabled?: boolean;
  info?: string;
  maxCount?: number;
}
const SelectInput = forwardRef<HTMLDivElement, SelectInputProps>(
  (
    {
      label,
      placeholder,
      error,
      className,
      inputClassName = "bg-gray-modern-100",
      value,
      onChange,
      options,
      required = false,
      maxCount,
      showInfo = false,
      disabled = false,
      info,
      ...props
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState<string[]>([]);

    useEffect(() => {
      setSelectedValue(value ? value.split(",") : []);
    }, [value]);

    const suffix = (
      <>
        <span>
          {selectedValue.length} / {maxCount}
        </span>
        <DownOutlined />
      </>
    );

    return (
      <div className={className}>
        <label className="block text-16px font-gilroy font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
              {required && (
                <span className="text-[14px] text-error font-gilroy">* </span>
              )}
              {label}
            </span>
          )}
          <Select
            mode="multiple"
            disabled={disabled}
            maxCount={maxCount ?? undefined}
            value={selectedValue}
            style={{ width: "100%" }}
            onChange={(value) => {
              onChange && onChange(value.join(","));
              setSelectedValue(value);
            }}
            suffixIcon={maxCount && suffix}
            placeholder={`${L.translate("CommonForm.please_select")}`}
            options={options.map((item) => ({ value: item, label: item }))}
          />
        </label>
        {showInfo && (
          <span
            role="info"
            className="block pt-2 text-[12px] text-secondary font-gilroy font-medium"
          >
            {info}
          </span>
        )}
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[12px] text-error font-gilroy"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

SelectInput.displayName = "SelectInput";
export default SelectInput;
