import { atom, useAtom } from "jotai";
import {
  checkHasAuthToken,
  getAuthToken,
  removeAuthToken,
  setAuthToken,
  getAuthUserInfo,
  setAuthUserInfo,
  removeAuthUserInfo,
  setAuthRefresh,
  getAuthRefresh,
  removeAuthRefresh,
} from "../api/token-util";

const authorizationAtom = atom(checkHasAuthToken());
export default function useAuth() {
  const [isAuthorized, setAuthorized] = useAtom(authorizationAtom);
  return {
    setToken: setAuthToken,
    getToken: getAuthToken,
    getRefresh: getAuthRefresh,
    getCurrentUser: getAuthUserInfo,
    setCurrentUser: setAuthUserInfo,
    isAuthorized,
    authorize(token: string, refresh: string) {
      setAuthToken(token);
      setAuthRefresh(refresh);
      setAuthorized(true);
    },
    unauthorize() {
      setAuthorized(false);
      removeAuthUserInfo();
      removeAuthRefresh();
      removeAuthToken();
    },
  };
}
