import { FC, useContext, useEffect, useState, useRef } from "react";
import { CrossIcon } from "src/components/icons/cross-icon";
import { PlusSmallIcon } from "src/components/icons/plus-small-icon";
import Button from "src/components/ui/button";
import InputWithoutLabel from "src/components/ui/input-without-label";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useModalAction } from "src/components/modal-views/context";
import Zoom from "react-medium-image-zoom";
import UserPlaceholder from "../../assets/images/placeholder.png";
import useAuth from "src/hooks/use-auth";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import client from "../../api";
import { getErrorMessage, isValidEmail } from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import Input from "src/components/ui/input";
import { Photo } from "src/api/types";
import { SpinnerIcon } from "src/components/icons/spinner-icon";
import { LazyLoadImage } from "react-lazy-load-image-component";
import L from "i18n-react";
import ImageWithLoading from "src/components/ui/image-with-loading";

const AdminProfilePage: FC = () => {
  const { getCurrentUser, setCurrentUser } = useAuth();

  const schema = yup
    .object({
      first_name: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required")))
        .min(3),
      last_name: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required")))
        .min(3),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: getCurrentUser().first_name ?? "",
      last_name: getCurrentUser().last_name ?? "",
    },
  });

  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { openModal } = useModalAction();

  const { setAlertText, setAlertTitle, setIsAlertOpened, geoPosition } =
    useContext(GlobalContext);

  const avatarRef = useRef<HTMLInputElement>(null);
  const [avatarPreview, setAvatarPreview] = useState<any | null>(null);

  useEffect(() => {
    if (!isMounted) return;

    getInfo();
  }, [isMounted]);

  const onUpdateInfo = (data: FormData) => {
    updateProfile(data);
  };

  const { mutate: getInfo, isLoading } = useMutation(client.users.me, {
    onSuccess: (data) => {
      setCurrentUser(data.user, getCurrentUser().password);
    },
    onError: (error: any) => {
      setAlertTitle("Alert.error");
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        setAlertText(String(L.translate("Errors.failed_update_profile")));
      }
      setIsAlertOpened(true);
    },
  });

  const { mutate: updateProfile, isLoading: isUpdatingProfile } = useMutation(
    client.users.updateProfile,
    {
      onSuccess: (data) => {
        setCurrentUser(data.user, getCurrentUser().password);

        setAlertTitle("Alert.sucess");
        setAlertText("Success.updated_profile");
        setIsAlertOpened(true);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_update_profile")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: updateAvatar, isLoading: isUpdatingAvatar } = useMutation(
    client.users.updateAvatar,
    {
      onSuccess: (data) => {
        setAvatarPreview(null);
        setCurrentUser(data.user, getCurrentUser().password);
      },
      onError: (error: any) => {
        setAvatarPreview(null);

        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_update_profile")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onSelectAvatar = () => {
    if (isUpdatingAvatar) return;

    avatarRef.current?.click();
  };

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles?.length == 0) {
      return;
    }

    const url = URL.createObjectURL(selectedFiles![0]);
    setAvatarPreview(url);
    updateAvatar(selectedFiles![0]);
  };

  return (
    <div className="w-full h-full bg-whole-gray">
      <div className="bg-white p-4 rounded-[8px]">
        <div className="text-primary text-[24px] font-gilroy-semibold">
          {L.translate("CommonForm.edit_profile")}
          <div className="flex flex-col items-center justify-center gap-4 w-full sm:w-[80%] md:w-[70%] 2xl:w-[50%]">
            <div className="flex items-center justify-center mt-4 relative border-2 border-active rounded-full overflow-clip">
              <ImageWithLoading
                src={
                  avatarPreview
                    ? avatarPreview
                    : getCurrentUser().avatar
                    ? getCurrentUser().avatar
                    : UserPlaceholder
                }
                className="w-40 h-40 object-cover cursor-pointer bg-gray-modern-400"
                onClick={onSelectAvatar}
                isRoundedFull={true}
              />
              {isUpdatingAvatar && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur-[12px] rounded-full">
                  <SpinnerIcon className="h-auto w-5 animate-spin text-active" />
                </div>
              )}
              <input
                ref={avatarRef}
                className="hidden"
                type="file"
                onChange={handleAvatarChange}
                accept="image/png, image/jpeg"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-between gap-2 sm:gap-4">
              <div className="flex flex-col sm:flex-row items-start justify-center gap-2 sm:gap-4 w-full">
                <InputWithoutLabel
                  label={String(L.translate("CommonForm.first_name"))}
                  placeholder={String(L.translate("CommonForm.first_name"))}
                  className="w-full"
                  {...register("first_name")}
                  error={
                    errors.first_name && (errors.first_name.message as string)
                  }
                />
                <InputWithoutLabel
                  label={String(L.translate("CommonForm.last_name"))}
                  placeholder={String(L.translate("CommonForm.last_name"))}
                  className="w-full"
                  {...register("last_name")}
                  error={
                    errors.last_name && (errors.last_name.message as string)
                  }
                />
              </div>
            </div>
            <div className="mt-4 flex w-full items-center justify-end gap-4">
              <Button
                variant="validate"
                type="submit"
                className="rounded-[10px] font-gilroy text-[14px] font-bold leading-[24px] h-[44px] w-full sm:w-auto sm:!px-12"
                onClick={handleSubmit(onUpdateInfo)}
                disabled={!isValid || isUpdatingProfile}
                isLoading={isUpdatingProfile}
              >
                {L.translate("Base.submit")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProfilePage;
