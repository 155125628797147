import { FC, useContext, useEffect, useState } from "react";
import Button from "src/components/ui/button";
import InputWithoutLabel from "src/components/ui/input-without-label";
import PasswordWithoutLabel from "src/components/ui/password-without-label";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import client from "../../api";
import GlobalContext from "src/context/global-context";
import useAuth from "src/hooks/use-auth";
import L from "i18n-react";

const SettingsPage: FC = () => {
  const { getCurrentUser, setCurrentUser } = useAuth();
  const { setAlertText, setAlertTitle, setIsAlertOpened, geoPosition } =
    useContext(GlobalContext);

  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const schema = yup
    .object({
      email: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required")))
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          String(L.translate("ValidationErrors.invalid_email"))
        ),
      old_pass: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required")))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\,\_])(?=.{8,})/,
          String(L.translate("ValidationErrors.password_condition"))
        ),
      new_pass: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required")))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\,\_])(?=.{8,})/,
          String(L.translate("ValidationErrors.password_condition"))
        ),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      email: getCurrentUser().email,
      old_pass: getCurrentUser().password,
      new_pass: "",
    },
  });

  const newPassword = watch("new_pass");

  useEffect(() => {
    if (!isMounted) return;
  }, [isMounted]);

  const { mutate: changePassword, isLoading } = useMutation(
    client.users.changePassword,
    {
      onSuccess: (data) => {
        console.log(data);
        setCurrentUser(getCurrentUser(), newPassword);

        setAlertTitle("Alert.sucess");
        setAlertText("Success.changed_pass");
        setIsAlertOpened(true);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_change_pass")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onSubmit = (data: FormData) => {
    changePassword(data);
  };

  return (
    <div className="w-full h-full bg-whole-gray">
      <div className="bg-white p-4 rounded-[8px] mb-4">
        <div className="text-primary text-[24px] font-gilroy-semibold">
          {String(L.translate("CommonForm.change_your_password"))}
        </div>
        <div className="mt-4 flex w-full md:w-[50%] flex-col items-center justify-center gap-4">
          <InputWithoutLabel
            label={String(L.translate("CommonForm.email"))}
            className="w-full"
            readOnly
            {...register("email")}
            error={errors.email && (errors.email.message as string)}
          />
          <PasswordWithoutLabel
            label={String(L.translate("CommonForm.old_pass"))}
            className="w-full"
            {...register("old_pass")}
            error={errors.old_pass && (errors.old_pass.message as string)}
          />
          <PasswordWithoutLabel
            label={String(L.translate("CommonForm.new_pass"))}
            className="w-full"
            {...register("new_pass")}
            error={errors.new_pass && (errors.new_pass.message as string)}
          />
        </div>
        <div className="mt-4 flex w-full md:w-[50%] items-center justify-end gap-4">
          <Button
            variant="validate"
            type="submit"
            className="rounded-[10px] font-gilroy text-[14px] font-bold leading-[24px] h-[44px] w-full sm:w-auto sm:!px-12"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid || isLoading}
            isLoading={isLoading}
          >
            {L.translate("Base.submit")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
