export const ReceiptIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_17_1311)">
        <path
          d="M13.3333 0.408203H6.66667C5.562 0.409526 4.50296 0.848938 3.72185 1.63005C2.94073 2.41117 2.50132 3.47021 2.5 4.57487V19.5749C2.50012 19.7264 2.54157 19.8751 2.6199 20.0049C2.69823 20.1346 2.81047 20.2406 2.94453 20.3113C3.07859 20.382 3.22941 20.4148 3.38073 20.4062C3.53206 20.3976 3.67817 20.3479 3.80333 20.2624L5.55833 19.0632L7.31333 20.2624C7.45199 20.3573 7.61612 20.4081 7.78417 20.4081C7.95222 20.4081 8.11634 20.3573 8.255 20.2624L10.005 19.0632L11.755 20.2624C11.8937 20.3575 12.058 20.4084 12.2262 20.4084C12.3945 20.4084 12.5588 20.3575 12.6975 20.2624L14.4475 19.064L16.1975 20.2615C16.3226 20.3468 16.4685 20.3963 16.6197 20.4048C16.7708 20.4134 16.9214 20.3806 17.0553 20.31C17.1892 20.2394 17.3013 20.1337 17.3797 20.0042C17.458 19.8746 17.4996 19.7262 17.5 19.5749V4.57487C17.4987 3.47021 17.0593 2.41117 16.2782 1.63005C15.497 0.848938 14.438 0.409526 13.3333 0.408203ZM15.8333 17.9915L14.9167 17.3649C14.7778 17.2696 14.6134 17.2185 14.445 17.2185C14.2766 17.2185 14.1122 17.2696 13.9733 17.3649L12.2233 18.564L10.4733 17.3649C10.3346 17.2697 10.1703 17.2188 10.0021 17.2188C9.83386 17.2188 9.66958 17.2697 9.53083 17.3649L7.78083 18.564L6.03083 17.3649C5.89226 17.2701 5.7283 17.2194 5.56042 17.2194C5.39254 17.2194 5.22857 17.2701 5.09 17.3649L4.16667 17.9915V4.57487C4.16667 3.91183 4.43006 3.27594 4.8989 2.8071C5.36774 2.33826 6.00363 2.07487 6.66667 2.07487H13.3333C13.9964 2.07487 14.6323 2.33826 15.1011 2.8071C15.5699 3.27594 15.8333 3.91183 15.8333 4.57487V17.9915Z"
          fill="currentColor"
        />
        <path
          d="M13.3333 7.07489H6.66665C6.20641 7.07489 5.83331 7.44799 5.83331 7.90822C5.83331 8.36846 6.20641 8.74156 6.66665 8.74156H13.3333C13.7935 8.74156 14.1666 8.36846 14.1666 7.90822C14.1666 7.44799 13.7935 7.07489 13.3333 7.07489Z"
          fill="currentColor"
        />
        <path
          d="M11.6666 10.4082H6.66665C6.20641 10.4082 5.83331 10.7813 5.83331 11.2415C5.83331 11.7018 6.20641 12.0749 6.66665 12.0749H11.6666C12.1269 12.0749 12.5 11.7018 12.5 11.2415C12.5 10.7813 12.1269 10.4082 11.6666 10.4082Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_1311">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.408203)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
