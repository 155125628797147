import { forwardRef, useState, useEffect } from "react";
import classNames from "classnames";
import { CrossIcon } from "../icons/cross-icon";
import L from "i18n-react";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";

type InputWithRangeProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  className?: string;
  inputClassName?: string;
  currentValue: { from: number; to: number };
  onChange: any;
};

const InputWithRange = forwardRef<HTMLInputElement, InputWithRangeProps>(
  (
    {
      label,
      type = "number",
      className,
      inputClassName = "bg-gray-modern-100",
      currentValue,
      onChange,
      ...props
    },
    ref
  ) => {
    const isMounted = useIsMounted();

    const [fromValue, SetFromValue] = useState(
      currentValue.from == -1 ? "" : currentValue.from.toString()
    );
    const [toValue, setToValue] = useState(
      currentValue.to == -1 ? "" : currentValue.to.toString()
    );

    useEffect(() => {
      if (!isMounted) return;

      onChange({
        from: fromValue.length == 0 ? -1 : Number(fromValue),
        to: toValue.length == 0 ? -1 : Number(toValue),
      });
    }, [isMounted, fromValue, toValue]);

    return (
      <div className="w-full">
        {label && (
          <span className="block cursor-pointer pb-2 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
            {label}
          </span>
        )}
        <div
          className={classNames(
            'flex flex-row items-center gap-1 justify-between h-[44px] placeholder-gray-modern-400 border-none w-full appearance-none text-[14px] font-gilroy font-medium rounded-[8px] py-2 text-primary ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px]")',
            inputClassName
          )}
        >
          <label className="w-full block text-16px font-gilroy font-medium text-secondary relative">
            <input
              type={type}
              placeholder={String(L.translate("Base.from"))}
              value={fromValue}
              onChange={(e) => SetFromValue(e.target.value)}
              className={classNames(
                "h-[44px] border-none w-full appearance-none text-[14px] font-gilroy font-medium rounded-[8px] px-3 py-2 text-primary ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px]",
                fromValue.length > 0 && classNames("!pr-10")
              )}
            />
            {fromValue.length > 0 && (
              <button
                className="absolute bottom-0 right-0 h-[44px] w-[44px] flex items-center justify-center"
                onClick={() => SetFromValue("")}
              >
                <CrossIcon className="w-3 h-3 text-secondary" />
              </button>
            )}
          </label>
          <label className="block text-16px font-gilroy font-medium text-secondary relative text-center w-4 flex-shrink-0">
            {`~`}
          </label>
          <label className="w-full block text-16px font-gilroy font-medium text-secondary relative">
            <input
              type={type}
              placeholder={String(L.translate("Base.to"))}
              value={toValue}
              onChange={(e) => setToValue(e.target.value)}
              className={classNames(
                "h-[44px] border-none w-full appearance-none text-[14px] font-gilroy font-medium rounded-[8px] px-3 py-2 text-primary ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px]",
                toValue.length > 0 && classNames("!pr-10")
              )}
            />
            {toValue.length > 0 && (
              <button
                className="absolute bottom-0 right-0 h-[44px] w-[44px] flex items-center justify-center"
                onClick={() => setToValue("")}
              >
                <CrossIcon className="w-3 h-3 text-secondary" />
              </button>
            )}
          </label>
        </div>
      </div>
    );
  }
);

InputWithRange.displayName = "InputWithRange";
export default InputWithRange;
