import { FC, useContext, useEffect, useState, useRef } from "react";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import InputWithoutLabel from "src/components/ui/input-without-label";
import L from "i18n-react";
import { CollaboratorBidType, CollaboratorType } from "src/lib/data-types";
import { useModalAction } from "src/components/modal-views/context";
import { Event, SearchQueryOptions } from "src/api/types";
import { ConfigValue } from "src/config";
import ImageWithLoading from "src/components/ui/image-with-loading";
import { Carousel } from "react-responsive-carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Spin } from "antd";
import GlobalContext from "src/context/global-context";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import client from "../../api";
import { calculateDistance, capitalizeFirstLetter } from "src/lib/utils";
import { ArrowLeftIcon } from "src/components/icons/arrow-left-icon";
import Button from "src/components/ui/button";
import { CheckIcon } from "src/components/icons/check-icon";
import CitySelection from "src/components/ui/city-selection";
import moment from "moment";
import Dropdown from "src/components/ui/dropdown";
import { Langauges, Langauges_IT, ShoeSizes } from "src/lib/constants";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { ClusteredMapMarkers } from "src/components/map/clutered-markers";
import { CloseCircleIcon } from "src/components/icons/close-circle-icon";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import routes from "src/config/routes";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import CityPinImage from "../../assets/images/city_pin.png";

const SelectCollaboratorsPage = () => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { openModal } = useModalAction();
  const navigate = useNavigate();

  const location = useLocation();
  const event: Event | undefined = location.state;

  const [selectedCity, setSelectedCity] = useState<
    | {
        lat: number;
        lng: number;
      }
    | undefined
  >(undefined);

  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<CollaboratorType[]>([]);
  const [filteredDataSource, setFilteredDataSource] = useState<
    CollaboratorType[]
  >([]);

  const {
    setAlertText,
    setAlertTitle,
    setIsAlertOpened,
    geoPosition,
    language,
  } = useContext(GlobalContext);

  const [selectedCollabs, setSelectedCollabs] = useState<number[]>([]);
  const [selectedCollabID, setSelectedCollabID] = useState(-1);

  const [selectedLocation, setSelectedLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [selectedHeight, setSelectedHeight] = useState("");
  const [selectedAge, setSelectedAge] = useState("");
  const [selectedLang, setSelectedLang] = useState(-1);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [selectedPhotos, setSelectedPhotos] = useState<string[]>([]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 960 ? true : false);
  }

  const { mutate: getAll, isLoading } = useMutation(
    client.events.getAvailableCollaborators,
    {
      onSuccess: (data) => {
        setTotal(data.results.length);
        setDataSource(
          data.results.map((item, index) => {
            let lat: number = 0;
            let lng: number = 0;

            if (item.location) {
              const positions = item.location.split(",");
              if (positions.length >= 2) {
                lat = Number(positions[0]);
                lng = Number(positions[1]);
              }
            }

            return {
              key: index,
              id: item.user,
              first_name: item.info.first_name ?? "",
              last_name: item.info.last_name ?? "",
              name: `${capitalizeFirstLetter(
                item.info.first_name ?? ""
              )} ${capitalizeFirstLetter(item.info.last_name ?? "")}`,
              user_id: item.user,
              lat: lat,
              lng: lng,
              user_email: item.info.email,
              cv: item.cv,
              main_photo: item.main_photo,
              photo2: item.photo2,
              photo3: item.photo3,
              photo4: item.photo4,
              photo5: item.photo5,
              photo6: item.photo6,
              photo7: item.photo7,
              photo8: item.photo8,
              photo9: item.photo9,
              sex: item.info.sex,
              height: item.info.height,
              shoe_size: item.info.shoe_size,
              size: item.info.italian_size,
              instagram: item.info.instagram,
              telephone_number: item.info.telephone_number,
              info: item.info,
            };
          })
        );
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_load_collaborator")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    if (!isMounted) return;
    if (!event) return;

    getAll(event!.id);
  }, [isMounted]);

  useEffect(() => {
    setSelectedLocation(null);

    let tempResult = [...dataSource];

    if (searchKeyword.trim().length > 0) {
      tempResult = tempResult.filter((item) =>
        `${item.first_name ?? ""} ${item.last_name ?? ""}`
          .toLowerCase()
          .includes(searchKeyword.trim().toLowerCase())
      );
    }

    if (selectedCity) {
      tempResult = tempResult.filter(
        (item) =>
          calculateDistance(
            selectedCity!.lat,
            selectedCity!.lng,
            item.lat,
            item.lng
          ) < 30
      );
    }

    if (selectedLang > 0) {
      tempResult = tempResult.filter((item) => {
        if (!item.info) return false;

        if ("italian" in item.info && selectedLang == 1) return true;
        if ("english" in item.info && selectedLang == 2) return true;
        if ("french" in item.info && selectedLang == 3) return true;
        if ("german" in item.info && selectedLang == 4) return true;
        if ("spanish" in item.info && selectedLang == 5) return true;

        return false;
      });
    }

    if (selectedAge.length > 0) {
      tempResult = tempResult.filter((item) => {
        if (!item.info) return false;

        const birthDate = moment(item.info.date_of_birth).format("yyyy");
        const age = `${moment().diff(birthDate, "years")}`;
        if (age == selectedAge) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (selectedHeight.length > 0) {
      tempResult = tempResult.filter((item) => {
        if (!item.info) return false;

        if (item.info.height == selectedHeight) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (selectedCity) {
      setSelectedLocation(selectedCity);
    } else {
      tempResult.length > 0 &&
        setSelectedLocation({
          lat: tempResult[0].lat,
          lng: tempResult[0].lng,
        });
    }

    setTimeout(() => setSelectedLocation(null), 600);

    setFilteredDataSource(tempResult);
  }, [
    dataSource,
    searchKeyword,
    selectedCity,
    selectedHeight,
    selectedAge,
    selectedLang,
  ]);

  const onBack = () => {
    navigate(-1);
  };

  const onReset = () => {
    setSelectedCollabs([]);
    setSelectedLocation(null);
    setSelectedCollabID(-1);
  };

  const onPickedDates = () => {
    setTimeout(() => onBack(), 300);
  };

  const onConfirm = () => {
    if (!event) return;

    openModal("COLLAB_PICK_DATES", {
      payload: { event, selectedCollabs, fromDatabase: false },
      callback: onPickedDates,
    });
  };

  const resetSelected = () => {
    setSelectedCollabs([]);
    setSelectedLocation(null);
    setSelectedCollabID(-1);
  };

  const onTappedItem = (nID: number) => {
    if (selectedCollabs.includes(nID)) {
      setSelectedCollabs([...selectedCollabs].filter((value) => value != nID));

      setSelectedLocation(null);
      setSelectedCollabID(-1);
    } else {
      setSelectedCollabs([...selectedCollabs, nID]);

      const selectedItem = filteredDataSource.filter((item) => item.id == nID);
      if (selectedItem.length > 0) {
        /*
        moveLocation(
          { lat: selectedItem[0].lat, lng: selectedItem[0].lng },
          selectedItem[0]
        );
        */
        setSelectedCollabID(nID);
        setSelectedLocation({
          lat: selectedItem[0].lat,
          lng: selectedItem[0].lng,
        });
      }
    }

    setTimeout(() => setSelectedLocation(null), 600);
  };

  const makeMapView = () => {
    return (
      <APIProvider apiKey={ConfigValue.GOOGLE_MAPS_API}>
        <Map
          mapId={ConfigValue.GOOGLE_MAP_ID}
          defaultCenter={{ lat: geoPosition.lat, lng: geoPosition.lng }}
          center={selectedLocation ? selectedLocation : undefined}
          defaultZoom={15}
          zoom={selectedLocation ? 12 : undefined}
          gestureHandling={"greedy"}
          style={{
            width: "100%",
            height: isMobile ? "80vh" : "calc(100vh - 210px)",
            borderTopRightRadius: isMobile ? 0 : 8,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: isMobile ? 8 : 0,
            overflow: "clip",
          }}
          disableDefaultUI
        >
          {filteredDataSource && (
            <ClusteredMapMarkers
              isBid={true}
              items={filteredDataSource}
              selectedID={selectedCollabID}
              resetSelectedID={resetSelected}
            />
          )}
          {selectedCity && (
            <AdvancedMarker
              position={{ lat: selectedCity!.lat, lng: selectedCity!.lng }}
            >
              <div className="flex items-center justify-center w-[48px] h-[48px]">
                <img
                  src={CityPinImage}
                  className="w-[48px] h-[48px] object-cover"
                />
              </div>
            </AdvancedMarker>
          )}
        </Map>
      </APIProvider>
    );
  };

  return (
    <div className="w-full h-full bg-whole-gray">
      <div className="flex flex-col items-start justify-center bg-white p-4 rounded-[8px] mb-4">
        <button
          className="flex flex-row gap-2 items-center h-[44px] bg-white text-active rounded-[8px] group"
          onClick={onBack}
        >
          <ArrowLeftIcon className="w-[20px] h-[20px] text-active group-hover:text-main" />
          <span className="text-active group-hover:text-main font-[14px] font-gilroy-semibold">
            {L.translate("Base.back")}
          </span>
        </button>
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between w-full">
          <div className="text-primary text-[24px] font-gilroy-semibold">
            {`${L.translate("CommonForm.select_collaborators")} (${
              selectedCollabs.length
            })`}
          </div>
          <div className="flex flex-row w-full sm:w-auto items-center justify-between sm:justify-center gap-4">
            <Button
              variant="solid"
              className={`${
                selectedCollabs.length > 0 ? "bg-active" : "bg-[#BCBEAD]"
              } rounded-[8px] text-[12px] font-gilroy leading-[24px] h-[44px]  text-white hover:bg-main`}
              onClick={onReset}
              disabled={selectedCollabs.length == 0}
            >
              <CloseCircleIcon className="w-[16px] h-[16px] text-white" />
              <span className="uppercase font-gilroy-semibold text-[14px]">
                {L.translate("Base.reset")}
              </span>
            </Button>
            <Button
              variant="solid"
              className={`${
                selectedCollabs.length > 0 ? "bg-active" : "bg-[#BCBEAD]"
              } rounded-[8px] text-[12px] font-gilroy leading-[24px] h-[44px]  text-white hover:bg-main`}
              onClick={onConfirm}
              disabled={selectedCollabs.length == 0}
            >
              <CheckIcon className="w-[16px] h-[16px] text-white" />
              <span className="uppercase font-gilroy-semibold text-[14px]">
                {L.translate("Base.confirm")}
              </span>
            </Button>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row w-full mt-4 relative">
          {isLoading && (
            <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
          )}

          <div
            className={`${
              isMobile ? "rounded-t-[8px]" : "rounded-l-[8px]"
            } flex flex-col w-full sm:w-[40%] md:w-[30%] max-w-[300px] flex-shrink-0 bg-gray-modern-50 overflow-y-auto scrollbar-hide`}
            style={{ height: isMobile ? "80vh" : "calc(100vh - 240px)" }}
          >
            <InputWithoutLabel
              placeholder={String(
                L.translate("CommonForm.search_collaborators")
              )}
              inputClassName="bg-gray-modern-100 rounded-none focus:!ring-0"
              value={searchKeyword}
              showClose={searchKeyword.length > 0}
              clickedClose={() => setSearchKeyword("")}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
            {filteredDataSource.length == 0 && (
              <label className="mt-4 w-full text-center font-gilroy-semibold text-[14px] text-secondary">
                {L.translate("CommonForm.no_collaborators")}
              </label>
            )}

            {filteredDataSource.map((item, index) => (
              <div
                key={item.id}
                className={`${
                  selectedCollabs.includes(item.id) && "bg-active"
                } ${
                  index < filteredDataSource.length - 1 &&
                  "border-b-[1px] border-success-100"
                } flex flex-row items-center gap-2 p-4 hover:bg-main hover:bg-opacity-30 group`}
                onClick={() => onTappedItem(item.id)}
              >
                {/* <ImageWithLoading
                  isRoundedFull={true}
                  src={item.main_photo}
                  className="flex-shrink-0 w-[48px] h-[48px] object-cover rounded-full"
                /> */}
                <div className="flex flex-col items-start gap-2 sm:gap-0 lg:gap-2">
                  <label
                    className={`${
                      selectedCollabs.includes(item.id)
                        ? "text-white"
                        : "text-primary"
                    } text-[16px] font-gilroy-bold group-hover:text-white`}
                  >
                    {item.name}
                  </label>
                  <div className="flex flex-row sm:flex-col lg:flex-row gap-4 sm:gap-0 lg:gap-4">
                    <div className="flex flex-row items-center justify-start gap-1">
                      <label
                        className={`${
                          selectedCollabs.includes(item.id)
                            ? "text-white"
                            : "text-secondary"
                        } text-[12px] font-gilroy group-hover:text-white`}
                      >
                        {`${L.translate(
                          "CollaboratorProfile.height_without_cm"
                        )}:`}
                      </label>
                      <label
                        className={`${
                          selectedCollabs.includes(item.id)
                            ? "text-white"
                            : "text-primary"
                        } text-[12px] font-gilroy-semibold group-hover:text-white`}
                      >
                        {item.height}cm
                      </label>
                    </div>
                    <div className="flex flex-row items-center justify-start gap-1">
                      <label
                        className={`${
                          selectedCollabs.includes(item.id)
                            ? "text-white"
                            : "text-secondary"
                        } text-[12px] font-gilroy group-hover:text-white`}
                      >
                        {`${L.translate("CollaboratorProfile.size")}:`}
                      </label>
                      <label
                        className={`${
                          selectedCollabs.includes(item.id)
                            ? "text-white"
                            : "text-primary"
                        } text-[12px] font-gilroy-semibold group-hover:text-white`}
                      >
                        {Number(item.size)
                          ? `${
                              ShoeSizes.filter(
                                (eachSize) => eachSize.id == Number(item.size)!
                              )[0].name
                            }`
                          : "-"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="relative w-full h-full">
            <div className="absolute top-2 right-2 left-2 z-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <CitySelection
                required
                placeholder={String(L.translate("CollaboratorProfile.city"))}
                onPicked={(location?: { lat: number; lng: number }) =>
                  setSelectedCity(location)
                }
              />
              <InputWithoutLabel
                inputClassName="bg-white shadow-card"
                placeholder={String(L.translate("CollaboratorProfile.height"))}
                type="number"
                className="w-full"
                showClose={selectedHeight.length > 0}
                value={selectedHeight}
                clickedClose={() => setSelectedHeight("")}
                onChange={(e) => setSelectedHeight(e.target.value.trim())}
              />
              <InputWithoutLabel
                inputClassName="bg-white shadow-card"
                placeholder={String(L.translate("Base.age"))}
                type="number"
                className="w-full"
                showClose={selectedAge.length > 0}
                clickedClose={() => setSelectedAge("")}
                value={selectedAge}
                onChange={(e) => setSelectedAge(e.target.value.trim())}
              />
              <DropdownWithoutLabel
                inputClassName="bg-white shadow-card"
                placeholder={String(L.translate("Base.language"))}
                className="w-full"
                data={[
                  { id: 0, name: String(L.translate("Base.all")) },
                  ...(language == "EN" ? Langauges : Langauges_IT),
                ]}
                onChange={(e: any) => setSelectedLang(Number(e))}
              />
            </div>
            {makeMapView()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCollaboratorsPage;
