import { FC, useContext, useEffect, useState } from "react";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import L from "i18n-react";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Spin } from "antd";
import { useMutation } from "react-query";
import client from "../../api";
import GlobalContext from "src/context/global-context";
import { useNavigate } from "react-router-dom";
import useAuth from "src/hooks/use-auth";
import { Event } from "src/api/types";
import Textarea from "src/components/ui/textarea";
import Button from "src/components/ui/button";
import { CollabDataType } from "src/lib/data-types";
import { Empty, MenuProps, Space, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { capitalizeFirstLetter } from "src/lib/utils";
import { CrossIcon } from "src/components/icons/cross-icon";
import { PlusIcon } from "src/components/icons/plus-icon";
import CheckboxOptions from "src/components/ui/checkbox-options";
import { useModalAction } from "src/components/modal-views/context";
import CollabsSelectionListPage from "./collab-list";
import JobAdsPastList from "src/components/ads/list";
import { MAX_CHARACTERS_LENGTH } from "src/lib/constants";

const JobAdsPage: FC = () => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const navigate = useNavigate();
  const { openModal } = useModalAction();

  const { setAlertText, setAlertTitle, setIsAlertOpened, geoPosition } =
    useContext(GlobalContext);

  const { getCurrentUser } = useAuth();
  const permissions = getCurrentUser().permissions ?? "";
  const userType = getCurrentUser().user_type ?? "";

  const [bSelectingCollabs, setSelectingCollabs] = useState(false);

  const [events, setEvents] = useState<Event[]>([]);
  const [details, setDetails] = useState("");

  const [dataSource, setDataSource] = useState<CollabDataType[]>([]);
  const [selectedCollabs, setSelectedCollabs] = useState<number[]>([]);
  const [isRefreshing, setRefreshing] = useState(false);

  const schema = yup
    .object({
      event_id: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      details: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      send_option: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      collaborators: yup
        .number()
        .positive()
        .required(String(L.translate("ValidationErrors.field_required"))),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    reset,
    watch,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { mutate: getEvents, isLoading: isLoadingEvents } = useMutation(
    client.events.all,
    {
      onSuccess: (data) => {
        setEvents(data.results);
      },
    }
  );

  useEffect(() => {
    if (!isMounted) return;

    getEvents("ads");
  }, [isMounted]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  const { mutate: sendAds, isLoading } = useMutation(client.ads.send, {
    onSuccess: (data) => {
      setAlertTitle("Alert.sucess");
      setAlertText("Success.sent_job_ads");
      setIsAlertOpened(true);

      setTimeout(() => {
        reset();
        setDetails("");
        setDataSource([]);
        setSelectedCollabs([]);

        setRefreshing(!isRefreshing);
      }, 300);
    },
    onError: (error: any) => {
      setAlertTitle("Alert.error");
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        setAlertText(String(L.translate("Errors.failed_send_ads")));
      }
      setIsAlertOpened(true);
    },
  });

  const onSend = (data: FormData) => {
    let updatedDetails = data.details;
    if (data.send_option == 0) {
      updatedDetails = data.details.replace(/\n/g, "\\n");
    }

    sendAds({
      ...data,
      process_details: updatedDetails,
      user_ids: dataSource.map((item) => item.id),
    });
  };

  const onUpdateList = (selectedUsers: CollabDataType[]) => {
    setSelectingCollabs(false);
    setSelectedCollabs([]);

    const tempList = [...dataSource, ...selectedUsers];
    setDataSource(tempList.map((item, index) => ({ ...item, key: index })));

    setValue("collaborators", 1, { shouldValidate: true });
  };

  const onAddCollab = () => {
    setSelectingCollabs(true);
  };

  const onDelete = () => {
    const tempList = [...dataSource].filter(
      (item) => !selectedCollabs.includes(item.id)
    );
    setDataSource(tempList.map((item, index) => ({ ...item, key: index })));
    tempList.length == 0 &&
      setValue("collaborators", -1, { shouldValidate: true });

    setTimeout(() => setSelectedCollabs([]), 300);
  };

  const columns: ColumnsType<CollabDataType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.first_name")}
        </p>
      ),
      dataIndex: "first_name",
      className: "w-auto",
      key: "first_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.last_name")}
        </p>
      ),
      dataIndex: "last_name",
      className: "w-auto",
      key: "last_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CollaboratorProfile.sex")}
        </p>
      ),
      dataIndex: "sex",
      className: "w-auto",
      key: "sex",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value == 0 ? L.translate("Base.male") : L.translate("Base.female")}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("Base.age")}
        </p>
      ),
      dataIndex: "age",
      className: "w-auto",
      key: "age",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("Base.language")}
        </p>
      ),
      dataIndex: "language",
      className: "w-auto",
      key: "langauge",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CollaboratorProfile.height")}
        </p>
      ),
      dataIndex: "height",
      className: "w-auto",
      key: "height",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CollaboratorProfile.city")}
        </p>
      ),
      dataIndex: "city",
      className: "w-auto",
      key: "city",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<CollabDataType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[14px] font-medium">
          {L.translate("Base.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="flex flex-col sm:flex-row items-start justify-between w-full">
              <div className="flex flex-col items-start justify-between w-full">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.name")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {`${capitalizeFirstLetter(
                      selData.first_name
                    )} ${capitalizeFirstLetter(selData.last_name)}`}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CollaboratorProfile.sex")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.sex == 0
                      ? L.translate("Base.male")
                      : L.translate("Base.female")}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("Base.language")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.language}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CollaboratorProfile.height")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.height}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-start justify-between w-full">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CollaboratorProfile.city")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.city}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("Base.age")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.age}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    const tempKeys = newSelectedRowKeys.map((item) => Number(item));
    setSelectedCollabs(tempKeys.map((item) => dataSource[item].id));
    //setSelectedCollabs(newSelectedRowKeys.map((item) => Number(item)));
  };

  const getSelectedRowKeys = () => {
    const rowKeys = selectedCollabs.map((item) =>
      dataSource.findIndex((eachDatasource) => eachDatasource.id == item)
    );
    return rowKeys;
  };

  const rowSelection = {
    selectedRowKeys: getSelectedRowKeys(),
    onChange: onSelectChange,
  };

  return bSelectingCollabs ? (
    <CollabsSelectionListPage
      onBack={() => setSelectingCollabs(false)}
      currentCollabs={dataSource.map((item) => item.id)}
      onConfirmCallback={onUpdateList}
    />
  ) : (
    <div className="w-full h-full bg-whole-gray">
      <div className="bg-white p-4 rounded-[8px] mb-4">
        <div className="text-primary text-[24px] font-gilroy-semibold">
          {L.translate("Sidebar.job_ads")}
        </div>
        <div className="mt-4 flex flex-col lg:flex-row gap-4 lg:gap-8 items-start justify-center w-full">
          <div className="w-full lg:w-[40%] relative">
            <Controller
              name="event_id"
              control={control}
              render={({ field }) => (
                <DropdownWithoutLabel
                  label={String(L.translate("CommonForm.event_name"))}
                  className="w-full"
                  required
                  data={events}
                  error={errors.event_id && (errors.event_id.message as string)}
                  {...field}
                />
              )}
            />
            <div className="mt-4 flex w-full flex-col sm:flex-row items-center justify-between gap-4">
              <div className="relative w-full">
                <label className="block cursor-pointer text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
                  <span className="block cursor-pointer pb-2 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
                    <span className="text-[14px] text-error font-gilroy">
                      *{" "}
                    </span>
                    {L.translate("CommonForm.description")}
                  </span>
                </label>
                <Textarea
                  placeholder={String(L.translate("CommonForm.description"))}
                  className="w-full mb-4 font-gilroy"
                  value={details}
                  onChange={(e) => {
                    if (e.target.value.length <= 1000) {
                      setDetails(e.target.value);
                      setValue("details", e.target.value, {
                        shouldValidate: true,
                      });
                    }
                  }}
                />
                <span className="absolute bottom-1 right-1 block cursor-pointer text-[12px] font-gilroy font-normal text-secondary">
                  {`${
                    details.length === 0 ? 1000 : 1000 - details.length
                  } ${L.translate("CommonForm.characters")}`}
                </span>
              </div>
            </div>
            <Controller
              name="send_option"
              control={control}
              render={({ field }) => (
                <CheckboxOptions
                  label={String(L.translate("CommonForm.send_option"))}
                  required
                  options={[
                    String(L.translate("Base.whatsapp")),
                    String(L.translate("Base.email")),
                  ]}
                  error={
                    errors.send_option && (errors.send_option.message as string)
                  }
                  {...field}
                />
              )}
            />
            <Button
              variant="validate"
              type="submit"
              className="mt-8 rounded-[10px] uppercase font-gilroy text-[14px] font-bold leading-[24px] h-[44px] w-full"
              onClick={handleSubmit(onSend)}
              disabled={!isValid || isLoading}
              isLoading={isLoading}
            >
              {L.translate("Base.submit")}
            </Button>
          </div>
          <div className="w-full relative">
            <div className="flex flex-col md:flex-row items-center justify-between gap-4 md:gap-0">
              <div className="flex flex-row items-center justify-between md:justify-start gap-2 sm:gap-4 w-full md:w-auto">
                <div className="text-secondary text-[18px] font-gilroy-semibold">
                  <span className="text-[14px] text-error font-gilroy">* </span>
                  {L.translate("Sidebar.collaborators")}
                </div>
                <Button
                  variant="icon"
                  className="bg-active p-2 rounded-[8px] hover:bg-main text-white"
                  onClick={onAddCollab}
                >
                  <PlusIcon className="text-white w-4 h-4" />
                </Button>
              </div>
              <Button
                variant="solid"
                className={`${
                  selectedCollabs.length > 0 ? "bg-error" : "bg-[#BCBEAD]"
                } rounded-[8px] text-[12px] font-gilroy leading-[24px] !h-[36px] text-white hover:bg-error-100`}
                onClick={onDelete}
              >
                <CrossIcon className="w-4 h-4 text-white" />
                <span className="text-[14px] font-gilroy">
                  {L.translate("Base.delete")}
                </span>
              </Button>
            </div>
            <Table
              locale={{
                emptyText: (
                  <Empty
                    description={
                      <p className="font-gilroy-semibold text-secondary text-[14px]">
                        {L.translate("Base.no_data")}
                      </p>
                    }
                  />
                ),
              }}
              rowSelection={rowSelection}
              showSorterTooltip={false}
              columns={isMobile ? mobileColumns : columns}
              dataSource={dataSource}
              pagination={false}
            />
          </div>
        </div>
        <JobAdsPastList isRefreshing={isRefreshing} />
      </div>
    </div>
  );
};

export default JobAdsPage;
