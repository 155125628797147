import { FC, useContext, useEffect, useState } from "react";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import L from "i18n-react";
import Button from "src/components/ui/button";
import { CalendarPlusIcon } from "src/components/icons/calendar-plus-icon";
import { useNavigate } from "react-router-dom";
import routes from "src/config/routes";
import InvoiceBlocks from "src/components/invoice/block-info";
import InputWithoutLabel from "src/components/ui/input-without-label";
import MonthPicker from "src/components/ui/month-picker";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import { InvoiceStatus, LIST_PAGE_SIZE } from "src/lib/constants";
import { InvoiceSearchQueryOptions, CurrentInvoiceStatus } from "src/api/types";
import { Dropdown, Empty, MenuProps, Space, Spin, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { InvoiceDataType } from "src/lib/data-types";
import { useMutation } from "react-query";
import client from "../../api";
import GlobalContext from "src/context/global-context";
import { makeColumnHeader } from "src/components/table-columns/make-column-header";
import {
  capitalizeFirstLetter,
  extractIDs,
  getInvoiceStatusColor,
  getInvoiceStatusName,
  makeFormattedDateString,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import { CreditCardIcon } from "src/components/icons/credit-card-icon";
import { DownloadIcon } from "src/components/icons/download-icon";
import { generateInvoiceXML } from "src/lib/generate-xml";
import moment from "moment";
import { LeafHeartIcon } from "src/components/icons/leaf-heart-icon";
import { useModalAction } from "src/components/modal-views/context";
import { CrossIcon } from "src/components/icons/cross-icon";

const InvoicesPage: FC = () => {
  const isMounted = useIsMounted();
  const { openModal } = useModalAction();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState<InvoiceSearchQueryOptions>({
    per_page: LIST_PAGE_SIZE,
    page: 1,
    sort: "id",
    direction: "desc",
    date: "",
    due_date: "",
    event_name: "",
    client_name: "",
    status: -1,
  });
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<InvoiceDataType[]>([]);
  const [currentStatus, setCurrentStatus] = useState({
    total_invoice: 0,
    paid_invoice: 0,
    pending_invoice: 0,
    archived_invoice: 0,
  });
  const { setAlertText, setAlertTitle, setIsAlertOpened, geoPosition } =
    useContext(GlobalContext);

  const [selectedItems, setSelectedItems] = useState<{
    [key: number]: number[];
  }>({});

  useEffect(() => {
    if (!isMounted) return;

    const delayDebounceFn = setTimeout(() => {
      getStatus();
      getList(searchParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    isMounted,
    searchParams.page,
    searchParams.sort,
    searchParams.direction,
    searchParams.date,
    searchParams.due_date,
    searchParams.event_name,
    searchParams.client_name,
    searchParams.status,
  ]);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 868 ? true : false);
  }

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  const onCreateNewInvoice = () => {
    navigate(routes.createInvoice);
  };

  const onPageChange = (page: number, pageSize: number) => {
    setSearchParams({ ...searchParams, page });
  };

  const { mutate: getStatus, isLoading: isLoadingStatus } = useMutation(
    client.invoices.status,
    {
      onSuccess: (data) => {
        setCurrentStatus(data.status);
      },
    }
  );

  const { mutate: markAsPaid, isLoading: isMarkingPaid } = useMutation(
    client.invoices.markAsPaid,
    {
      onSuccess: (data) => {
        //setSelectedInvoices([]);
        setSelectedItems({});
        getList(searchParams);
        getStatus();
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(
            String(L.translate("Errors.failed_mark_invoice_as_paid"))
          );
        }
        setIsAlertOpened(true);
      },
    }
  );

  /*
  const { mutate: archiveProcess, isLoading: isArchiving } = useMutation(
    client.events.archive,
    {
      onSuccess: (data) => {
        getList(searchParams);
        getStatus();
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_archive_invoice")));
        }
        setIsAlertOpened(true);
      },
    }
  );
  */

  const { mutate: archiveInvoice, isLoading: isArchiving } = useMutation(
    client.invoices.archive,
    {
      onSuccess: (data) => {
        getList(searchParams);
        getStatus();
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_archive_invoice")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: deleteInvoice, isLoading: isDeleting } = useMutation(
    client.invoices.delete,
    {
      onSuccess: (data) => {
        getList(searchParams);
        getStatus();
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_delete_invoice")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getList, isLoading } = useMutation(client.invoices.list, {
    onSuccess: (data) => {
      setTotal(data.count);
      setDataSource(
        data.results.map((item, index) => ({
          key: index,
          id: item.id,
          client_name: item.client.name,
          event_id: item.event.id,
          event_name: item.event.name,
          invoice_no: item.event.invoice_no ?? "-",
          client: item.client,
          amount: item.amount,
          date: item.date,
          due_date: item.due_date,
          status: item.status,
          created_at: item.created_at,
          updated_at: item.updated_at,
        }))
      );
    },
    onError: (error: any) => {
      setAlertTitle("Alert.error");
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        setAlertText(String(L.translate("Errors.failed_load_invoices")));
      }
      setIsAlertOpened(true);
    },
  });

  const onMarkAsPaid = () => {
    markAsPaid({ invoice_ids: extractIDs(selectedItems) });
  };

  const onDelete = (nInvoiceID: number) => {
    openModal("CONFIRM", { payload: nInvoiceID, callback: onConfirmDelete });
  };

  const onConfirmDelete = (nInvoiceID: number) => {
    deleteInvoice(nInvoiceID);
  };
  const onArchiveEvent = (nInvoiceID: number) => {
    const selectedItems = dataSource.filter((item) => item.id == nInvoiceID);
    if (selectedItems.length == 0) return;

    openModal("CONFIRM", { payload: nInvoiceID, callback: onConfirmArchive });
  };

  const onConfirmArchive = (nInvoiceID: number) => {
    //delete process
    const selectedItems = dataSource.filter((item) => item.id == nInvoiceID);
    if (selectedItems.length == 0) return;

    archiveInvoice(nInvoiceID);
    /*
    archiveProcess({
      event_id: selectedItems[0].event_id,
      invoice_id: nInvoiceID,
    });
    */
  };

  const onDownloadXML = (nInvoiceID: number) => {
    const selectedItems = dataSource.filter((item) => item.id == nInvoiceID);
    if (selectedItems.length == 0) return;

    const xmlContent = generateInvoiceXML(selectedItems[0]);
    const blob = new Blob([xmlContent], { type: "application/xml" });

    // Step 3: Create a link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${moment().format("DDMMYY-HH.mm")}-invoice.xml`; // Specify the file name

    // Append to the body
    document.body.appendChild(link);
    link.click(); // Trigger the download
    document.body.removeChild(link); // Clean up
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    const tempKeys = newSelectedRowKeys.map((item) => Number(item));
    const selInvoiceIDs = tempKeys.map((item) => dataSource[item].id);
    let currentSelectedItems = { ...selectedItems };
    currentSelectedItems[searchParams.page] = selInvoiceIDs;
    setSelectedItems(currentSelectedItems);
    //setSelectedInvoices(tempKeys.map((item) => dataSource[item].id));
  };

  const getSelectedRowKeys = () => {
    const rowKeys = (selectedItems[searchParams.page] ?? []).map((item) =>
      dataSource.findIndex((eachDatasource) => eachDatasource.id == item)
    );
    return rowKeys;
  };

  const rowSelection = {
    selectedRowKeys: getSelectedRowKeys(),
    onChange: onSelectChange,
  };

  const columns: ColumnsType<InvoiceDataType> = [
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.invoice_id")),
          "invoice_no",
          searchParams,
          setSearchParams
        ),
      dataIndex: "invoice_no",
      className: "w-auto",
      key: "invoice_no",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.client")),
          "client_name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "client_name",
      className: "w-auto",
      key: "client_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.invoice_descr")),
          "event_name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "event_name",
      className: "w-auto",
      key: "event_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          `€.${L.translate("CommonForm.total")}`,
          "amount",
          searchParams,
          setSearchParams
        ),
      dataIndex: "amount",
      className: "w-[120px]",
      key: "amount",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {makeNumberWithFloatingDigits(value, 2)}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.date")),
          "date",
          searchParams,
          setSearchParams
        ),
      dataIndex: "date",
      className: "w-[120px]",
      key: "date",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? makeFormattedDateString(value) : "-"}
          </p>
        </div>
      ),
    },
    /*
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.due_date")),
          "due_date",
          searchParams,
          setSearchParams
        ),
      dataIndex: "due_date",
      className: "w-[120px]",
      key: "due_date",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    */
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.status")}
        </p>
      ),
      dataIndex: "status",
      className: "w-[100px]",
      key: "status",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p
            className="font-gilroy text-white text-[13px] sm:text-[14px] rounded-[8px] px-2"
            style={{ backgroundColor: getInvoiceStatusColor(value) }}
          >
            {L.translate(getInvoiceStatusName(value))}
          </p>
        </div>
      ),
    },
    {
      title: () => <p className="hidden" />,
      dataIndex: "action",
      className: "w-[50px]",
      render: (_, record: { key: React.Key; id: number }) => (
        <div className="flex flex-row items-center justify-center gap-2">
          <Button
            variant="icon"
            className="bg-error !text-white hover:!bg-red-400 text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(record.id);
            }}
          >
            <CrossIcon className="text-white w-4 h-4" />
            <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
              {L.translate("Base.delete")}
            </span>
          </Button>
          <Button
            variant="icon"
            className="!text-white hover:bg-red-400 bg-purple text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onDownloadXML(record.id);
            }}
          >
            <DownloadIcon className="text-white w-4 h-4" />
            <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
              {L.translate("CommonForm.download_xml")}
            </span>
          </Button>
          <Button
            variant="icon"
            className="!text-white hover:bg-purple bg-[#8d257c] text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onArchiveEvent(record.id);
            }}
          >
            <LeafHeartIcon className="text-white w-4 h-4" />
            <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
              {L.translate("Base.archive")}
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<InvoiceDataType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[14px] font-medium">
          {L.translate("Base.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="w-full flex flex-col sm:flex-row gap-4 items-start justify-between">
              <div className="w-full flex flex-col gap-4 items-start justify-center">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.invoice_id")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.invoice_no}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.client")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.client_name}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.invoice_descr")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.event_name}
                  </p>
                </div>
              </div>
              <div className="w-full flex flex-col gap-4 items-start justify-center">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.amount")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {makeNumberWithFloatingDigits(selData.amount, 2)}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.date")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.date ? makeFormattedDateString(selData.date) : "-"}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.status")}:`}
                  </p>
                  <div className="flex flex-row gap-2 items-center">
                    <p
                      className="font-gilroy text-white text-[14px] rounded-[8px] px-2"
                      style={{
                        backgroundColor: getInvoiceStatusColor(selData.status),
                      }}
                    >
                      {L.translate(getInvoiceStatusName(selData.status))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row items-start justify-center gap-2">
              <Button
                variant="text"
                className="text-[14px] font-gilroy !text-white w-full h-[44px] rounded-[8px] hover:bg-red-400 bg-error"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(selData.id);
                }}
              >
                <CrossIcon className="text-white w-4 h-4" />
                {L.translate("Base.delete")}
              </Button>
              <Button
                variant="text"
                className="text-[14px] font-gilroy px-8 !text-white w-full h-[44px] rounded-[8px] hover:bg-red-400 bg-purple"
                onClick={(e) => {
                  e.stopPropagation();
                  onDownloadXML(selData.id);
                }}
              >
                <DownloadIcon className="w-[16px] h-[16px] text-white" />
                <span className="text-[14px] font-gilroy uppercase">
                  {L.translate("CommonForm.download_xml")}
                </span>
              </Button>
              <Button
                variant="icon"
                className="text-[14px] font-gilroy px-8 !text-white w-full h-[44px] rounded-[8px] hover:bg-purple bg-[#8d257c]"
                onClick={(e) => {
                  e.stopPropagation();
                  onArchiveEvent(selData.id);
                }}
              >
                <LeafHeartIcon className="text-white w-4 h-4" />
                <span className="text-[14px] font-gilroy uppercase">
                  {L.translate("Base.archive")}
                </span>
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full h-full bg-whole-gray">
      <div className="bg-white p-4 rounded-[8px] mb-4">
        {/*
          <div className="flex flex-row items-center justify-between gap-4">
            <div className="text-primary text-[24px] font-gilroy-semibold">
              {L.translate("Sidebar.invoices")}
            </div>
            <Button
              variant="solid"
              className="rounded-[8px] text-[12px] font-gilroy leading-[24px] bg-active text-white hover:bg-main"
              onClick={onCreateNewInvoice}
            >
              <CalendarPlusIcon className="w-[16px] h-[16px] text-white" />
              <span className="text-[14px] font-gilroy uppercase">
                {L.translate("Sidebar.new_invoice")}
              </span>
            </Button>
          </div>
        */}
        <div className="text-primary text-[24px] font-gilroy-semibold">
          {L.translate("Sidebar.invoices")}
        </div>
        <div className="mt-8 flex flex-col items-center justify-between bg-white">
          <InvoiceBlocks
            total_invoice={currentStatus.total_invoice}
            pending_invoice={currentStatus.pending_invoice}
            paid_invoice={currentStatus.paid_invoice}
          />
        </div>
        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full gap-4">
          <InputWithoutLabel
            placeholder={String(L.translate("CommonForm.invoice_descr"))}
            className="w-full"
            showClose={searchParams.event_name!.length > 0}
            clickedClose={() =>
              setSearchParams({ ...searchParams, event_name: "", page: 1 })
            }
            value={searchParams.event_name}
            onChange={(e) =>
              setSearchParams({
                ...searchParams,
                event_name: e.target.value,
                page: 1,
              })
            }
          />

          <InputWithoutLabel
            placeholder={String(L.translate("CommonForm.client_name"))}
            className="w-full"
            showClose={searchParams.client_name!.length > 0}
            clickedClose={() =>
              setSearchParams({ ...searchParams, client_name: "", page: 1 })
            }
            value={searchParams.client_name}
            onChange={(e) =>
              setSearchParams({
                ...searchParams,
                client_name: e.target.value,
                page: 1,
              })
            }
          />
          <MonthPicker
            placeholder={String(L.translate("CommonForm.filter_by_month"))}
            value={searchParams.date ?? ""}
            onChange={(strDate?: string) =>
              setSearchParams({ ...searchParams, date: strDate ?? "", page: 1 })
            }
          />
          <DropdownWithoutLabel
            placeholder={String(L.translate("CommonForm.status"))}
            className="w-full"
            data={[
              { id: -1, name: String(L.translate("CommonForm.status")) },
              ...InvoiceStatus.map((item) => ({
                ...item,
                name: String(L.translate(item.name)),
              })),
            ]}
            onChange={(e: number) =>
              setSearchParams({ ...searchParams, status: e, page: 1 })
            }
          />
        </div>
        <div className="mt-8 relative w-full">
          <div className="flex flex-col sm:flex-row justify-end items-center gap-4">
            <Button
              variant="solid"
              className={`${
                extractIDs(selectedItems).length == 0
                  ? "bg-[#BCBEAD]"
                  : "bg-success"
              } rounded-[8px] text-[12px] font-gilroy leading-[24px] text-white hover:bg-success-100 w-full sm:w-auto`}
              onClick={onMarkAsPaid}
              disabled={extractIDs(selectedItems).length == 0}
              isLoading={isMarkingPaid}
            >
              <CreditCardIcon className="w-[16px] h-[16px] text-white" />
              <span className="text-[14px] font-gilroy uppercase">
                {L.translate("CommonForm.mark_as_paid")}
              </span>
            </Button>
          </div>
          <Table
            locale={{
              emptyText: (
                <Empty
                  description={
                    <p className="font-gilroy-semibold text-secondary text-[14px]">
                      {L.translate("Base.no_data")}
                    </p>
                  }
                />
              ),
            }}
            rowSelection={rowSelection}
            showSorterTooltip={false}
            columns={isMobile ? mobileColumns : columns}
            dataSource={dataSource}
            loading={isLoading || isArchiving || isDeleting}
            pagination={{
              total,
              current: searchParams.page,
              onChange: onPageChange,
              position: ["bottomCenter"],
              defaultPageSize: LIST_PAGE_SIZE,
              showSizeChanger: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoicesPage;
