import L from "i18n-react";
import { ReceiptIcon } from "../icons/receipt-icon";
import { ListCheckIcon } from "../icons/list-check-icon";
import { DatabaseIcon } from "../icons/database-icon";
import { useNavigate } from "react-router-dom";

const ReportInfo = ({
  icon,
  title,
  amount,
  backgroundColor,
  path,
}: {
  icon: React.ReactNode;
  title: string;
  amount: number;
  backgroundColor: string;
  path: string;
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={`w-full flex flex-row items-center justify-between rounded-[8px] shadow-card px-4 py-6 cursor-pointer ${backgroundColor} hover:bg-opacity-60`}
      onClick={() => navigate(path)}
    >
      <div className="flex flex-row items-center justify-start gap-2">
        <div className="w-12 h-12 flex items-center justify-center bg-white rounded-[8px]">
          {icon}
        </div>
        <div className="flex flex-col items-start justify-center">
          <p className="font-gilroy-semibold text-[14px] text-white">{title}</p>
          <p className="font-gilroy-bold text-[16px] text-white">{amount}</p>
        </div>
      </div>
    </div>
  );
};

export default ReportInfo;
