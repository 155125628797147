import { FC, useContext, useEffect, useState } from "react";
import { CheckboxIcon } from "src/components/icons/checkbox-icon";
import { PaperPlaneTopIcon } from "src/components/icons/paper-plane-top-icon";
import { SquareIcon } from "src/components/icons/square-icon";
import Button from "src/components/ui/button";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import Zoom from "react-medium-image-zoom";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import routes from "src/config/routes";
import Footer from "src/layouts/_footer";

const PrivacyPage: FC = () => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  return (
    <motion.div
      initial="exit"
      animate="enter"
      exit="exit"
      className="scrollbar-hide flex min-h-screen w-full flex-col bg-whole-gray dark:bg-dark-100"
    >
      <header className="border-b border-light-300 app-header sticky top-0 z-50 flex h-16 w-full items-center justify-between bg-whole-gray px-4 ltr:left-0 rtl:right-0 dark:border-dark-300 dark:bg-dark-250 sm:h-[70px]">
        <div className="flex items-center gap-2 cursor-pointer flex-shrink-0">
          <a
            onClick={() => {
              navigate(routes.home);
            }}
          >
            <img
              src={isMobile ? "/logo192.png" : "/logo512.png"}
              className={`${
                isMobile ? "w-[32.78px] h-[32.78px]" : "w-[44px] h-[44px]"
              } object-contain`}
            />
          </a>
        </div>
      </header>
      <div className="flex flex-1 font-gilroy">
        <div className="px-4 mb-12">
          <div id="Containeri4gck" className="Containeri4gck SPY_vo">
            <div
              data-mesh-id="Containeri4gckinlineContent"
              data-testid="inline-content"
              className=""
            >
              <div>
                <section
                  id="comp-lh285uxs"
                  tabIndex={-1}
                  className="Oqnisf comp-lh285uxs wixui-section"
                  data-block-level-container="ClassicSection"
                >
                  <div id="bgLayers_comp-lh285uxs" className="MW5IWV">
                    <div
                      data-testid="colorUnderlay"
                      className="LWbAav Kv1aVt"
                    ></div>
                    <div id="bgMedia_comp-lh285uxs" className="VgO9Yg"></div>
                  </div>
                  <div
                    data-mesh-id="comp-lh285uxsinlineContent"
                    data-testid="inline-content"
                    className=""
                  >
                    <div
                      data-mesh-id="comp-lh285uxsinlineContent-gridContainer"
                      data-testid="mesh-container-content"
                    >
                      <div>
                        <h2
                          style={{
                            fontSize: 33,
                            width: "100%",
                            fontWeight: "bold",
                            marginTop: 32,
                            marginBottom: 24,
                          }}
                        >
                          <div style={{ fontSize: 33, width: "100%" }}>
                            <div className="w-full text-center">
                              <span
                                style={{ color: "#292929" }}
                                className="wixui-rich-text__text"
                              >
                                Informativa sulla privacy
                              </span>
                            </div>
                          </div>
                        </h2>
                      </div>
                      <div
                        id="comp-jzlltxel"
                        className="HcOXKn c9GqVL QxJLC3 comp-jzlltxel wixui-rich-text"
                        data-testid="richTextElement"
                      >
                        <h1
                          className="font_0 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="color_25 wixui-rich-text__text">
                              Questa informativa è resa all’utente in attuazione
                              del provvedimento del Garante per la protezione
                              dei dati personali dell’8 Giugno 2023
                              “Individuazione delle modalità semplificate per
                              l’informativa e l’acquisizione del consenso per
                              l’uso dei cookie” e nel rispetto dell’art. 13 del
                              Codice privacy (D.Lgs. n. 196/2003). La presente
                              Privacy e Cookie Policy è relativa al sito web (
                              <a
                                data-auto-recognition="true"
                                href="http://www.withyouagency.it"
                                target="_blank"
                                className="wixui-rich-text__text"
                              >
                                www.withyouagency.it
                              </a>
                              ) Sede legale: Via Privata del Gonfalone, 3 -
                              20123 Milano (MI) – Partita IVA 10940150963.
                            </span>
                          </span>
                        </h1>

                        <h1
                          className="font_0 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="wixGuard wixui-rich-text__text">
                              ​
                            </span>
                          </span>
                        </h1>

                        <h1
                          className="font_0 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="color_25 wixui-rich-text__text">
                              Riceviamo, raccogliamo e archiviamo tutte le
                              informazioni che inserisci sul nostro sito web o
                              ci fornisci in qualsiasi altro modo. Inoltre,
                              raccogliamo l'indirizzo IP (Internet Protocol)
                              utilizzato per connettere il tuo computer a
                              Internet; accesso; indirizzo email; parola
                              d'ordine; informazioni su computer e connessione.
                              Potremmo utilizzare strumenti software per
                              misurare e raccogliere informazioni sulla
                              sessione, inclusi tempi di risposta alle pagine,
                              durata delle visite a determinate pagine,
                              informazioni sull'interazione della pagina e
                              metodi utilizzati per navigare lontano dalla
                              pagina. Raccogliamo anche informazioni personali
                              identificabili (tra cui nome, email, password,
                              comunicazioni).
                            </span>
                          </span>
                        </h1>

                        <h1
                          className="font_0 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="wixGuard wixui-rich-text__text">
                              ​
                            </span>
                          </span>
                        </h1>

                        <h1
                          className="font_0 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="color_25 wixui-rich-text__text">
                              Le tue informazioni personali verranno utilizzate
                              solo per i motivi specifici sopra indicati.
                            </span>
                          </span>
                        </h1>

                        <h1
                          className="font_0 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="wixGuard wixui-rich-text__text">
                              ​
                            </span>
                          </span>
                        </h1>

                        <p
                          className="font_8 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="color_25 wixui-rich-text__text">
                              Raccogliamo tali informazioni personali e
                              non-personali per i seguenti scopi:&nbsp;
                            </span>
                          </span>
                        </p>

                        <ol
                          className="font_8 color_25 wixui-rich-text__text"
                          style={{
                            fontSize: 16,
                            listStyleType: "decimal",
                            marginLeft: 20,
                          }}
                        >
                          <li
                            style={{
                              lineHeight: "normal",
                            }}
                            className="wixui-rich-text__text"
                          >
                            <p
                              className="font_8 wixui-rich-text__text"
                              style={{ lineHeight: "normal", fontSize: 16 }}
                            >
                              <span
                                style={{ letterSpacing: "normal" }}
                                className="wixui-rich-text__text"
                              >
                                <span className="color_25 wixui-rich-text__text">
                                  fornire e gestire i Servizi di reclutamento
                                  staff;&nbsp;
                                </span>
                              </span>
                            </p>
                          </li>
                          <li
                            style={{ lineHeight: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <p
                              className="font_8 wixui-rich-text__text"
                              style={{ lineHeight: "normal", fontSize: 16 }}
                            >
                              <span
                                style={{ letterSpacing: "normal" }}
                                className="wixui-rich-text__text"
                              >
                                <span className="color_25 wixui-rich-text__text">
                                  fornire assistenza continua e supporto tecnico
                                  ai nostri utenti;&nbsp;
                                </span>
                              </span>
                            </p>
                          </li>
                          <li
                            style={{ lineHeight: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <p
                              className="font_8 wixui-rich-text__text"
                              style={{ lineHeight: "normal", fontSize: 16 }}
                            >
                              <span
                                style={{ letterSpacing: "normal" }}
                                className="wixui-rich-text__text"
                              >
                                <span className="color_25 wixui-rich-text__text">
                                  per essere in grado di contattare i nostri
                                  visitatori e utenti con avvisi di servizi
                                  generali o personalizzati e messaggi
                                  promozionali;&nbsp;
                                </span>
                              </span>
                            </p>
                          </li>
                          <li
                            style={{ lineHeight: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <p
                              className="font_8 wixui-rich-text__text"
                              style={{ lineHeight: "normal", fontSize: 16 }}
                            >
                              <span
                                style={{ letterSpacing: "normal" }}
                                className="wixui-rich-text__text"
                              >
                                <span className="color_25 wixui-rich-text__text">
                                  per creare dati statistici aggregati e altre
                                  informazioni non personali aggregate e / o
                                  dedotte, che noi o i nostri partner
                                  commerciali possano utilizzare per fornire e
                                  migliorare i nostri rispettivi servizi;&nbsp;
                                </span>
                              </span>
                            </p>
                          </li>
                          <li
                            style={{ lineHeight: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <p
                              className="font_8 wixui-rich-text__text"
                              style={{ lineHeight: "normal", fontSize: 16 }}
                            >
                              <span
                                style={{ letterSpacing: "normal" }}
                                className="wixui-rich-text__text"
                              >
                                <span className="color_25 wixui-rich-text__text">
                                  per rispettare le leggi e i regolamenti
                                  applicabili
                                </span>
                              </span>
                            </p>
                          </li>
                        </ol>

                        <p
                          className="font_8 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="wixGuard wixui-rich-text__text">
                              ​
                            </span>
                          </span>
                        </p>

                        <h4
                          className="font_4 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="color_25 wixui-rich-text__text">
                              <span
                                style={{ fontSize: 16 }}
                                className="wixui-rich-text__text"
                              >
                                Questo sito fa uso di cookie di terze parti per:
                                Monitorare le visite e ottenere statistiche
                                sull’attività degli utenti e condividere sui
                                social network le pagine di questo sito
                              </span>
                            </span>
                          </span>
                        </h4>

                        <ul
                          className="font_8 color_25 wixui-rich-text__text"
                          style={{
                            fontSize: 16,
                            listStyleType: "disc",
                            marginLeft: 20,
                          }}
                        >
                          <li
                            style={{ lineHeight: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <p
                              className="font_8 wixui-rich-text__text"
                              style={{ lineHeight: "normal", fontSize: 16 }}
                            >
                              <span
                                style={{ letterSpacing: "normal" }}
                                className="wixui-rich-text__text"
                              >
                                <span className="color_25 wixui-rich-text__text">
                                  <span
                                    style={{ fontSize: 16 }}
                                    className="wixui-rich-text__text"
                                  >
                                    Google Analytics. Per il monitoraggio delle
                                    visite e per l’ottenimento di statistiche
                                    sull’attività del sito. Maggiori
                                    informazioni sono contenute nella&nbsp;
                                    <span
                                      style={{ textDecoration: "underline" }}
                                      className="wixui-rich-text__text"
                                    >
                                      <a
                                        href="http://www.google.com/analytics/learn/privacy.html"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="wixui-rich-text__text"
                                      >
                                        Privacy policy
                                      </a>
                                    </span>
                                    &nbsp;di Google Analytics.
                                  </span>
                                </span>
                              </span>
                            </p>
                          </li>
                          <li
                            style={{ lineHeight: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <p
                              className="font_8 wixui-rich-text__text"
                              style={{ lineHeight: "normal", fontSize: 16 }}
                            >
                              <span
                                style={{ letterSpacing: "normal" }}
                                className="wixui-rich-text__text"
                              >
                                <span className="color_25 wixui-rich-text__text">
                                  <span
                                    style={{ fontSize: 16 }}
                                    className="wixui-rich-text__text"
                                  >
                                    Facebook. Per maggiori informazioni dei
                                    singoli servizi scorrere la pagina e vedere
                                    le privacy policy rispettive ai singoli
                                    servizi: Facebook Pulsante Mi Piace e widget
                                    sociali di Facebook (Facebook, Inc.) Il
                                    pulsante “Mi Piace” e i widget sociali di
                                    Facebook sono servizi di interazione con il
                                    social network Facebook, forniti da
                                    Facebook, Inc. Dati personali raccolti:
                                    Cookie e Dati di utilizzo. Luogo del
                                    trattamento: USA &nbsp;
                                    <span
                                      style={{ textDecoration: "underline" }}
                                      className="wixui-rich-text__text"
                                    >
                                      <a
                                        href="http://www.facebook.com/privacy/explanation.php"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="wixui-rich-text__text"
                                      >
                                        Privacy Policy
                                      </a>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </p>
                          </li>
                        </ul>

                        <p
                          className="font_8 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="color_25 wixui-rich-text__text">
                              <span
                                style={{ fontSize: 16 }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ textDecoration: "underline" }}
                                  className="wixui-rich-text__text"
                                >
                                  <span className="wixGuard wixui-rich-text__text">
                                    ​
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </p>

                        <p
                          className="font_8 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="color_25 wixui-rich-text__text">
                              Se preferisci che Google Analytics non utilizzi i
                              dati raccolti in nessun modo puoi utilizzare la
                              navigazione anonima (opzione Do Not Track) del tuo
                              browser. Le modalità di funzionamento nonché le
                              opzioni per limitare o bloccare i cookie, possono
                              essere effettuate modificando le impostazioni del
                              proprio browser Internet. Per sapere come attivare
                              l’opzione sui principali motori di ricerca clicca
                              sul browser che usi:
                            </span>
                          </span>
                        </p>

                        <ul
                          className="font_8 color_25 wixui-rich-text__text"
                          style={{
                            fontSize: 16,
                            listStyleType: "disc",
                            marginLeft: 20,
                          }}
                        >
                          <li
                            style={{ lineHeight: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <p
                              className="font_8 wixui-rich-text__text"
                              style={{ lineHeight: "normal", fontSize: 16 }}
                            >
                              <span
                                style={{ letterSpacing: "normal" }}
                                className="wixui-rich-text__text"
                              >
                                <span className="color_25 wixui-rich-text__text">
                                  Internet Explorer&nbsp;
                                  <span
                                    style={{ textDecoration: "underline" }}
                                    className="wixui-rich-text__text"
                                  >
                                    <a
                                      href="http://windows.microsoft.com/it-IT/windows7/Block-enable-or-allow-cookies"
                                      target="_blank"
                                      rel="noreferrer noopener"
                                      className="wixui-rich-text__text"
                                    >
                                      (http://windows.microsoft.com/it-IT/windows7/Block-enable-or-allow-cookies)
                                    </a>
                                  </span>
                                </span>
                              </span>
                            </p>
                          </li>
                          <li
                            style={{ lineHeight: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <p
                              className="font_8 wixui-rich-text__text"
                              style={{ lineHeight: "normal", fontSize: 16 }}
                            >
                              <span
                                style={{ letterSpacing: "normal" }}
                                className="wixui-rich-text__text"
                              >
                                <span className="color_25 wixui-rich-text__text">
                                  Google Chrome&nbsp;
                                  <span
                                    style={{ textDecoration: "underline" }}
                                    className="wixui-rich-text__text"
                                  >
                                    <a
                                      href="https://support.google.com/chrome/answer/95464?hl=it"
                                      target="_blank"
                                      rel="noreferrer noopener"
                                      className="wixui-rich-text__text"
                                    >
                                      (https://support.google.com/chrome/answer/95464?hl=it)
                                    </a>
                                  </span>
                                </span>
                              </span>
                            </p>
                          </li>
                          <li
                            style={{ lineHeight: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <p
                              className="font_8 wixui-rich-text__text"
                              style={{ lineHeight: "normal", fontSize: 16 }}
                            >
                              <span
                                style={{ letterSpacing: "normal" }}
                                className="wixui-rich-text__text"
                              >
                                <span className="color_25 wixui-rich-text__text">
                                  Mozilla Firefox&nbsp;
                                  <span
                                    style={{ textDecoration: "underline" }}
                                    className="wixui-rich-text__text"
                                  >
                                    <a
                                      href="https://support.mozilla.org/it/kb/Navigazione%20anonima"
                                      target="_blank"
                                      rel="noreferrer noopener"
                                      className="wixui-rich-text__text"
                                    >
                                      (https://support.mozilla.org/it/kb/Navigazione
                                      anonima)
                                    </a>
                                  </span>
                                </span>
                              </span>
                            </p>
                          </li>
                          <li
                            style={{ lineHeight: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <p
                              className="font_8 wixui-rich-text__text"
                              style={{ lineHeight: "normal", fontSize: 16 }}
                            >
                              <span
                                style={{ letterSpacing: "normal" }}
                                className="wixui-rich-text__text"
                              >
                                <span className="color_25 wixui-rich-text__text">
                                  Opera Browser&nbsp;
                                  <span
                                    style={{ textDecoration: "underline" }}
                                    className="wixui-rich-text__text"
                                  >
                                    <a
                                      href="http://help.opera.com/opera/Windows/1781/it/private.html#privateWindow"
                                      target="_blank"
                                      rel="noreferrer noopener"
                                      className="wixui-rich-text__text"
                                    >
                                      (http://help.opera.com/opera/Windows/1781/it/private.html#privateWindow)
                                    </a>
                                  </span>
                                </span>
                              </span>
                            </p>
                          </li>
                          <li
                            style={{ lineHeight: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <p
                              className="font_8 wixui-rich-text__text"
                              style={{ lineHeight: "normal", fontSize: 16 }}
                            >
                              <span
                                style={{ letterSpacing: "normal" }}
                                className="wixui-rich-text__text"
                              >
                                <span className="color_25 wixui-rich-text__text">
                                  Apple Safari&nbsp;
                                  <span
                                    style={{ textDecoration: "underline" }}
                                    className="wixui-rich-text__text"
                                  >
                                    <a
                                      href="https://support.apple.com/it-it/HT6074"
                                      target="_blank"
                                      rel="noreferrer noopener"
                                      className="wixui-rich-text__text"
                                    >
                                      (https://support.apple.com/it-it/HT6074)
                                    </a>
                                  </span>
                                </span>
                              </span>
                            </p>
                          </li>
                        </ul>

                        <p
                          className="font_8 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="color_25 wixui-rich-text__text">
                              Scegliere di disattivare Google Analytics
                              installando sul tuo browser il componente
                              aggiuntivo per la disattivazione. Clicca qui per
                              scaricarlo&nbsp;
                              <span
                                style={{ textDecoration: "underline" }}
                                className="wixui-rich-text__text"
                              >
                                <a
                                  href="https://tools.google.com/dlpage/gaoptout?hl=it"
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  className="wixui-rich-text__text"
                                >
                                  https://tools.google.com/dlpage/gaoptout?hl=it
                                </a>
                              </span>
                            </span>
                          </span>
                        </p>

                        <p
                          className="font_8 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="color_25 wixui-rich-text__text">
                              I cookie tecnici e gli analytics sono alcuni dei
                              cookie che usiamo per rendere la tua navigazione
                              piacevole.
                              <br className="wixui-rich-text__text" />
                              La disabilitazione dei cookies potrebbe limitare
                              la possibilità di usare il Sito e impedire di
                              beneficiare in pieno delle funzionalità e dei
                              servizi presenti sul Sito. Per decidere quali
                              accettare e quali rifiutare, è illustrata di
                              seguito una descrizione dei cookies utilizzati sul
                              Sito. È possibile inoltre visitare il sito, in
                              lingua inglese,&nbsp;
                              <span
                                style={{ textDecoration: "underline" }}
                                className="wixui-rich-text__text"
                              >
                                <a
                                  href="http://www.aboutcookies.org/"
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  className="wixui-rich-text__text"
                                >
                                  www.aboutcookies.org
                                </a>
                              </span>
                              &nbsp;per informazioni su come poter
                              gestire/eliminare i cookie in base al tipo di
                              browser utilizzato. Per eliminare i cookie dal
                              browser Internet del proprio smartphone/tablet, è
                              necessario fare riferimento al manuale d’uso del
                              dispositivo.
                            </span>
                          </span>
                        </p>

                        <p
                          className="font_8 wixui-rich-text__text"
                          style={{ fontSize: 16, lineHeight: "normal" }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="color_25 wixui-rich-text__text">
                              <span
                                style={{ fontSize: 16 }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">
                                  ​
                                </span>
                              </span>
                            </span>
                          </span>
                        </p>

                        <p
                          className="font_8 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="color_25 wixui-rich-text__text">
                              Potremmo contattarti per informarti in merito al
                              tuo account, per risolvere problemi relativi al
                              tuo account, per sondare le tue opinioni tramite
                              sondaggi o questionari, per inviare aggiornamenti
                              sulla nostra agenzia, o come altrimenti necessario
                              potremmo contattarti per far rispettare il nostro
                              Accordo stipulato con gli utenti, le leggi
                              nazionali applicabili e qualsiasi accordo che
                              potremmo avere con te. A tal fine, potremmo
                              contattarti via email, telefono, sms e posta
                              ordinaria.
                            </span>
                          </span>
                        </p>

                        <p
                          className="font_8 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="wixGuard wixui-rich-text__text">
                              ​
                            </span>
                          </span>
                        </p>

                        <p
                          className="font_8 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="color_25 wixui-rich-text__text">
                              Se non desideri che i tuoi dati vengano elaborati,
                              ti preghiamo di contattarci all'indirizzo email [
                              <a
                                data-auto-recognition="true"
                                href="mailto:info@withyouagency.it"
                                className="wixui-rich-text__text"
                              >
                                info@withyouagency.it
                              </a>
                              ] o di inviarci una lettera a: [
                            </span>
                            <span
                              style={{ fontStyle: "normal" }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixui-rich-text__text">
                                <span
                                  style={{ fontWeight: "normal" }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontSize: 16 }}
                                    className="wixui-rich-text__text"
                                  >
                                    <span className="color_25 wixui-rich-text__text">
                                      Via Privata del Gonfalone, 3 - 20123
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <span className="color_25 wixui-rich-text__text">
                              {" "}
                              Milano].
                            </span>
                          </span>
                        </p>

                        <p
                          className="font_8 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="wixGuard wixui-rich-text__text">
                              ​
                            </span>
                          </span>
                        </p>

                        <p
                          className="font_8 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="color_25 wixui-rich-text__text">
                              Ci riserviamo il diritto di modificare questa
                              informativa sulla privacy in qualsiasi momento,
                              quindi ti preghiamo di controllarla
                              frequentemente. Cambiamenti e chiarimenti
                              entreranno in vigore immediatamente dopo la loro
                              pubblicazione sul sito web. Se apportiamo
                              modifiche sostanziali a questa informativa, ti
                              notificheremo che è stata aggiornata, in modo che
                              tu sappia quali informazioni raccogliamo, come le
                              usiamo e in quali circostanze le usiamo e/o
                              divulghiamo.
                            </span>
                          </span>
                        </p>

                        <p
                          className="font_8 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="wixGuard wixui-rich-text__text">
                              ​
                            </span>
                          </span>
                        </p>

                        <p
                          className="font_8 wixui-rich-text__text"
                          style={{ lineHeight: "normal", fontSize: 16 }}
                        >
                          <span
                            style={{ letterSpacing: "normal" }}
                            className="wixui-rich-text__text"
                          >
                            <span className="color_25 wixui-rich-text__text">
                              Se desideri accedere, correggere, modificare o
                              eliminare qualsiasi informazione personale che
                              abbiamo su di te, sei invitato a contattarci
                              all'indirizzo [
                              <a
                                data-auto-recognition="true"
                                href="mailto:info@withyouagency.it"
                                className="wixui-rich-text__text"
                              >
                                info@withyouagency.it
                              </a>
                              ] o inviare una lettera a: [
                            </span>
                            <span
                              style={{ fontStyle: "normal" }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixui-rich-text__text">
                                <span
                                  style={{ fontWeight: "normal" }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontSize: 16 }}
                                    className="wixui-rich-text__text"
                                  >
                                    <span className="color_25 wixui-rich-text__text">
                                      Via Privata del Gonfalone, 3 - 20123
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <span className="color_25 wixui-rich-text__text">
                              {" "}
                              Milano].
                            </span>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
};

export default PrivacyPage;
