export const CrossIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_689)">
        <path
          d="M19.7557 0.243989C19.5994 0.0877629 19.3875 0 19.1665 0C18.9455 0 18.7336 0.0877629 18.5773 0.243989L9.99982 8.82149L1.42232 0.243989C1.26605 0.0877629 1.05413 0 0.833155 0C0.612185 0 0.400262 0.0877629 0.243989 0.243989C0.0877629 0.400262 0 0.612185 0 0.833155C0 1.05413 0.0877629 1.26605 0.243989 1.42232L8.82149 9.99982L0.243989 18.5773C0.0877629 18.7336 0 18.9455 0 19.1665C0 19.3875 0.0877629 19.5994 0.243989 19.7557C0.400262 19.9119 0.612185 19.9996 0.833155 19.9996C1.05413 19.9996 1.26605 19.9119 1.42232 19.7557L9.99982 11.1782L18.5773 19.7557C18.7336 19.9119 18.9455 19.9996 19.1665 19.9996C19.3875 19.9996 19.5994 19.9119 19.7557 19.7557C19.9119 19.5994 19.9996 19.3875 19.9996 19.1665C19.9996 18.9455 19.9119 18.7336 19.7557 18.5773L11.1782 9.99982L19.7557 1.42232C19.9119 1.26605 19.9996 1.05413 19.9996 0.833155C19.9996 0.612185 19.9119 0.400262 19.7557 0.243989Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_689">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
