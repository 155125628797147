export const ExclamationIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_823)">
        <path
          d="M10 20.4082C8.02219 20.4082 6.08879 19.8217 4.4443 18.7229C2.79981 17.6241 1.51809 16.0623 0.761209 14.235C0.00433286 12.4078 -0.193701 10.3971 0.192152 8.4573C0.578004 6.51749 1.53041 4.73566 2.92894 3.33714C4.32746 1.93861 6.10929 0.986202 8.0491 0.600349C9.98891 0.214499 11.9996 0.412531 13.8268 1.16941C15.6541 1.92628 17.2159 3.20801 18.3147 4.8525C19.4135 6.49699 20 8.43039 20 10.4082C19.9971 13.0595 18.9426 15.6014 17.0679 17.4761C15.1932 19.3508 12.6513 20.4053 10 20.4082ZM10 2.07487C8.35183 2.07487 6.74066 2.56361 5.37025 3.47929C3.99984 4.39497 2.93174 5.69646 2.30101 7.21917C1.67028 8.74189 1.50525 10.4174 1.82679 12.034C2.14834 13.6505 2.94201 15.1353 4.10745 16.3008C5.27289 17.4662 6.75774 18.2599 8.37425 18.5814C9.99076 18.903 11.6663 18.7379 13.189 18.1072C14.7118 17.4765 16.0132 16.4084 16.9289 15.038C17.8446 13.6675 18.3333 12.0564 18.3333 10.4082C18.3309 8.19881 17.4522 6.0806 15.8899 4.51833C14.3276 2.95605 12.2094 2.0773 10 2.07487Z"
          fill="currentColor"
        />
        <path
          d="M10 16.2415C9.77901 16.2415 9.56705 16.1537 9.41076 15.9974C9.25448 15.8412 9.16669 15.6292 9.16669 15.4082V8.74152C9.16669 8.5205 9.25448 8.30854 9.41076 8.15226C9.56705 7.99598 9.77901 7.90818 10 7.90818C10.221 7.90818 10.433 7.99598 10.5893 8.15226C10.7456 8.30854 10.8334 8.5205 10.8334 8.74152V15.4082C10.8334 15.6292 10.7456 15.8412 10.5893 15.9974C10.433 16.1537 10.221 16.2415 10 16.2415Z"
          fill="currentColor"
        />
        <path
          d="M10.8334 5.40818C10.8334 5.86842 10.4603 6.24152 10 6.24152C9.53978 6.24152 9.16669 5.86842 9.16669 5.40818C9.16669 4.94795 9.53978 4.57485 10 4.57485C10.4603 4.57485 10.8334 4.94795 10.8334 5.40818Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_823">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="matrix(1 0 0 -1 0 20.4082)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
