import type { User, UserResponse } from "../api/types";
import useAuth from "./use-auth";
import { useMutation, useQuery, useQueryClient } from "react-query";
import client from "../api";
import { API_ENDPOINTS } from "../api/endpoints";
import { error } from "console";

export function useMe() {
  const { isAuthorized } = useAuth();
  const { data, isLoading, error } = useQuery<UserResponse, Error>(
    [API_ENDPOINTS.USERS_ME],
    client.users.me,
    {
      enabled: isAuthorized,
    }
  );
  return {
    me: data,
    isLoading,
    error,
    isAuthorized,
  };
}

export function useLogout() {
  const { unauthorize } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(client.users.logout, {
    onSuccess: () => {
      unauthorize();
      //queryClient.resetQueries(API_ENDPOINTS.USERS_ME);
    },
    onError: (error) => {
      unauthorize();
    },
  });
}
