export const ArrowLeftIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_681)">
        <path
          d="M0.733331 11.7417L3.95833 15C4.0358 15.0781 4.12797 15.1401 4.22952 15.1824C4.33107 15.2247 4.43999 15.2465 4.55 15.2465C4.66001 15.2465 4.76893 15.2247 4.87048 15.1824C4.97203 15.1401 5.0642 15.0781 5.14166 15C5.21977 14.9225 5.28177 14.8304 5.32407 14.7288C5.36638 14.6273 5.38816 14.5184 5.38816 14.4083C5.38816 14.2983 5.36638 14.1894 5.32407 14.0879C5.28177 13.9863 5.21977 13.8941 5.14166 13.8167L2.175 10.8333H19.1667C19.3877 10.8333 19.5996 10.7455 19.7559 10.5893C19.9122 10.433 20 10.221 20 10C20 9.77899 19.9122 9.56703 19.7559 9.41075C19.5996 9.25447 19.3877 9.16668 19.1667 9.16668H2.125L5.14166 6.15001C5.28941 5.99503 5.37182 5.78913 5.37182 5.57501C5.37182 5.36089 5.28941 5.15499 5.14166 5.00001C5.0642 4.9219 4.97203 4.85991 4.87048 4.8176C4.76893 4.77529 4.66001 4.75351 4.55 4.75351C4.43999 4.75351 4.33107 4.77529 4.22952 4.8176C4.12797 4.85991 4.0358 4.9219 3.95833 5.00001L0.733331 8.20834C0.265162 8.67709 0.00219727 9.31251 0.00219727 9.97501C0.00219727 10.6375 0.265162 11.2729 0.733331 11.7417Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_681">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
