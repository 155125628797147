export const SpinnerIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_699)">
        <path
          d="M9.99988 20C8.22091 19.9996 6.47425 19.5246 4.94005 18.6241C3.40584 17.7236 2.13964 16.4301 1.27203 14.877C0.404425 13.324 -0.0331759 11.5676 0.00436342 9.78899C0.0419027 8.01041 0.553223 6.27404 1.48559 4.75897C2.41795 3.2439 3.7376 2.00498 5.30844 1.16998C6.87928 0.334989 8.64442 -0.0658492 10.4218 0.00881249C12.1992 0.0834742 13.9245 0.630932 15.4198 1.59472C16.9151 2.55851 18.1262 3.90374 18.9282 5.49167C18.9815 5.58972 19.0145 5.69747 19.0254 5.80853C19.0362 5.91959 19.0247 6.0317 18.9914 6.13821C18.9581 6.24471 18.9037 6.34344 18.8315 6.42855C18.7593 6.51365 18.6708 6.58339 18.5712 6.63363C18.4715 6.68387 18.3628 6.71358 18.2515 6.72101C18.1401 6.72844 18.0284 6.71343 17.923 6.67688C17.8176 6.64032 17.7206 6.58296 17.6377 6.5082C17.5549 6.43344 17.4879 6.34281 17.4407 6.24167C16.5961 4.56935 15.2126 3.23043 13.5136 2.44101C11.8145 1.65159 9.899 1.45774 8.07623 1.89075C6.25345 2.32375 4.62977 3.35835 3.46724 4.82755C2.30472 6.29674 1.67119 8.11481 1.66891 9.98831C1.66663 11.8618 2.29573 13.6814 3.45467 15.1534C4.61362 16.6255 6.23478 17.664 8.05649 18.1014C9.87821 18.5389 11.7942 18.3497 13.4952 17.5644C15.1961 16.7791 16.5829 15.4436 17.4316 13.7733C17.5357 13.5833 17.7098 13.4413 17.9169 13.3775C18.124 13.3137 18.3478 13.333 18.5408 13.4314C18.7339 13.5299 18.881 13.6996 18.951 13.9047C19.021 14.1098 19.0083 14.3341 18.9157 14.53C18.0828 16.1787 16.8079 17.5633 15.2334 18.5293C13.659 19.4952 11.847 20.0044 9.99988 20Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_699">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
