import { FC, useContext, useEffect, useState, useRef } from "react";
import Button from "src/components/ui/button";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import {
  EyeColors,
  EyeColors_IT,
  HairColors,
  HairColors_IT,
  Langauges,
  Langauges_IT,
  LIST_PAGE_SIZE,
  LIST_PAGE_SIZE_50,
  Provinces,
  ShoeSizes,
} from "src/lib/constants";
import L from "i18n-react";
import { CollabDataType } from "src/lib/data-types";
import { Empty, MenuProps, Space, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useModalAction } from "src/components/modal-views/context";
import { CollabSearchQueryOptions, SearchQueryOptions } from "src/api/types";
import { SortIcon } from "src/components/icons/sort-icon";
import GlobalContext from "src/context/global-context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  calculateDistance,
  capitalizeFirstLetter,
  getErrorMessage,
} from "src/lib/utils";
import moment from "moment";
import CollabFilterSection from "src/components/filter/collab";
import { ArrowLeftIcon } from "src/components/icons/arrow-left-icon";
import { CheckIcon } from "src/components/icons/check-icon";
import { AngleDownSmallIcon } from "src/components/icons/angle-down-small-icon";

const CollabsSelectionListPage = ({
  currentCollabs,
  onBack,
  onConfirmCallback,
}: {
  currentCollabs: number[];
  onBack: any;
  onConfirmCallback: any;
}) => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { openModal } = useModalAction();
  const { setAlertText, setAlertTitle, setIsAlertOpened, language } =
    useContext(GlobalContext);
  const [selectedUserID, setSelectedUserID] = useState(-1);
  const [isMoreFilters, setMoreFilters] = useState(false);

  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<CollabDataType[]>([]);
  const [filteredDataSource, setFilteredDataSource] = useState<
    CollabDataType[]
  >([]);

  const [selectedCollabs, setSelectedCollabs] = useState<number[]>([]);

  useEffect(() => {
    if (!isMounted) return;

    getList();
  }, [isMounted]);

  const { mutate: getList, isLoading } = useMutation(
    client.users.collabUsersAll,
    {
      onSuccess: (data) => {
        setTotal(data.results.length);

        const tempList = data.results.map((item, index) => {
          let lat: number = 0;
          let lng: number = 0;

          if (item.location) {
            const positions = item.location.split(",");
            if (positions.length >= 2) {
              lat = Number(positions[0]);
              lng = Number(positions[1]);
            }
          }

          let userCity = "-";
          let userProvince = "-";
          let age = "-";
          let languages = [];
          if (item.collaborator) {
            //get city
            const items = item.collaborator.residence_address.split("$$$");
            if (items.length >= 4) {
              userCity = items[1];
              userProvince = items[2];
            }

            //get age
            const birthDate = moment(item.collaborator.date_of_birth).format(
              "yyyy"
            );
            age = `${moment().diff(birthDate, "years")}`;

            //get langauge
            if ("italian" in item.collaborator)
              languages.push(L.translate("CollaboratorProfile.italian"));
            if ("english" in item.collaborator)
              languages.push(L.translate("CollaboratorProfile.english"));
            if ("french" in item.collaborator)
              languages.push(L.translate("CollaboratorProfile.french"));
            if ("german" in item.collaborator)
              languages.push(L.translate("CollaboratorProfile.german"));
            if ("spanish" in item.collaborator)
              languages.push(L.translate("CollaboratorProfile.spanish"));
            if ("other" in item.collaborator)
              languages.push(item.collaborator.other);
          }

          return {
            key: index,
            id: item.id,
            first_name: item.first_name ?? "",
            last_name: item.last_name ?? "",
            email: item.email,
            lat: lat,
            lng: lng,
            name: `${capitalizeFirstLetter(
              item.first_name ?? ""
            )} ${capitalizeFirstLetter(item.last_name ?? "")}`,
            cv: item.collaborator?.cv ?? "",
            main_photo: item.collaborator?.main_photo ?? "",
            photo2: item.collaborator?.photo2 ?? "",
            photo3: item.collaborator?.photo3 ?? "",
            photo4: item.collaborator?.photo4 ?? "",
            photo5: item.collaborator?.photo5 ?? "",
            photo6: item.collaborator?.photo6 ?? "",
            photo7: item.collaborator?.photo7 ?? "",
            photo8: item.collaborator?.photo8 ?? "",
            photo9: item.collaborator?.photo9 ?? "",
            sex: item.collaborator ? item.collaborator!.sex : -1,
            height: item.collaborator ? item.collaborator!.height : "-",
            shoe_size: item.collaborator ? item.collaborator!.shoe_size : "",
            instagram: item.collaborator ? item.collaborator!.instagram : "",
            telephone_number: item.collaborator
              ? item.collaborator!.telephone_number
              : "",
            info: item.collaborator ? item.collaborator! : undefined,
            city: userCity,
            province: userProvince,
            age: age,
            language: languages.length == 0 ? "-" : languages.join(", "),
            status: item.status,
            feedback: item.feedback ?? "",
            rate: item.rate ?? 0,
            created_at: item.created_at,
            updated_at: item.updated_at,
          };
        });

        setDataSource(
          tempList.filter((item) => !currentCollabs.includes(item.id))
        );
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_load_collaborator")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  const onShowMoreFilters = () => {
    setMoreFilters(!isMoreFilters);
  };

  const onConfirm = () => {
    onConfirmCallback(
      [...dataSource].filter((item) =>
        selectedCollabs.includes(item.key as number)
      )
    );
  };

  const onPageChange = (page: number, pageSize: number) => {
    console.log(page);
  };

  const columns: ColumnsType<CollabDataType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.first_name")}
        </p>
      ),
      dataIndex: "first_name",
      className: "w-auto",
      key: "first_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.last_name")}
        </p>
      ),
      dataIndex: "last_name",
      className: "w-auto",
      key: "last_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CollaboratorProfile.sex")}
        </p>
      ),
      dataIndex: "sex",
      className: "w-auto",
      key: "sex",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value == 0 ? L.translate("Base.male") : L.translate("Base.female")}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("Base.age")}
        </p>
      ),
      dataIndex: "age",
      className: "w-auto",
      key: "age",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("Base.language")}
        </p>
      ),
      dataIndex: "language",
      className: "w-auto",
      key: "langauge",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CollaboratorProfile.height")}
        </p>
      ),
      dataIndex: "height",
      className: "w-auto",
      key: "height",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CollaboratorProfile.city")}
        </p>
      ),
      dataIndex: "city",
      className: "w-auto",
      key: "city",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<CollabDataType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[14px] font-medium">
          {L.translate("Base.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = filteredDataSource.filter(
          (item) => item.key === record.key
        )[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="flex flex-col sm:flex-row items-start justify-between w-full">
              <div className="flex flex-col items-start justify-between w-full">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.name")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {`${capitalizeFirstLetter(
                      selData.first_name
                    )} ${capitalizeFirstLetter(selData.last_name)}`}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CollaboratorProfile.sex")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.sex == 0
                      ? L.translate("Base.male")
                      : L.translate("Base.female")}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("Base.language")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.language}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CollaboratorProfile.height")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.height}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-start justify-between w-full">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CollaboratorProfile.city")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.city}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("Base.age")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.age}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log(
      "selectedRowKeys changed: ",
      newSelectedRowKeys,
      dataSource,
      filteredDataSource
    );
    const tempKeys = newSelectedRowKeys.map((item) => Number(item));
    setSelectedCollabs(tempKeys);
    //setSelectedCollabs(tempKeys.map((item) => dataSource[item].id));
    //setSelectedCollabs(newSelectedRowKeys.map((item) => Number(item)));
  };

  const getSelectedRowKeys = () => {
    const rowKeys = selectedCollabs.map((item) =>
      dataSource.findIndex((eachDatasource) => eachDatasource.key == item)
    );
    return rowKeys;
  };

  const rowSelection = {
    selectedRowKeys: selectedCollabs,
    onChange: onSelectChange,
  };

  return (
    <div className="w-full h-full bg-white mb-4 rounded-[8px]">
      <div className="flex flex-col items-start justify-center bg-white p-4 rounded-[8px]">
        <button
          className="flex flex-row gap-2 items-center h-[44px] bg-white text-active rounded-[8px] group"
          onClick={onBack}
        >
          <ArrowLeftIcon className="w-[20px] h-[20px] text-active group-hover:text-main" />
          <span className="text-active group-hover:text-main font-[14px] font-gilroy-semibold">
            {L.translate("Base.back")}
          </span>
        </button>
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between w-full">
          <div className="text-primary text-[24px] font-gilroy-semibold">
            {`${L.translate("CommonForm.select_collaborators")} (${
              selectedCollabs.length
            })`}
          </div>
          <div className="flex flex-row w-full sm:w-auto items-center justify-between sm:justify-center gap-4">
            <Button
              variant="icon"
              className="bg-purple p-2 rounded-[8px] hover:bg-red-400 text-white"
              onClick={onShowMoreFilters}
            >
              <AngleDownSmallIcon
                className={`${
                  isMoreFilters ? "rotate-180" : "rotate-0"
                } text-white w-[20px] h-[20px] duration-300`}
              />
            </Button>
            <Button
              variant="icon"
              className={`${
                selectedCollabs.length > 0 ? "bg-active" : "bg-[#BCBEAD]"
              } rounded-[8px] p-2 text-[12px] font-gilroy leading-[24px] text-white hover:bg-main`}
              onClick={onConfirm}
              disabled={selectedCollabs.length == 0}
            >
              <CheckIcon className="w-[20px] h-[20px] text-white" />
            </Button>
          </div>
        </div>
      </div>
      <div className="relative w-full px-4 mb-8">
        <CollabFilterSection
          isMore={isMoreFilters}
          dataSource={dataSource}
          setFilteredDataSource={setFilteredDataSource}
          collabStatus={-1}
        />
        <Table
          locale={{
            emptyText: (
              <Empty
                description={
                  <p className="font-gilroy-semibold text-secondary text-[14px]">
                    {L.translate("Base.no_data")}
                  </p>
                }
              />
            ),
          }}
          rowSelection={rowSelection}
          showSorterTooltip={false}
          columns={isMobile ? mobileColumns : columns}
          dataSource={filteredDataSource}
          loading={isLoading}
          pagination={{
            total,
            onChange: onPageChange,
            position: ["bottomCenter"],
            defaultPageSize: LIST_PAGE_SIZE_50,
            showSizeChanger: false,
          }}
        />
      </div>
    </div>
  );
};

export default CollabsSelectionListPage;
