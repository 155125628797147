import L from "i18n-react";
import { ReceiptIcon } from "../icons/receipt-icon";
import { ListCheckIcon } from "../icons/list-check-icon";
import { DatabaseIcon } from "../icons/database-icon";
import { InvoiceBlockItem } from "../invoice/block-info";

const PaymentBlocks = ({
  total_payment,
  paid_payment,
  pending_payment,
}: {
  total_payment: number;
  paid_payment: number;
  pending_payment: number;
}) => {
  return (
    <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-3">
      <InvoiceBlockItem
        icon={<ReceiptIcon className="text-white w-[20px] h-20px]" />}
        title={String(L.translate("CommonForm.total_payment"))}
        amount={total_payment}
        backgroundColor={"bg-active"}
      />
      <InvoiceBlockItem
        icon={<DatabaseIcon className="text-white w-[20px] h-[20px]" />}
        title={String(L.translate("CommonForm.pending_payment"))}
        amount={pending_payment}
        backgroundColor={"bg-error"}
      />
      <InvoiceBlockItem
        icon={<ListCheckIcon className="text-white w-[20px] h-[20px]" />}
        title={String(L.translate("CommonForm.paid_payment"))}
        amount={paid_payment}
        backgroundColor={"bg-success"}
      />
    </div>
  );
};

export default PaymentBlocks;
