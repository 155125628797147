import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { CheckboxIcon } from "src/components/icons/checkbox-icon";
import { PaperPlaneTopIcon } from "src/components/icons/paper-plane-top-icon";
import { SquareIcon } from "src/components/icons/square-icon";
import Button from "src/components/ui/button";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import Zoom from "react-medium-image-zoom";
import { useParams } from "react-router-dom";
import GlobalContext from "src/context/global-context";
import { useMutation } from "react-query";
import client from "../../api";
import { Event, WebCastingBid } from "src/api/types";
import {
  capitalizeFirstLetter,
  getCapitalizedFirstLetter,
  getErrorMessage,
} from "src/lib/utils";
import ImageWithLoading from "src/components/ui/image-with-loading";
import { SpinnerIcon } from "src/components/icons/spinner-icon";
import { ExclamationIcon } from "src/components/icons/exclamation-icon";
import { CheckIcon } from "src/components/icons/check-icon";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import L from "i18n-react";
import { useModalAction } from "src/components/modal-views/context";
import LangSwitcher from "src/components/ui/switcher-lang";
import InputWithoutLabel from "src/components/ui/input-without-label";
import UserPlaceholder from "../../assets/images/placeholder.png";
import {
  ShoeSizes,
  ShortLanguageLevels,
  ShortLanguageLevels_IT,
} from "src/lib/constants";
import moment from "moment";

const WebCastingPage: FC = () => {
  const { width, height } = useWindowSize();

  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { code } = useParams<{ code: string }>();

  const [event, setEvent] = useState<Event | null>(null);
  const [collaborators, setCollaborators] = useState<WebCastingBid[]>([]);
  const [errorMessage, setErrorMessage] = useState("");

  const { openModal } = useModalAction();

  const [isSuccess, setSuccess] = useState(false);
  const [castingStatus, setCastingStatus] = useState(0);

  const [selectedOptions, setSelectedOptions] = useState<{
    [key: number]: number;
  }>({});

  const {
    setAlertText,
    setAlertTitle,
    setIsAlertOpened,
    geoPosition,
    language: globalLang,
  } = useContext(GlobalContext);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  useEffect(() => {
    if (!isMounted || !code) return;

    getInfo(code);
  }, [isMounted, code]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, []);

  const { mutate: getInfo, isLoading } = useMutation(client.webCasting.view, {
    onSuccess: (data) => {
      console.log(data);

      if (data.data) {
        setCastingStatus(data.data.status);
        setEvent(data.data?.event);
        let tempResult = data.data.bids;
        tempResult.sort((a, b) => a.order - b.order);
        console.log(tempResult);
        setCollaborators(tempResult);

        let tempOptions: {
          [key: number]: number;
        } = {};
        data.data.bids.map((item) => {
          if (item.casting_status == 1) {
            tempOptions[item.collab_id] = 1;
          } else if (item.casting_status == 4) {
            tempOptions[item.collab_id] = 0;
          } else if (item.casting_status == 5) {
            tempOptions[item.collab_id] = -1;
          } else {
            tempOptions[item.collab_id] = -2;
          }
        });

        //data.data.status == 1 && setSelectedOptions(tempOptions);
        setSelectedOptions(tempOptions);
      } else {
        setErrorMessage(String(L.translate("GlobalErrors.APISomethingWrong")));
      }
    },
    onError: (error: any) => {
      setTimeout(() => {
        if (error.code === "ERR_NETWORK") {
          setErrorMessage(
            String(L.translate("GlobalErrors.NetworkDisconnect"))
          );
        } else {
          if (error.response) {
            if (
              error.response.data &&
              error.response.data.message === "code_not_found"
            ) {
              setErrorMessage(String(L.translate("Errors.code_not_found")));
            } else if (
              error.response.data &&
              error.response.data.message === "code_approved"
            ) {
              setErrorMessage(
                String(L.translate("Errors.code_already_approved"))
              );
            } else if (
              error.response.data &&
              error.response.data.message === "code_archived"
            ) {
              setErrorMessage(String(L.translate("Errors.code_archived")));
            } else if (
              error.response.data &&
              error.response.data.message === "code_deleted"
            ) {
              setErrorMessage(String(L.translate("Errors.code_deleted")));
            } else {
              setErrorMessage(
                getErrorMessage(
                  error.response.data.message ??
                    String(L.translate("GlobalErrors.APISomethingWrong"))
                )
              );
            }
          } else {
            setErrorMessage(
              String(L.translate("GlobalErrors.APISomethingWrong"))
            );
          }
        }
      }, 1000);
    },
  });

  const { mutate: submitCasting, isLoading: isSubmitting } = useMutation(
    client.webCasting.confirm,
    {
      onSuccess: (data) => {
        console.log(data);
        setSuccess(true);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_send_casting")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onSubmit = () => {
    const options = Object.keys(selectedOptions).map(
      (item) => `${item}:${selectedOptions[Number(item)]}`
    );

    const casting_result = options.join(",");

    event &&
      code &&
      submitCasting({
        event_id: event.id,
        code: code,
        casting_result: casting_result,
      });
  };

  const onMoveUpDown = (index: number, direction: boolean) => {
    let tempList = [...collaborators];

    if (direction) {
      //up
      if (index == 0) return;

      const temp = tempList[index - 1];
      tempList[index - 1] = tempList[index];
      tempList[index] = temp;
    } else {
      //down
      if (index == collaborators.length - 1) return;

      const temp = tempList[index + 1];
      tempList[index + 1] = tempList[index];
      tempList[index] = temp;
    }

    setCollaborators(tempList);
  };

  const checkSelectedOptions = () => {
    if (Object.keys(selectedOptions).length != collaborators.length) {
      return true;
    }

    let bAvailable = false;
    Object.keys(selectedOptions).map((item: string) => {
      if (selectedOptions[Number(item)] == -2) {
        bAvailable = true;
      }
    });

    return bAvailable;
  };

  const onSelectedOption = (index: number, value: number) => {
    const updatedSelectedOptions = { ...selectedOptions };
    updatedSelectedOptions[index] = value;
    setSelectedOptions(updatedSelectedOptions);
  };

  if (!event) {
    if (errorMessage.length > 0) {
      return (
        <div className="w-full h-full bg-whole-gray relative">
          <div className="fixed inset-0 z-50 backdrop-blur-[20px] w-[100vw] h-[100vh] flex items-center justify-center gap-4">
            <ExclamationIcon className="h-8 w-8 text-primary" />
            <span className="font-gilroy text-[20px] text-primary">
              {errorMessage}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-full h-full bg-whole-gray relative">
          <div className="fixed inset-0 z-50 backdrop-blur-[20px] w-[100vw] h-[100vh] flex items-center justify-center gap-4">
            <SpinnerIcon className="h-8 w-8 animate-spin text-active" />
            <span className="font-gilroy text-[20px] text-active">
              {L.translate("Base.validating_code")}
            </span>
          </div>
        </div>
      );
    }
  }

  if (isSuccess) {
    return (
      <div className="w-full h-full bg-whole-gray relative">
        <Confetti width={width} height={height} />
        <div className="fixed inset-0 z-50 w-[100vw] h-[100vh] flex items-center justify-center gap-4">
          <CheckIcon className="h-auto w-8 text-success" />
          <span className="font-gilroy text-[20px] uppercase text-success">
            {L.translate("Success.casting_result_sent")}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="relative w-full h-full bg-white flex flex-col items-center justify-center gap-2 p-8">
      {isSubmitting && (
        <div className="fixed inset-0 z-50 backdrop-blur-[20px] w-[100vw] h-[100vh] flex items-center justify-center gap-4">
          <SpinnerIcon className="h-auto w-8 animate-spin text-active" />
          <span className="font-gilroy text-[20px] text-active">
            {L.translate("Base.sending_casting_result")}
          </span>
        </div>
      )}

      <img
        src={isMobile ? "/logo192.png" : "/logo512.png"}
        className={`${isMobile ? "w-24 h-24" : "w-36 h-36"} object-contain`}
      />
      <div className="absolute top-2 right-2">
        <LangSwitcher />
      </div>
      <p className="mt-4 font-gilroy-medium text-[30px] text-primary">
        {event.name}
      </p>

      {/*
        <div className="text-primary text-[28px] font-gilroy-bold mt-4">
          {L.translate("CommonForm.event_details")}
        </div>
      */}
      {/*event && <EventDetailsView event={event} />*/}
      {/*
      <div className="mt-4 bg-gray-modern-400 h-[1px] w-full bg-opacity-30" />
      <p className="mt-4 font-gilroy-medium-italic text-[18px] text-active">{`${
        collaborators.length
      } ${L.translate("CommonForm.shortlist_notice")}`}</p>
      <div className="mt-4 bg-gray-modern-400 h-[1px] w-full bg-opacity-30" />
      */}
      <div className="flex flex-col items-center justify-center w-full gap-8 mt-4">
        {collaborators.map((item, index) => {
          let languages = [];

          //get langauge
          console.log(item.info);
          if ("italian" in item.info) {
            const nLevel = Number(item.info["italian"]) ?? 0;
            languages.push(
              `${L.translate("CollaboratorProfile.italian")} ${
                globalLang == "EN"
                  ? ShortLanguageLevels[nLevel]
                  : ShortLanguageLevels_IT[nLevel]
              }`
            );
          }
          if ("english" in item.info) {
            const nLevel = Number(item.info["english"]) ?? 0;
            languages.push(
              `${L.translate("CollaboratorProfile.english")} ${
                globalLang == "EN"
                  ? ShortLanguageLevels[nLevel]
                  : ShortLanguageLevels_IT[nLevel]
              }`
            );
          }
          if ("french" in item.info) {
            const nLevel = Number(item.info["french"]) ?? 0;
            languages.push(
              `${L.translate("CollaboratorProfile.french")} ${
                globalLang == "EN"
                  ? ShortLanguageLevels[nLevel]
                  : ShortLanguageLevels_IT[nLevel]
              }`
            );
          }
          if ("german" in item.info) {
            const nLevel = Number(item.info["german"]) ?? 0;
            languages.push(
              `${L.translate("CollaboratorProfile.german")} ${
                globalLang == "EN"
                  ? ShortLanguageLevels[nLevel]
                  : ShortLanguageLevels_IT[nLevel]
              }`
            );
          }
          if ("spanish" in item.info) {
            const nLevel = Number(item.info["spanish"]) ?? 0;
            languages.push(
              `${L.translate("CollaboratorProfile.spanish")} ${
                globalLang == "EN"
                  ? ShortLanguageLevels[nLevel]
                  : ShortLanguageLevels_IT[nLevel]
              }`
            );
          }
          if ("other" in item.info) languages.push(item.info.other);

          const isShowHeight = (item.casting_fields ?? "").includes("height");
          const isShowShoe = (item.casting_fields ?? "").includes("shoe");
          const isShowSize = (item.casting_fields ?? "").includes("size");
          const isShowLang = (item.casting_fields ?? "").includes("lang");
          const isShowAge = (item.casting_fields ?? "").includes("age");

          let age = "-";
          const birthDate = moment(item.info.date_of_birth).format("yyyy");
          age = `${moment().diff(birthDate, "years")}`;

          return (
            <div
              key={`collaborator_${index}`}
              className="flex flex-col gap-4 sm:gap-1 w-full"
            >
              <div className="flex flex-col items-center justify-between lg:flex-row gap-4">
                <div className="w-full  flex flex-col lg:flex-row items-center justify-start gap-4">
                  <div className="w-full sm:w-auto flex flex-col sm:flex-row flex-shrink-0 items-center justify-start gap-0 sm:gap-4">
                    {/*
                  <div className="flex flex-col items-center justify-center gap-1">
                    <button
                      className="w-5 h-5"
                      onClick={() => onMoveUpDown(index, true)}
                    >
                      <AngleDownIcon className="w-5 h-5 text-active hover:text-main" />
                    </button>
                    <button
                      className="w-5 h-5"
                      onClick={() => onMoveUpDown(index, false)}
                    >
                      <AngleDownIcon className="w-5 h-5 text-active hover:text-main rotate-180" />
                    </button>
                  </div>
                */}
                    <label className="font-gilroy-semibold text-[36px] text-secondary">
                      {`${capitalizeFirstLetter(
                        item.info.first_name ?? ""
                      )} ${getCapitalizedFirstLetter(
                        item.info.last_name ?? ""
                      )}.`}
                    </label>
                    <div className="hidden sm:block w-[1px] h-[30px] bg-gray-modern-400 bg-opacity-30" />
                    {/*
                      <div className="flex flex-col items-start justify-center">
                        <div className="flex flex-row items-center justify-start gap-2">
                          <label className="font-gilroy text-[12px] text-primary w-[60px]">
                            {L.translate(
                              "CollaboratorProfile.height_without_cm"
                            )}
                            :
                          </label>
                          <label className="font-gilroy-bold text-[12px] text-primary">
                            {`${item.info.height}cm`}
                          </label>
                        </div>
                        <div className="flex flex-row items-start justify-center gap-2">
                          <label className="font-gilroy text-[12px] text-primary w-[60px]">
                            {L.translate("CollaboratorProfile.shoe_size")}:
                          </label>
                          <label className="font-gilroy-bold text-[12px] text-primary">
                            {item.info.shoe_size}
                          </label>
                        </div>
                      </div>
                    */}
                  </div>
                  {item.notes.trim().length > 0 && (
                    <InputWithoutLabel
                      required
                      value={item.notes}
                      placeholder={String(L.translate("CommonForm.notes"))}
                      className="w-full lg:w-[30vw]"
                      disabled
                    />
                  )}
                </div>
                <div className="flex flex-col sm:flex-row items-center justify-between gap-2 sm:gap-4 flex-shrink-0">
                  {/*
                <Button
                  variant="solid"
                  className="w-full rounded-[8px] text-[12px] font-gilroy leading-[24px] h-[44px] bg-success text-white hover:bg-success-100"
                  onClick={() => onViewProposal(item)}
                >
                  <BriefcaseIcon className="w-[20px] h-[20px] text-white" />
                  <span className="text-[16px] font-gilroy uppercase">
                    {L.translate("CommonForm.view_proposal")}
                  </span>
                </Button>
                */}
                  <div
                    className={`${
                      castingStatus == 0
                        ? "pointer-events-auto"
                        : "pointer-events-none"
                    } flex flex-row border-[1px] border-gray-modern-100 rounded-[8px] gap-4 px-4 py-2`}
                  >
                    <div
                      key={`action_confirm_${index}`}
                      className="flex flex-row items-center justify-start py-1 gap-2"
                      onClick={() => onSelectedOption(item.collab_id, 1)}
                    >
                      {selectedOptions[item.collab_id] == 1 ? (
                        <CheckboxIcon className="w-[16px] h-[16px] text-secondary" />
                      ) : (
                        <SquareIcon className="w-[16px] h-[16px] text-secondary" />
                      )}
                      <label className="text-[12px] font-gilroy-semibold text-secondary">
                        {L.translate("Base.confirm")}
                      </label>
                    </div>
                    <div
                      key={`action_reverse_${index}`}
                      className="flex flex-row items-center justify-start py-1 gap-2"
                      onClick={() => onSelectedOption(item.collab_id, 0)}
                    >
                      {selectedOptions[item.collab_id] == 0 ? (
                        <CheckboxIcon className="w-[16px] h-[16px] text-secondary" />
                      ) : (
                        <SquareIcon className="w-[16px] h-[16px] text-secondary" />
                      )}
                      <label className="text-[12px] font-gilroy-semibold text-secondary">
                        {L.translate("Base.reverse")}
                      </label>
                    </div>
                    <div
                      key={`action_discard_${index}`}
                      className="flex flex-row items-center justify-start py-1 gap-2"
                      onClick={() => onSelectedOption(item.collab_id, -1)}
                    >
                      {selectedOptions[item.collab_id] == -1 ? (
                        <CheckboxIcon className="w-[16px] h-[16px] text-secondary" />
                      ) : (
                        <SquareIcon className="w-[16px] h-[16px] text-secondary" />
                      )}
                      <label className="text-[12px] font-gilroy-semibold text-secondary">
                        {L.translate("Base.discard")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="mt-4 flex flex-col sm:flex-row gap-4">
            {item.comp && (
              <p className="font-gilroy text-white text-center text-[14px] rounded-[8px] px-2 bg-active">
                {`${L.translate(
                  "CommonForm.comp_column"
                )}: ${makeNumberWithFloatingDigits(item.comp, 2)}`}
              </p>
            )}
            {item.reimburse && (
              <p className="font-gilroy text-white text-center text-[14px] rounded-[8px] px-2 bg-primary">
                {`${L.translate(
                  "CommonForm.reimb_column"
                )}: ${makeNumberWithFloatingDigits(item.reimburse, 2)}`}
              </p>
            )}
            {item.event_date && (
              <p className="font-gilroy text-white text-center text-[14px] rounded-[8px] px-2 bg-purple">
                {`${L.translate("CommonForm.event_date")}: ${
                  item.event_date
                }`}
              </p>
            )}
            {item.payment_date && (
              <p className="font-gilroy text-white text-center text-[14px] rounded-[8px] px-2 bg-error">
                {`${L.translate("CommonForm.payment_date")}: ${
                  item.payment_date
                }`}
              </p>
            )}
          </div>*/}
              {/*item.notes && item.notes.length > 0 && (
            <div className="w-full mt-2 flex flex-col items-start justify-center">
              <label className="font-gilroy text-[12px] text-secondary">
                {L.translate("CommonForm.notes")}
              </label>
              <label className="font-gilroy-semibold text-[14px] text-active">
                {item.notes}
              </label>
            </div>
          )*/}
              <div className="flex flex-wrap gap-x-4 gap-y-2 mt-2">
                {isShowHeight && (
                  <div className="flex flex-row items-center justify-start gap-2">
                    <label className="font-gilroy text-[12px] text-primary">
                      {L.translate("CollaboratorProfile.height_without_cm")}:
                    </label>
                    <label className="font-gilroy-bold text-[12px] text-primary">
                      {`${item.info.height}cm`}
                    </label>
                  </div>
                )}
                {isShowSize && (
                  <div className="flex flex-row items-center justify-start gap-2">
                    <label className="font-gilroy text-[12px] text-primary">
                      {L.translate("CollaboratorProfile.size")}:
                    </label>
                    <label className="font-gilroy-bold text-[12px] text-primary">
                      {`${
                        ShoeSizes.filter(
                          (eachSize) => eachSize.id == item.info.italian_size
                        )[0].name
                      }`}
                    </label>
                  </div>
                )}
                {isShowShoe && (
                  <div className="flex flex-row items-start justify-center gap-2">
                    <label className="font-gilroy text-[12px] text-primary">
                      {L.translate("CollaboratorProfile.shoe_size")}:
                    </label>
                    <label className="font-gilroy-bold text-[12px] text-primary">
                      {item.info.shoe_size}
                    </label>
                  </div>
                )}
                {isShowLang && (
                  <div className="flex flex-row items-start justify-center gap-2">
                    <label className="font-gilroy text-[12px] text-primary">
                      {L.translate("Base.language")}:
                    </label>
                    <label className="font-gilroy-bold text-[12px] text-primary">
                      {languages.length > 0 ? languages.join(" - ") : "-"}
                    </label>
                  </div>
                )}
                {isShowAge && (
                  <div className="flex flex-row items-start justify-center gap-2">
                    <label className="font-gilroy text-[12px] text-primary">
                      {L.translate("Base.age")}:
                    </label>
                    <label className="font-gilroy-bold text-[12px] text-primary">
                      {age}
                    </label>
                  </div>
                )}
              </div>
              <div
                className={`${
                  (isShowHeight ||
                    isShowLang ||
                    isShowShoe ||
                    isShowSize ||
                    isShowAge) &&
                  "mt-4"
                } grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8`}
              >
                {[
                  //item.main_photo,
                  //item.photo2,
                  //item.photo3,
                  //item.photo4,
                  //item.photo5,
                  item.casting_photo1 ?? item.photo2,
                  item.casting_photo2 ?? item.photo3,
                  item.casting_photo3 ?? item.photo4,
                  item.casting_photo4 ?? item.photo5,
                ].map(
                  (photo, photo_index) =>
                    photo && (
                      <Zoom
                        key={`collaborator-image-${photo_index}`}
                        classDialog="custom-zoom"
                      >
                        <ImageWithLoading
                          src={photo ? photo : UserPlaceholder}
                          className="object-contain aspect-square bg-gray-modern-100 rounded-[8px]"
                        />
                      </Zoom>
                    )
                )}
              </div>
              <div className="mt-4 bg-gray-modern-400 h-[1px] w-full bg-opacity-30" />
            </div>
          );
        })}
      </div>
      {castingStatus == 0 && (
        <Button
          variant="solid"
          className={`${
            checkSelectedOptions() ? "bg-[#BCBEAD]" : "bg-active"
          } mt-8 rounded-[8px] w-full md:w-[50%] text-[12px] font-gilroy leading-[24px] h-[44px] text-white hover:bg-main`}
          onClick={onSubmit}
          disabled={checkSelectedOptions()}
        >
          <PaperPlaneTopIcon className="w-[20px] h-[20px] text-white" />
          <span className="text-[16px] font-gilroy uppercase">
            {L.translate("CommonForm.send_casting")}
          </span>
        </Button>
      )}
    </div>
  );
};

export default WebCastingPage;
