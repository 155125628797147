export const WhatsAppIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_765)">
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d="M17.0525 2.90667C15.1808 1.03333 12.6925 0.000833333 10.0417 0C4.57915 0 0.133319 4.445 0.130819 9.91C0.129985 11.6567 0.586652 13.3617 1.45415 14.865L0.0474854 20L5.30082 18.6217C6.74832 19.4117 8.37832 19.8275 10.0367 19.8283H10.0408C15.5025 19.8283 19.9492 15.3825 19.9517 9.9175C19.9533 7.27 18.9233 4.77917 17.0525 2.90667ZM10.0417 18.1542H10.0383C8.55999 18.1542 7.11082 17.7567 5.84582 17.0058L5.54499 16.8275L2.42749 17.645L3.25999 14.605L3.06415 14.2933C2.23915 12.9817 1.80415 11.4658 1.80499 9.91C1.80665 5.36833 5.50249 1.67333 10.0458 1.67333C12.2458 1.67333 14.3142 2.53167 15.8692 4.08833C17.4242 5.64583 18.28 7.715 18.2792 9.91583C18.2767 14.4592 14.5817 18.1542 10.0417 18.1542ZM14.56 11.985C14.3125 11.8608 13.095 11.2617 12.8675 11.1792C12.6408 11.0967 12.4758 11.055 12.31 11.3025C12.1442 11.55 11.6708 12.1083 11.5258 12.2742C11.3817 12.4392 11.2367 12.46 10.9892 12.3358C10.7417 12.2117 9.94332 11.9508 8.99749 11.1067C8.26165 10.45 7.76415 9.63917 7.61999 9.39083C7.47582 9.1425 7.60499 9.00917 7.72832 8.88583C7.83999 8.775 7.97582 8.59667 8.09999 8.45167C8.22499 8.30833 8.26582 8.205 8.34915 8.03917C8.43165 7.87417 8.39082 7.72917 8.32832 7.605C8.26582 7.48167 7.77082 6.2625 7.56499 5.76667C7.36415 5.28333 7.15999 5.34917 7.00749 5.34167C6.86332 5.33417 6.69832 5.33333 6.53249 5.33333C6.36749 5.33333 6.09915 5.395 5.87249 5.64333C5.64582 5.89167 5.00582 6.49083 5.00582 7.70917C5.00582 8.92833 5.89332 10.1058 6.01665 10.2708C6.13999 10.4358 7.76249 12.9375 10.2467 14.01C10.8375 14.265 11.2992 14.4175 11.6583 14.5317C12.2517 14.72 12.7917 14.6933 13.2183 14.63C13.6942 14.5592 14.6833 14.0308 14.89 13.4525C15.0967 12.8742 15.0967 12.3775 15.0342 12.275C14.9725 12.1708 14.8075 12.1092 14.56 11.985Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_765">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
