import React, { useState, useEffect } from "react";
import "./week-picker.css";
import {
  addMonths,
  endOfWeek,
  startOfWeek,
  subMonths,
  getDaysInMonth,
} from "date-fns";
import { AngleDownIcon } from "../icons/angle-down-icon";
import { CalendarIcon } from "../icons/calendar";
import L from "i18n-react";

export const WeekPicker = ({
  week,
  setWeek,
  onChange,
}: {
  week: { firstDay: Date; lastDay: Date };
  setWeek: any;
  onChange: any;
}) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    onChange && onChange(week);
  }, [week]);

  const isLeapYear = () => {
    let leapYear = new Date(new Date().getFullYear(), 1, 29);
    return leapYear.getDate() == 29;
  };

  const convertDate = (date: Date) => {
    const dt = new Date(date);
    const day = dt.getDate() < 10 ? `0${dt.getDate()}` : dt.getDate();
    const month =
      dt.getMonth() + 1 < 10 ? `0${dt.getMonth() + 1}` : dt.getMonth() + 1;
    const year = dt.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const handleClick = (e: any) => {
    let localDate;
    if (e.target.id.includes("prev")) {
      localDate = new Date(date.setDate(1));
      setDate(new Date(date.setDate(1)));
    } else if (e.target.id.includes("next")) {
      localDate = new Date(date.setDate(getDaysInMonth(date)));
      setDate(new Date(date.setDate(getDaysInMonth(date))));
    } else {
      localDate = new Date(date.setDate(e.target.id));
      setDate(new Date(date.setDate(e.target.id)));
    }

    const firstDay = startOfWeek(localDate, { weekStartsOn: 1 });
    const lastDay = endOfWeek(localDate, { weekStartsOn: 1 });

    setWeek({ firstDay, lastDay });
    setTimeout(() => setOpen(false), 300);
  };

  const months: string[] = [
    String(L.translate("Months.Jan")),
    String(L.translate("Months.Feb")),
    String(L.translate("Months.Mar")),
    String(L.translate("Months.Apr")),
    String(L.translate("Months.May")),
    String(L.translate("Months.Jun")),
    String(L.translate("Months.July")),
    String(L.translate("Months.Aug")),
    String(L.translate("Months.Sep")),
    String(L.translate("Months.Oct")),
    String(L.translate("Months.Nov")),
    String(L.translate("Months.Dec")),
  ];

  const days: { [key: string]: number } = {
    "1": 31,
    "2": isLeapYear() ? 29 : 28,
    "3": 31,
    "4": 30,
    "5": 31,
    "6": 30,
    "7": 31,
    "8": 31,
    "9": 30,
    "10": 31,
    "11": 30,
    "12": 31,
  };

  const renderDays = () => {
    let month = date.getMonth() + 1;
    let ar = [];
    for (let i = 1; i <= days[month]; i++) {
      let currentDate = new Date(date).setDate(i);

      let cName = "single-number ";
      if (
        new Date(week.firstDay).getTime() <= new Date(currentDate).getTime() &&
        new Date(currentDate).getTime() <= new Date(week.lastDay).getTime()
      ) {
        cName = cName + "selected-week";
      }

      ar.push(
        <div
          key={`${month}-${i}`}
          id={`${i}`}
          className={cName}
          onClick={handleClick}
        >
          {i}
        </div>
      );
    }

    const displayDate = new Date(date).setDate(1);
    let dayInTheWeek = new Date(displayDate).getDay();
    if (dayInTheWeek < 1) {
      dayInTheWeek = 7;
    }

    let prevMonth = [];
    let prevMonthDays = new Date(date).getMonth();
    if (prevMonthDays === 0) {
      prevMonthDays = 12;
    }

    for (let i = dayInTheWeek; i > 1; i--) {
      let previousMonth = new Date(date).setMonth(
        new Date(date).getMonth() - 1
      );

      let currentDate = new Date(previousMonth).setDate(
        days[prevMonthDays] - i + 2
      );

      let cName = "single-number other-month";
      let currentTime = new Date(currentDate).getTime();
      let firstTime = new Date(week.firstDay).getTime();
      let endTime = new Date(week.lastDay).getTime();

      if (currentTime >= firstTime && currentTime <= endTime) {
        cName = "single-number selected-week";
      }

      prevMonth.push(
        <div
          onClick={handleClick}
          key={`prev-${month}-${i}`}
          id={"prev-" + i}
          className={cName}
        >
          {days[prevMonthDays] - i + 2}
        </div>
      );
    }

    let nextMonth = [];
    let fullDays = 35;
    if ([...prevMonth, ...ar].length > 35) {
      fullDays = 42;
    }

    for (let i = 1; i <= fullDays - [...prevMonth, ...ar].length; i++) {
      let cName = "single-number other-month";
      const lastDay = week.lastDay.getTime();
      const lastDayOfMonth = new Date(
        new Date(date).setDate(getDaysInMonth(date))
      );

      if (
        lastDayOfMonth.getTime() <= lastDay &&
        week.firstDay.getMonth() == lastDayOfMonth.getMonth()
      ) {
        cName = "single-number selected-week";
      }

      nextMonth.push(
        <div
          onClick={handleClick}
          key={`next-${month}-${i}`}
          id={"next-" + i}
          className={cName}
        >
          {i}
        </div>
      );
    }
    return [...prevMonth, ...ar, ...nextMonth];
  };

  const handleDate = (next: boolean = false) => {
    let localDate = new Date(date);
    if (next) {
      localDate = addMonths(localDate, 1);
    } else {
      localDate = subMonths(localDate, 1);
    }
    setDate(new Date(localDate));
  };

  return (
    <div
      className="week-picker-display font-gilroy !w-full sm:!w-[300px]"
      onBlur={() => setOpen(false)}
      onClick={() => setOpen(true)}
      tabIndex={0}
    >
      {/*
        <div className="flex flex-row items-center justify-between gap-4">
          <CalendarIcon className="w-4 h-4 text-active" />
          <span className="font-gilroy font-semibold !text-[18px] !text-active">
            {`${convertDate(week.firstDay)} ~ ${convertDate(week.lastDay)}`}
          </span>
        </div>
        */}
      <div className="flex flex-row items-center justify-center gap-4">
        <CalendarIcon className="w-[20px] h-[20px] text-white" />
      </div>
      {open && (
        <div className="week-picker-options">
          <div className="title-week">
            <div onClick={() => handleDate()} className="arrow-container">
              <AngleDownIcon className="text-active w-[16px] h-[16px] -rotate-90" />
            </div>
            {`${months[date.getMonth()]} ${date.getFullYear()}`}
            <div onClick={() => handleDate(true)} className="arrow-container">
              <AngleDownIcon className="text-active w-[16px] h-[16px] rotate-90" />
            </div>
          </div>
          <div className="numbers-container">
            <div className="single-number day">
              {L.translate("Weekdays.Mon")}
            </div>
            <div className="single-number day">
              {L.translate("Weekdays.Tue")}
            </div>
            <div className="single-number day">
              {L.translate("Weekdays.Wed")}
            </div>
            <div className="single-number day">
              {L.translate("Weekdays.Thu")}
            </div>
            <div className="single-number day">
              {L.translate("Weekdays.Fri")}
            </div>
            <div className="single-number day">
              {L.translate("Weekdays.Sat")}
            </div>
            <div className="single-number day">
              {L.translate("Weekdays.Sun")}
            </div>
          </div>
          <div className="numbers-container">{renderDays()}</div>
        </div>
      )}
    </div>
  );
};
