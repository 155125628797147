import "react-phone-input-2/lib/bootstrap.css";
import ReactPhone from "react-phone-input-2";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import startsWith from "lodash.startswith";

const phoneNumberAtom = atom("");
export function usePhoneInput() {
  let [phoneNumber, setPhoneNumber] = useAtom(phoneNumberAtom);
  return {
    phoneNumber,
    setPhoneNumber,
  };
}

export default function PhoneInput({
  className,
  defaultValue,
}: {
  className?: string;
  defaultValue?: string;
}) {
  let { phoneNumber, setPhoneNumber } = usePhoneInput();
  useEffect(() => {
    if (defaultValue) {
      setPhoneNumber(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className={className}>
      <ReactPhone
        onlyCountries={["it"]}
        country="it"
        onChange={(value) => setPhoneNumber(value)}
      />
    </div>
  );
}

export { ReactPhone };
