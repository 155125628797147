export const CopyIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_753)">
        <path
          d="M10.8334 16.6667C11.938 16.6654 12.9971 16.2259 13.7782 15.4448C14.5593 14.6637 14.9987 13.6047 15 12.5V5.20251C15.0013 4.76448 14.9157 4.33055 14.748 3.92586C14.5804 3.52118 14.334 3.1538 14.0234 2.84501L12.155 0.976681C11.8462 0.665993 11.4789 0.419684 11.0742 0.252031C10.6695 0.0843791 10.2356 -0.00128163 9.79752 1.44913e-05H5.83335C4.72869 0.00133771 3.66965 0.440749 2.88854 1.22186C2.10742 2.00298 1.66801 3.06202 1.66669 4.16668V12.5C1.66801 13.6047 2.10742 14.6637 2.88854 15.4448C3.66965 16.2259 4.72869 16.6654 5.83335 16.6667H10.8334ZM3.33335 12.5V4.16668C3.33335 3.50364 3.59675 2.86776 4.06559 2.39891C4.53443 1.93007 5.17031 1.66668 5.83335 1.66668C5.83335 1.66668 9.93252 1.67835 10 1.68668V3.33335C10 3.77538 10.1756 4.1993 10.4882 4.51186C10.8007 4.82442 11.2247 5.00001 11.6667 5.00001H13.3134C13.3217 5.06751 13.3334 12.5 13.3334 12.5C13.3334 13.1631 13.07 13.7989 12.6011 14.2678C12.1323 14.7366 11.4964 15 10.8334 15H5.83335C5.17031 15 4.53443 14.7366 4.06559 14.2678C3.59675 13.7989 3.33335 13.1631 3.33335 12.5ZM18.3334 6.66668V15.8333C18.332 16.938 17.8926 17.9971 17.1115 18.7782C16.3304 19.5593 15.2713 19.9987 14.1667 20H6.66669C6.44567 20 6.23371 19.9122 6.07743 19.7559C5.92115 19.5997 5.83335 19.3877 5.83335 19.1667C5.83335 18.9457 5.92115 18.7337 6.07743 18.5774C6.23371 18.4211 6.44567 18.3333 6.66669 18.3333H14.1667C14.8297 18.3333 15.4656 18.07 15.9345 17.6011C16.4033 17.1323 16.6667 16.4964 16.6667 15.8333V6.66668C16.6667 6.44567 16.7545 6.23371 16.9108 6.07743C17.067 5.92115 17.279 5.83335 17.5 5.83335C17.721 5.83335 17.933 5.92115 18.0893 6.07743C18.2456 6.23371 18.3334 6.44567 18.3334 6.66668Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_753">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
