import { Menu, MenuItems, MenuItem, MenuButton } from "@headlessui/react";
import { forwardRef, useState, useRef, useEffect, useContext } from "react";
import classNames from "classnames";
import { Transition } from "./transition";
import { Fragment } from "react";
import { GreenCheckIcon } from "../icons/green-check-icon";
import { animate } from "framer-motion";
import { AngleDownSmallIcon } from "../icons/angle-down-small-icon";
import { ConfigValue } from "src/config";
import { CrossIcon } from "../icons/cross-icon";
import GlobalContext from "src/context/global-context";

type PlaceInputProps = {
  curValue?: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  onPicked?: any;
  required?: boolean;
};

const PlaceInput = forwardRef<HTMLDivElement, PlaceInputProps>(
  (
    {
      error,
      className,
      curValue = "",
      placeholder = "",
      inputClassName = "bg-gray-modern-100",
      onPicked,
      required = false,
      ...props
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);

    const [value, setValue] = useState(curValue);
    const [isPicked, setPicked] = useState(false);
    const selectedIDRef = useRef(-1);
    const [data, setData] = useState<any[]>([]);
    const [selectedId, setSelectedId] = useState(-1);
    const [containerWidth, setContainerWidth] = useState(0);
    const [isContainerOpened, setIsContainerOpened] = useState(false);

    const { language } = useContext(GlobalContext);

    useEffect(() => {
      updateWindowSize();
      window.addEventListener("resize", updateWindowSize);
      btnRef.current?.addEventListener("click", cickedMenuButton);
      window.addEventListener("click", handler);

      return () => {
        window.removeEventListener("click", handler);
        window.removeEventListener("resize", updateWindowSize);
        btnRef.current?.removeEventListener("click", cickedMenuButton);
      };
    }, []);

    const fetchResult = async () => {
      const endpoint = `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/google-textsearch/${value}`;
      fetch(endpoint)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              "Network response was not ok " + response.statusText
            );
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          setData(data.results);
        })
        .catch((error) => console.log("error - ", error));
    };

    useEffect(() => {
      if (curValue) {
        setValue(curValue);
        setPicked(true);
      }
    }, [curValue]);

    useEffect(() => {
      if (value.length == 0 || isPicked) return;

      const delayDebounceFn = setTimeout(() => {
        fetchResult();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }, [value, isPicked]);

    useEffect(() => {
      console.log("Loading place input");
    }, [data]);

    const handler = (e: any) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setIsContainerOpened(false);
      }
    };

    function updateWindowSize() {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }

    useEffect(() => {
      setTimeout(() => menuRef.current?.scrollTo(0, selectedId * 40), 100);
    }, [selectedId, isContainerOpened]);

    function cickedMenuButton() {
      btnRef.current &&
        btnRef.current.ariaExpanded &&
        btnRef.current.ariaExpanded === "false" &&
        setIsContainerOpened(true);

      setTimeout(
        () => menuRef.current?.scrollTo(0, selectedIDRef.current * 40),
        100
      );
    }

    useEffect(() => {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }, [containerRef.current]);

    useEffect(() => {
      const handler = (e: any) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
          setSelectedId(-1);
          setPicked(false);
          setData([]);
        }
      };

      window.addEventListener("click", handler);

      return () => window.removeEventListener("click", handler);
    }, []);

    return (
      <div className={className} ref={containerRef}>
        <label className="block text-[16px] font-gilroy font-medium text-secondary relative">
          <input
            value={value}
            onChange={(e) => {
              setSelectedId(-1);
              setPicked(false);
              setData([]);
              setValue(e.target.value);
            }}
            placeholder={placeholder}
            {...props}
            className={classNames(
              "h-[44px] placeholder-gray-modern-400 border-none w-full appearance-none text-[14px] font-gilroy font-medium rounded-[8px] px-4 py-2 text-primary ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px] lg:px-5",
              classNames("!pr-10"),
              inputClassName
            )}
          />
          {(selectedId >= 0 || value.length > 0) && (
            <button
              className="absolute bottom-0 right-0 h-[44px] w-[44px] flex items-center justify-center"
              onClick={() => {
                setSelectedId(-1);
                setPicked(false);
                setData([]);
                setValue("");
                onPicked({
                  location: null,
                  coordinate: null,
                });
              }}
            >
              <CrossIcon className="w-3 h-3 text-secondary" />
            </button>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            show={!isPicked && data.length > 0}
          >
            <div
              ref={menuRef}
              className={`max-h-[216px] scrollbar-hide overflow-y-auto flex flex-col absolute z-30 mt-2 rounded-[12px] bg-light py-1.5 text-dark shadow-card origin-top-left dark:bg-dark-250 dark:text-light`}
            >
              {data.map((item: any, index) => (
                /* Use the `active` state to conditionally style the active item. */
                <div key={index}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (index == selectedId) {
                        return;
                      }

                      setSelectedId(index);
                      setPicked(true);
                      setValue(`${item.formatted_address}`);
                      onPicked({
                        location: `${item.formatted_address}`,
                        coordinate: `${item.geometry.location.lat},${item.geometry.location.lng}`,
                      });
                    }}
                    className="flex transition-fill-colors px-4  flex-row items-start justify-between py-2 text-primary hover:bg-active hover:text-light"
                    style={{ width: containerWidth }}
                  >
                    <p className="text-[14px] font-gilroy text-left">
                      {`${item.formatted_address}`}
                    </p>
                  </button>
                </div>
              ))}
            </div>
          </Transition>
        </label>
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[12px] text-error font-gilroy"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

PlaceInput.displayName = "PlaceInput";
export default PlaceInput;
