import { Menu, MenuItems, MenuItem, MenuButton } from "@headlessui/react";
import { forwardRef, useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { Transition } from "./transition";
import { Fragment } from "react";
import { GreenCheckIcon } from "../icons/green-check-icon";
import { animate } from "framer-motion";
import { AngleDownSmallIcon } from "../icons/angle-down-small-icon";
import { capitalizeFirstLetter } from "src/lib/utils";
import L from "i18n-react";

type DropdownWithoutLabelProps = {
  label?: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  data: any[];
  value?: number;
  onChange?: any;
  required?: boolean;
  showInfo?: boolean;
  info?: string;
};

const DropdownWithoutLabel = forwardRef<
  HTMLDivElement,
  DropdownWithoutLabelProps
>(
  (
    {
      label,
      error,
      className,
      placeholder = "",
      inputClassName = "bg-gray-modern-100",
      data = [],
      value = -1,
      onChange,
      required,
      showInfo = false,
      info,
      ...props
    },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);

    const selectedIDRef = useRef(-1);
    const [selectedId, setSelectedId] = useState(value);
    const [containerWidth, setContainerWidth] = useState(0);
    const [isContainerOpened, setIsContainerOpened] = useState(false);

    useEffect(() => {
      setSelectedId(value);

      if (containerRef.current) {
        selectedIDRef.current = value - 1;
      }
    }, [value]);

    useEffect(() => {
      updateWindowSize();
      window.addEventListener("resize", updateWindowSize);
      btnRef.current?.addEventListener("click", cickedMenuButton);
      window.addEventListener("click", handler);

      return () => {
        window.removeEventListener("click", handler);
        window.removeEventListener("resize", updateWindowSize);
        btnRef.current?.removeEventListener("click", cickedMenuButton);
      };
    }, []);

    const handler = (e: any) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setIsContainerOpened(false);
      }
    };

    function updateWindowSize() {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }

    useEffect(() => {
      setTimeout(
        () => menuRef.current?.scrollTo(0, selectedIDRef.current * 40),
        300
      );
    }, [selectedId, isContainerOpened]);

    function cickedMenuButton() {
      btnRef.current &&
        btnRef.current.ariaExpanded &&
        btnRef.current.ariaExpanded === "false" &&
        setIsContainerOpened(true);

      setTimeout(
        () => menuRef.current?.scrollTo(0, selectedIDRef.current * 40),
        100
      );
    }

    useEffect(() => {
      const width = containerRef.current ? containerRef.current.offsetWidth : 0;
      setContainerWidth(width);
    }, [containerRef.current]);

    return (
      <div className={className} ref={containerRef}>
        <label className="block text-[16px] font-gilroy font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
              {required && (
                <span className="text-[14px] text-error font-gilroy">* </span>
              )}
              {label}
            </span>
          )}
          <Menu>
            <MenuButton
              ref={btnRef}
              className={classNames(
                "relative h-[44px] placeholder-gray-modern-400 w-full appearance-none text-[14px] font-gilroy rounded-[8px] px-4 py-2 text-dark ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px] focus:ring-active lg:px-5",
                inputClassName
              )}
            >
              {({ open }) => (
                <>
                  <p
                    className={`${
                      selectedId < 0 ? "text-gray-modern-400" : "text-primary"
                    } text-left line-clamp-1 pr-4`}
                  >
                    {data.filter((item) => item.id == selectedId).length > 0
                      ? data.filter((item) => item.id == selectedId)[0].name ??
                        `${capitalizeFirstLetter(
                          data.filter((item) => item.id == selectedId)[0]
                            .first_name ?? ""
                        )} ${capitalizeFirstLetter(
                          data.filter((item) => item.id == selectedId)[0]
                            .last_name ?? ""
                        )}`
                      : placeholder}
                  </p>
                  <span className="absolute top-0 flex h-full w-[34px] cursor-pointer items-center justify-start text-dark-900 hover:text-dark-700 right-0 dark:text-dark-800 hover:dark:text-light-900 lg:w-9">
                    <AngleDownSmallIcon
                      className={`text-primary h-auto w-4 ${
                        open ? "rotate-180" : "rotate-0"
                      }`}
                    />
                  </span>
                </>
              )}
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems
                ref={menuRef}
                className={`max-h-[216px] scrollbar-hide overflow-y-auto flex flex-col absolute z-30 mt-2 rounded-[12px] bg-light py-1.5 text-dark shadow-card origin-top-left dark:bg-dark-250 dark:text-light`}
              >
                {data.length > 0 ? (
                  data.map((item, index) => (
                    /* Use the `active` state to conditionally style the active item. */
                    <MenuItem key={index}>
                      {({ active, close }) => (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            if (item.id == selectedId) {
                              close();
                              return;
                            }

                            setSelectedId(item.id);
                            onChange(item.id);
                            selectedIDRef.current = index;

                            close();
                          }}
                          className="flex transition-fill-colors px-4  flex-row items-start justify-between py-2 text-primary hover:bg-active hover:text-light"
                          style={{ width: containerWidth }}
                        >
                          <p className="text-left text-[14px] font-gilroy">
                            {item.name ??
                              `${capitalizeFirstLetter(
                                item.first_name ?? ""
                              )} ${capitalizeFirstLetter(
                                item.last_name ?? ""
                              )}`}
                          </p>
                          <GreenCheckIcon
                            className={`${
                              selectedId === item.id ? "block" : "hidden"
                            } w-[20px] h-[20px]`}
                          />
                        </button>
                      )}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key={-1}>
                    <div
                      className="flex transition-fill-colors px-4  flex-row items-start justify-between py-2 text-primary"
                      style={{ width: containerWidth }}
                    >
                      <p className="text-[14px] font-gilroy">
                        {L.translate("Base.nothing")}
                      </p>
                    </div>
                  </MenuItem>
                )}
              </MenuItems>
            </Transition>
          </Menu>
        </label>
        {showInfo && (
          <span
            role="info"
            className="block pt-2 text-[12px] text-secondary font-gilroy font-medium"
          >
            {info}
          </span>
        )}
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[12px] text-error font-gilroy"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

DropdownWithoutLabel.displayName = "DropdownWithoutLabel";
export default DropdownWithoutLabel;
