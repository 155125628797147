import React, { FC, useContext, useState } from "react";
import Input from "../../components/ui/input";
import Password from "../../components/ui/password";
import Button from "../../components/ui/button";
import { motion } from "framer-motion";
import { fadeInBottom } from "../../lib/framer-motion/fade-in-bottom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Navigate, useNavigate } from "react-router-dom";
import routes from "../../config/routes";
import useAuth from "../../hooks/use-auth";
import { useMutation } from "react-query";
import client from "../../api";
import { getErrorMessage } from "../../lib/utils";
import { useModalAction } from "src/components/modal-views/context";
import GlobalContext from "src/context/global-context";
import L from "i18n-react";

const SignUpPage: FC = () => {
  const { openModal } = useModalAction();
  const navigate = useNavigate();

  const schema = yup
    .object({
      email: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required")))
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          String(L.translate("ValidationErrors.invalid_email"))
        ),
      password: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required")))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\,\_])(?=.{8,})/,
          String(L.translate("ValidationErrors.password_condition"))
        ),
      first_name: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required")))
        .min(3),
      last_name: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required")))
        .min(3),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;

  const { setAlertText, setIsAlertOpened, setAlertTitle } =
    useContext(GlobalContext);

  const {
    setValue,
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const { mutate: signup, isLoading } = useMutation(
    client.users.collabRegister,
    {
      onSuccess: (data) => {
        setAlertTitle("Alert.sucess");
        setAlertText("Success.sign_up");
        setIsAlertOpened(true);

        setTimeout(() => navigate(routes.login), 300);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");

        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data &&
              error.response.data.message === "user_type_not_exist"
            ) {
              setAlertText(String(L.translate("Errors.failed_create_account")));
            } else if (
              error.response.data &&
              error.response.data.message ===
                "the_password_must_not_be_greater_than25_characters"
            ) {
              setAlertText(String(L.translate("Errors.pass_under_25")));
            } else if (
              error.response.data &&
              error.response.data.message === "email_already_taken"
            ) {
              setAlertText(String(L.translate("Errors.email_taken")));
            } else {
              setAlertText(
                getErrorMessage(
                  error.response.data.message ??
                    String(L.translate("GlobalErrors.APISomethingWrong"))
                )
              );
            }
          } else {
            setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { isAuthorized } = useAuth();
  if (isAuthorized) return <Navigate to={routes.events} />;

  const onSubmit = (data: FormData) => {
    console.log(data);
    signup(data);
  };

  return (
    <motion.div
      variants={fadeInBottom()}
      className="justify-center items-center w-full m-auto"
    >
      <div className="flex items-center w-full justify-center m-auto">
        <form
          className="flex h-full w-full sm:w-[421px] py-8 flex-col px-4 sm:px-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-2 sm:gap-0">
            <img
              alt="staff"
              src={"/logo512.png"}
              className="w-24 h-24 object-contain m-auto sm:hidden"
            />
            <p className="text-[34px] font-medium font-gilroy text-primary text-center sm:text-left">
              {String(L.translate("AuthForm.register_title"))}
            </p>
            <p className="text-[18px] font-light font-gilroy text-secondary text-center sm:text-left">
              {String(L.translate("AuthForm.register_descr"))}
            </p>
          </div>
          <div className="flex flex-col mt-10 gap-4">
            <Input
              label={String(L.translate("CommonForm.email"))}
              id="new-email"
              autoComplete="new-email"
              className="w-full"
              {...register("email")}
              error={errors.email && (errors.email.message as string)}
            />
            <Input
              label={String(L.translate("CommonForm.first_name"))}
              className="w-full"
              {...register("first_name")}
              error={errors.first_name && (errors.first_name.message as string)}
            />
            <Input
              label={String(L.translate("CommonForm.last_name"))}
              className="w-full"
              {...register("last_name")}
              error={errors.last_name && (errors.last_name.message as string)}
            />
            <Password
              label={String(L.translate("CommonForm.password"))}
              id="new-password"
              autoComplete="new-password"
              className="w-full"
              {...register("password")}
              error={errors.password && (errors.password.message as string)}
            />
          </div>
          <div className="flex flex-col gap-8 mt-10">
            <Button
              variant="validate"
              type="submit"
              className="rounded-[10px] font-gilroy text-[16px] font-bold leading-[24px] h-[64px]"
              disabled={!isValid || isLoading}
              isLoading={isLoading}
            >
              {String(L.translate("AuthForm.create_account"))}
            </Button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default SignUpPage;
