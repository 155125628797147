import { Navigate, useLocation } from "react-router-dom";

import routes from "../config/routes";
import useAuth from "../hooks/use-auth";

export default function PrivateRoute({
  children,
}: React.PropsWithChildren<{}>) {
  const { isAuthorized } = useAuth();

  if (!isAuthorized) {
    return <Navigate to={routes.home} />;
  }

  return <>{children}</>;
}
