import { FC, useContext, useEffect, useState } from "react";
import Button from "src/components/ui/button";
import InputWithoutLabel from "src/components/ui/input-without-label";
import PasswordWithoutLabel from "src/components/ui/password-without-label";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import client from "../../api";
import GlobalContext from "src/context/global-context";
import useAuth from "src/hooks/use-auth";
import L from "i18n-react";
import { useDropzone } from "react-dropzone";
import { DownloadIcon } from "../icons/download-icon";

const TemplateDocSelection = ({
  title,
  fileName,
  onFileChange,
}: {
  title: string;
  fileName: string;
  onFileChange: any;
}) => {
  const { acceptedFiles, getRootProps, open, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
  });

  const [files, setFiles] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const elements = acceptedFiles.map((file) => (
      <li
        key={file.name}
        className="font-gilroy-semibold text-primary text-[16px]"
      >
        {file.name} - {file.size} bytes
      </li>
    ));

    setFiles(elements);

    onFileChange(acceptedFiles.length == 0 ? null : acceptedFiles[0]);
  }, [acceptedFiles]);

  const onView = () => {
    window.open(fileName, "_blank");
  };

  return (
    <div className="flex w-full relative">
      <section className="border-2 border-dashed border-secondary rounded-[8px] w-full cursor-pointer">
        <div {...getRootProps({ className: "px-4 py-12" })}>
          <input {...getInputProps()} />
          <div className="font-gilroy-semibold text-[16px] text-center text-secondary">
            {title}
          </div>
        </div>
        {files.length > 0 && (
          <aside className="px-4 py-2 border-t-[1px] border-secondary border-opacity-10">
            <h4 className="font-gilroy-semibold text-secondary text-[16px]">
              {`${L.translate("Base.file")}:`}
            </h4>
            <ul>{files}</ul>
          </aside>
        )}
      </section>
      <div className="absolute top-2 right-2">
        <Button
          variant="icon"
          className="bg-success p-2 rounded-[8px] hover:bg-success-100 text-white group relative cursor-pointer"
          onClick={onView}
        >
          <DownloadIcon className="text-white w-[20px] h-[20px]" />
          <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
            {L.translate("CollaboratorProfile.view_current_one")}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default TemplateDocSelection;
