export const ConfigValue = {
  PORT: process.env.PORT || 3000,
  PUBLIC_REST_API_ENDPOINT: process.env.REACT_APP_BASE_URL || "",
  PUBLIC_ASSETS_API_ENDPOINT: process.env.REACT_APP_ASSETS_URL || "",
  WEB_CASTING_BASE_URL: process.env.REACT_APP_WEB_CASTING_BASE_URL || "",
  SIGN_BASE_URL: process.env.REACT_APP_SIGN_BASE_URL || "",
  AUTH_TOKEN_KEY: "staff-ms-auth-token",
  AUTH_REFRESH_KEY: "staff-ms-auth-refresh",
  AUTH_USER_INFO: "staff-ms-auth-user",
  COLLABORATOR_PROFILE_FILLED: "collaborator-profile-filled",
  CRYPTO_SECRET_PASS: process.env.REACT_APP_SECRET_PASS || "XkhZG4fW2t2W",
  MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_PUBLIC_MAPBOX_TOKEN || "",
  GOOGLE_MAPS_API: process.env.REACT_APP_GOOGLE_MAPS_API || "",
  GOOGLE_MAP_ID: process.env.REACT_APP_GOOGLE_MAP_ID || "",
  LANGUAGE: "staff-ms-language",
};
