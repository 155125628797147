import { FC, useEffect, useContext, useState } from "react";
import {
  format,
  subMonths,
  subYears,
  addMonths,
  startOfDay,
  lastDayOfMonth,
  addDays,
  isSameDay,
  getDay,
  getDaysInMonth,
  startOfMonth,
  getMonth,
  getYear,
  getDate,
  setDate,
} from "date-fns";
import { AngleDownIcon } from "../icons/angle-down-icon";
import { Spin } from "antd";
import { useMutation } from "react-query";
import client from "../../api";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";
import { it } from "date-fns/locale/it";
import { capitalizeFirstLetter, generateRandomString } from "src/lib/utils";
import moment from "moment";

interface MonthCalendarProps {
  selectedValue?: string[];
  onSelectDate?: any;
  error?: string;
}

const MonthCalendar: FC<MonthCalendarProps> = ({
  selectedValue,
  onSelectDate,
  error,
}) => {
  const [currentMonth, setCurrentMonth] = useState(
    selectedValue ? new Date(selectedValue[0]) : new Date()
  );
  const [currentDays, setCurrentDays] = useState<number[]>([]);
  const [countByDay, setCountByDay] = useState<{ [key: number]: number }>({});
  const [selectedDays, setSelectedDays] = useState<string[]>(
    selectedValue ?? []
  );
  const { language } = useContext(GlobalContext);

  const weeks = [
    L.translate("Weekdays.Mon"),
    L.translate("Weekdays.Tue"),
    L.translate("Weekdays.Wed"),
    L.translate("Weekdays.Thu"),
    L.translate("Weekdays.Fri"),
    L.translate("Weekdays.Sat"),
    L.translate("Weekdays.Sun"),
  ];

  const changeMonthHandle = (btnType: string) => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const { mutate: getList, isLoading } = useMutation(client.events.listByDate, {
    onSuccess: (data) => {
      console.log(data.data);
      let tempCountByDay: { [key: number]: number } = {};
      data.data.map((item) => {
        if (item.start_date && item.end_date) {
          const eventStartDate = moment(item.start_date).toDate();
          const eventEndDate = moment(item.end_date).toDate();

          if (item.start_date == item.end_date) {
            const nDay = getDate(eventStartDate);
            nDay in tempCountByDay
              ? (tempCountByDay[nDay] = tempCountByDay[nDay] + 1)
              : (tempCountByDay[nDay] = 1);
          } else {
            if (item.dates) {
              const lastDayInMonth = getDate(lastDayOfMonth(currentMonth));
              const lastDay = setDate(currentMonth, lastDayInMonth);

              let currentDate = eventStartDate;
              while (
                currentDate <=
                (eventEndDate.getTime() <= lastDay.getTime()
                  ? eventEndDate
                  : lastDay)
              ) {
                if (
                  !item.dates.includes(moment(currentDate).format("YYYY-MM-DD"))
                ) {
                  currentDate = addDays(currentDate, 1);
                  continue;
                }

                const nDay = getDate(currentDate);
                nDay in tempCountByDay
                  ? (tempCountByDay[nDay] = tempCountByDay[nDay] + 1)
                  : (tempCountByDay[nDay] = 1);

                currentDate = addDays(currentDate, 1);
              }
            }
          }
        }
      });

      setCountByDay(tempCountByDay);
    },
  });

  const getDateFullString = (day: number) => {
    const strDay = day >= 10 ? day : `0${day}`;
    const strMonth =
      getMonth(currentMonth) + 1 >= 10
        ? getMonth(currentMonth) + 1
        : `0${getMonth(currentMonth) + 1}`;
    const selDate = `${getYear(currentMonth)}-${strMonth}-${strDay}`;

    return selDate;
  };

  const onSelectedDate = (day: number) => {
    const selDate = getDateFullString(day);

    let tempDays = [...selectedDays];
    if (tempDays.includes(selDate)) {
      tempDays = tempDays.filter((item) => item != selDate);
    } else {
      tempDays = [...tempDays, selDate];
    }

    tempDays = tempDays.sort((a, b) => a.localeCompare(b));

    setSelectedDays(tempDays);
    onSelectDate(tempDays.join(","));
  };

  useEffect(() => {
    generateDays();

    setCountByDay({});
    getList({ month: getMonth(currentMonth) + 1, year: getYear(currentMonth) });
  }, [currentMonth]);

  const generateDays = () => {
    const totalDays = getDaysInMonth(currentMonth);
    const firstDayInMonth = getDay(startOfMonth(currentMonth));
    const leadingArray =
      firstDayInMonth == 1
        ? []
        : new Array(firstDayInMonth == 0 ? 6 : firstDayInMonth - 1).fill(0);

    const lastDayInMonth = getDay(lastDayOfMonth(currentMonth));
    const trailingArray =
      lastDayInMonth == 0 ? [] : new Array(6 - lastDayInMonth).fill(0);

    const daysArray = Array.from({ length: totalDays }, (_, i) => i + 1);
    setCurrentDays([...leadingArray, ...daysArray, ...trailingArray]);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row items-center justify-between">
        <button
          className="flex items-center justify-center bg-blue-dark-50 rounded-[8px] h-[36px] w-[36px] hover:bg-active text-active hover:text-white"
          onClick={() => changeMonthHandle("prev")}
        >
          <AngleDownIcon className="w-[16px] h-[16px] -rotate-90" />
        </button>
        <div className="relative text-primary font-gilroy font-semibold text-[16px]">
          {isLoading ? (
            <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
          ) : (
            capitalizeFirstLetter(
              language == "EN"
                ? format(currentMonth, "MMM yyyy")
                : format(currentMonth, "MMM yyyy", { locale: it })
            )
          )}
        </div>
        <button
          className="flex items-center justify-center bg-blue-dark-50 rounded-[8px] h-[36px] w-[36px] hover:bg-active text-active hover:text-white"
          onClick={() => changeMonthHandle("next")}
        >
          <AngleDownIcon className="w-[16px] h-[16px] rotate-90" />
        </button>
      </div>
      <div className="grid grid-cols-7 gap-[calc((100%-210px)/7)] mt-4">
        {weeks.map((item, index) => (
          <div
            key={`month-view-${index}`}
            className="font-gilroy font-semibold text-[13px] text-primary text-center"
          >
            {`${item}.`}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7 gap-[calc((100%-210px)/7)] mt-4 mb-4">
        {currentDays.map((item) => (
          <button
            key={`month-view-day-${generateRandomString()}`}
            onClick={() => onSelectedDate(item)}
            className={`${item === 0 && "invisible"} ${
              selectedDays.includes(getDateFullString(item)) &&
              "bg-success-100 text-secondary"
            } font-gilroy text-[13px] text-secondary text-center py-2 h-[54px] flex flex-col items-center justify-start rounded-[8px] hover:bg-gray-modern-50`}
          >
            <p>{item}</p>
            {countByDay[item] && (
              <p className="font-gilroy-bold text-[14px] text-active">
                {countByDay[item]}
              </p>
            )}
          </button>
        ))}
      </div>
      {error && (
        <span
          role="alert"
          className="block pt-8 text-[12px] text-error font-gilroy"
        >
          {`* ${error}`}
        </span>
      )}
    </div>
  );
};

export default MonthCalendar;
