import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { forwardRef, useState, useRef, useEffect, useContext } from "react";
import GlobalContext from "../../context/global-context";
import "../../../node_modules/flag-icons/css/flag-icons.min.css";
import classNames from "classnames";
import { Transition } from "./transition";
import { Fragment } from "react";
import { languages } from "../../languages";
import { lgNames } from "../../languages/utils";
import { generateRandomString } from "src/lib/utils";

const LangSwitcher = forwardRef<HTMLDivElement>(() => {
  const { language, setLanguage } = useContext(GlobalContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);

  const selectedIDRef = useRef(-1);
  const [selectedId, setSelectedId] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [isContainerOpened, setIsContainerOpened] = useState(false);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);
    btnRef.current?.addEventListener("click", cickedMenuButton);
    window.addEventListener("click", handler);

    return () => {
      window.removeEventListener("click", handler);
      window.removeEventListener("resize", updateWindowSize);
      btnRef.current?.removeEventListener("click", cickedMenuButton);
    };
  }, []);

  const handler = (e: any) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setIsContainerOpened(false);
    }
  };

  function updateWindowSize() {
    const width = containerRef.current ? containerRef.current.offsetWidth : 0;
    setContainerWidth(width);
  }

  useEffect(() => {
    setTimeout(() => menuRef.current?.scrollTo(0, selectedId * 40), 100);
  }, [selectedId, isContainerOpened]);

  function cickedMenuButton() {
    btnRef.current &&
      btnRef.current.ariaExpanded &&
      btnRef.current.ariaExpanded === "false" &&
      setIsContainerOpened(true);

    setTimeout(
      () => menuRef.current?.scrollTo(0, selectedIDRef.current * 40),
      100
    );
  }

  useEffect(() => {
    const width = containerRef.current ? containerRef.current.offsetWidth : 0;
    setContainerWidth(width);
  }, [containerRef.current]);

  return (
    <div ref={containerRef}>
      <Menu>
        <MenuButton
          ref={btnRef}
          className={classNames(
            "relative w-full appearance-none text-16px font-manrope font-medium bg-transparent px-4 py-2 text-dark dark:text-light lg:px-5 dark:focus:ring-active"
          )}
        >
          {({ open }) => (
            <div
              className={`${
                selectedId < 0 ? "text-other" : "text-primary"
              } text-left flex gap-2`}
            >
              <div className="lang__img">
                <span
                  className={`fi fi-${
                    language === "EN" ? "gb" : language.toLowerCase()
                  }`}
                />
              </div>
              <span className="lang__current">{language}</span>
            </div>
          )}
        </MenuButton>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems
            key={generateRandomString()}
            ref={menuRef}
            className={`max-h-[216px] scrollbar-hide overflow-y-auto flex flex-col absolute right-0 z-30 rounded-[12px] bg-light py-1.5 text-dark shadow-card origin-top-left dark:bg-dark-250 dark:text-light w-[140px]`}
            // style={{ width: containerWidth }}
          >
            {Object.keys(languages)?.map((item: string, index) => {
              return (
                /* Use the `active` state to conditionally style the active item. */
                <MenuItem key={generateRandomString()}>
                  {({ active, close }) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setLanguage(item);
                        setSelectedId(index);
                        selectedIDRef.current = index;
                        close();
                      }}
                      className="flex gap-4 transition-fill-colors px-4 flex-row items-start py-2 hover:bg-light-400 pr-[23px]"
                    >
                      <span className="text-primary text-[16px] font-medium font-manrope">
                        <span
                          className={`fi fi-${
                            item === "EN" ? "gb" : item.toLowerCase()
                          }`}
                        />
                      </span>
                      <span className="select__drop-text">
                        {item in lgNames ? lgNames[item] : "Unknown Language"}
                      </span>
                    </button>
                  )}
                </MenuItem>
              );
            })}
          </MenuItems>
        </Transition>
      </Menu>
    </div>
  );
});

LangSwitcher.displayName = "LangSwitcher";
export default LangSwitcher;
