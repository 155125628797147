import { FC, useContext, useEffect, useState, useRef } from "react";
import Button from "src/components/ui/button";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import {
  EyeColors,
  EyeColors_IT,
  HairColors,
  HairColors_IT,
  Langauges,
  Langauges_IT,
  LIST_PAGE_SIZE,
  LIST_PAGE_SIZE_50,
  Provinces,
  ShoeSizes,
} from "src/lib/constants";
import L from "i18n-react";
import { CollabDataType } from "src/lib/data-types";
import { Empty, MenuProps, Space, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useModalAction } from "src/components/modal-views/context";
import { CollabSearchQueryOptions, SearchQueryOptions } from "src/api/types";
import { SortIcon } from "src/components/icons/sort-icon";
import GlobalContext from "src/context/global-context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  calculateDistance,
  capitalizeFirstLetter,
  getColorByRate,
  getErrorMessage,
} from "src/lib/utils";
import { makeColumnHeader } from "../table-columns/make-column-header";
import moment from "moment";
import CitySelection from "../ui/city-selection";
import InputWithoutLabel from "../ui/input-without-label";
import Dropdown from "../ui/dropdown";
import { CrossIcon } from "../icons/cross-icon";
import { StarFillIcon } from "../icons/star-fill-icon";
import InputWithRange from "../ui/input-range";
import DropdownWithoutLabel from "../ui/dropdown-without-label";
import DropdownWithSearchWithoutLabel from "../ui/dropdown-search-without-label";
import CollabFilterSection from "../filter/collab";
import { PlaneIcon } from "../icons/plane-icon";
import { PaperPlaneTopIcon } from "../icons/paper-plane-top-icon";

const CollaboratorsListView = ({
  bRefresh,
  isMoreFilters,
  collabStatus,
}: {
  bRefresh: boolean;
  isMoreFilters: boolean;
  collabStatus: number;
}) => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { openModal } = useModalAction();
  const { setAlertText, setAlertTitle, setIsAlertOpened, language } =
    useContext(GlobalContext);
  const [selectedUserID, setSelectedUserID] = useState(-1);

  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<CollabDataType[]>([]);
  const [filteredDataSource, setFilteredDataSource] = useState<
    CollabDataType[]
  >([]);

  useEffect(() => {
    if (!isMounted) return;

    getList();
  }, [isMounted, bRefresh]);

  const { mutate: getList, isLoading } = useMutation(
    client.users.collabUsersAll,
    {
      onSuccess: (data) => {
        setTotal(data.results.length);
        setDataSource(
          data.results.map((item, index) => {
            let lat: number = 0;
            let lng: number = 0;

            if (item.location) {
              const positions = item.location.split(",");
              if (positions.length >= 2) {
                lat = Number(positions[0]);
                lng = Number(positions[1]);
              }
            }

            let userCity = "-";
            let userProvince = "-";
            let age = "-";
            let languages = [];
            if (item.collaborator) {
              //get city
              const items = item.collaborator.home_address.split("$$$");
              if (items.length >= 4) {
                userCity = items[1];
                userProvince = items[2];
              }

              //get age
              const birthDate = moment(item.collaborator.date_of_birth).format(
                "yyyy"
              );
              age = `${moment().diff(birthDate, "years")}`;

              //get langauge
              if ("italian" in item.collaborator)
                languages.push(L.translate("CollaboratorProfile.italian"));
              if ("english" in item.collaborator)
                languages.push(L.translate("CollaboratorProfile.english"));
              if ("french" in item.collaborator)
                languages.push(L.translate("CollaboratorProfile.french"));
              if ("german" in item.collaborator)
                languages.push(L.translate("CollaboratorProfile.german"));
              if ("spanish" in item.collaborator)
                languages.push(L.translate("CollaboratorProfile.spanish"));
              if ("other" in item.collaborator)
                languages.push(item.collaborator.other);
            }

            return {
              key: index,
              id: item.id,
              first_name: item.first_name ?? "",
              last_name: item.last_name ?? "",
              email: item.email,
              lat: lat,
              lng: lng,
              name: `${capitalizeFirstLetter(
                item.first_name ?? ""
              )} ${capitalizeFirstLetter(item.last_name ?? "")}`,
              video1: item.collaborator?.video1 ?? "",
              video2: item.collaborator?.video2 ?? "",
              cv: item.collaborator?.cv ?? "",
              main_photo: item.collaborator?.main_photo ?? "",
              photo2: item.collaborator?.photo2 ?? "",
              photo3: item.collaborator?.photo3 ?? "",
              photo4: item.collaborator?.photo4 ?? "",
              photo5: item.collaborator?.photo5 ?? "",
              photo6: item.collaborator?.photo6 ?? "",
              photo7: item.collaborator?.photo7 ?? "",
              photo8: item.collaborator?.photo8 ?? "",
              photo9: item.collaborator?.photo9 ?? "",
              sex: item.collaborator ? item.collaborator!.sex : -1,
              height: item.collaborator ? item.collaborator!.height : "-",
              shoe_size: item.collaborator ? item.collaborator!.shoe_size : "",
              instagram: item.collaborator ? item.collaborator!.instagram : "",
              telephone_number: item.collaborator
                ? item.collaborator!.telephone_number
                : "",
              info: item.collaborator ? item.collaborator! : undefined,
              city: userCity,
              province: userProvince,
              age: age,
              language: languages.length == 0 ? "-" : languages.join(", "),
              status: item.status,
              feedback: item.feedback ?? "",
              rate: item.rate ?? 0,
              confirmed: item.confirmed,
              profile_id: undefined,
              created_at: item.created_at,
              updated_at: item.updated_at,
            };
          })
        );
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_load_collaborator")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  const onRefreshList = () => {
    getList();
  };

  const onPageChange = (page: number, pageSize: number) => {
    console.log(page);
  };

  const { mutate: deleteUser, isLoading: isDeletingUser } = useMutation(
    client.users.delete,
    {
      onSuccess: (data) => {
        setAlertTitle("Alert.sucess");
        setAlertText("Success.deleted_collaborator");
        setIsAlertOpened(true);

        setTimeout(() => onRefreshList(), 300);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(
            String(L.translate("Errors.failed_delete_collaborator"))
          );
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: remindUser, isLoading: isRemindingUser } = useMutation(
    client.users.remind,
    {
      onSuccess: (data) => {
        setAlertTitle("Alert.sucess");
        setAlertText("Success.reminded_collaborator");
        setIsAlertOpened(true);
        //setTimeout(() => onRefreshList(), 300);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(
            String(L.translate("Errors.failed_remind_collaborator"))
          );
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onDeleteUser = (userId: number) => {
    openModal("CONFIRM", { payload: userId, callback: onConfirmDelete });
  };

  const onRemindUser = (userId: number) => {
    remindUser(userId);
  };

  const onRateUser = (userId: number) => {
    const selectedUsers = filteredDataSource.filter(
      (item) => item.id == userId
    );
    if (selectedUsers.length == 0) return;

    openModal("LEAVE_REVIEW", {
      payload: selectedUsers[0],
      refreshCallback: onRefreshList,
    });
  };

  const onConfirmDelete = (userId: number) => {
    //delete process
    setSelectedUserID(userId);
    deleteUser(userId);
  };

  const { mutate: getProfile, isLoading: isGettingProfile } = useMutation(
    client.collaborators.getProfile,
    {
      onSuccess: (data) => {
        setTimeout(() => {
          let lat: number = 0;
          let lng: number = 0;

          if (data.collaborator.location) {
            const positions = data.collaborator.location.split(",");
            if (positions.length >= 2) {
              lat = Number(positions[0]);
              lng = Number(positions[1]);
            }
          }

          openModal("COLLABORATOR_BID", {
            payload: {
              userID: data.collaborator.user,
              isBid: false,
              bidInfo: data.collaborator.info,
              main_photo: data.collaborator.main_photo,
              photo2: data.collaborator.photo2,
              photo3: data.collaborator.photo3,
              photo4: data.collaborator.photo4,
              photo5: data.collaborator.photo5,
              photo6: data.collaborator.photo6,
              photo7: data.collaborator.photo7,
              photo8: data.collaborator.photo8,
              photo9: data.collaborator.photo9,
              video1: data.collaborator.video1 ?? "",
              video2: data.collaborator.video2 ?? "",
              cv: data.collaborator.cv,
              lat: lat,
              lng: lng,
              confirmed: data.collaborator.confirmed,
              profile_id: data.collaborator.id,
              created_at: data.collaborator.created_at,
            },
            refreshCallback: onRefreshList,
          });
        }, 300);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_get_collaborator")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onClickedUser = (data: CollabDataType) => {
    if (data.info) {
      getProfile(data.id);
    } else {
      setAlertTitle("Alert.error");
      setAlertText(String(L.translate("Errors.no_profile")));
      setIsAlertOpened(true);
    }
  };

  const columns: ColumnsType<CollabDataType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.first_name")}
        </p>
      ),
      dataIndex: "first_name",
      className: "w-auto",
      key: "first_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.last_name")}
        </p>
      ),
      dataIndex: "last_name",
      className: "w-auto",
      key: "last_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CollaboratorProfile.sex")}
        </p>
      ),
      dataIndex: "sex",
      className: "w-auto",
      key: "sex",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value == 0 ? L.translate("Base.male") : L.translate("Base.female")}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("Base.age")}
        </p>
      ),
      dataIndex: "age",
      className: "w-auto",
      key: "age",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("Base.language")}
        </p>
      ),
      dataIndex: "language",
      className: "w-auto",
      key: "langauge",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CollaboratorProfile.height")}
        </p>
      ),
      dataIndex: "height",
      className: "w-auto",
      key: "height",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CollaboratorProfile.city")}
        </p>
      ),
      dataIndex: "city",
      className: "w-auto",
      key: "city",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => <p className="hidden" />,
      dataIndex: "action",
      className: "w-[50px]",
      render: (
        _,
        record: {
          key: React.Key;
          id: number;
          rate?: number;
          info?: { [key: string]: any };
        }
      ) => (
        <div className="flex flex-row items-center justify-start gap-2">
          {record.info ? (
            <Button
              variant="icon"
              className={`${getColorByRate(
                record.rate
              )} !text-white text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer`}
              onClick={(e) => {
                e.stopPropagation();
                onRateUser(record.id);
              }}
            >
              <StarFillIcon className="text-white w-4 h-4" />
              <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
                {L.translate("CommonForm.rate")}
              </span>
            </Button>
          ) : (
            <Button
              variant="icon"
              className="bg-active hover:bg-main !text-white text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onRemindUser(record.id);
              }}
            >
              <PaperPlaneTopIcon className="text-white w-4 h-4" />
              <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
                {L.translate("CommonForm.remind")}
              </span>
            </Button>
          )}
          <Button
            variant="icon"
            className="bg-error !text-white hover:!bg-error-100 text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
            isLoading={isDeletingUser && selectedUserID == record.id}
            disabled={isDeletingUser}
            onClick={(e) => {
              e.stopPropagation();
              onDeleteUser(record.id);
            }}
          >
            <CrossIcon className="text-white w-4 h-4" />
            <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
              {L.translate("Base.delete")}
            </span>
          </Button>
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<CollabDataType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[14px] font-medium">
          {L.translate("Base.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="flex flex-col sm:flex-row items-start justify-between w-full">
              <div className="flex flex-col items-start justify-between w-full">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.name")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {`${capitalizeFirstLetter(
                      selData.first_name
                    )} ${capitalizeFirstLetter(selData.last_name)}`}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CollaboratorProfile.sex")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.sex == 0
                      ? L.translate("Base.male")
                      : L.translate("Base.female")}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("Base.language")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.language}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CollaboratorProfile.height")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.height}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-start justify-between w-full">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CollaboratorProfile.city")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.city}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("Base.age")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.age}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-2 mt-2 w-full">
              {selData.info ? (
                <Button
                  variant="text"
                  className={`${getColorByRate(
                    selData.rate
                  )} text-[14px] font-gilroy px-8 !text-white w-full md:w-auto h-[44px] rounded-[8px]`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onRateUser(selData.id);
                  }}
                >
                  <StarFillIcon className="text-white w-5 h-5" />
                  {L.translate("CommonForm.rate")}
                </Button>
              ) : (
                <Button
                  variant="text"
                  className="bg-active hover:bg-main text-[14px] font-gilroy px-8 !text-white w-full md:w-auto h-[44px] rounded-[8px]"
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemindUser(selData.id);
                  }}
                >
                  <PaperPlaneTopIcon className="text-white w-5 h-5" />
                  {L.translate("CommonForm.remind")}
                </Button>
              )}
              <Button
                variant="text"
                className="text-[14px] font-gilroy px-8 !text-white w-full md:w-auto h-[44px] rounded-[8px] hover:bg-error-100 bg-error"
                isLoading={isDeletingUser}
                disabled={isDeletingUser}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteUser(selData.id);
                }}
              >
                <CrossIcon className="text-white w-4 h-4" />
                {L.translate("Base.delete")}
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="relative w-full mt-4">
      <CollabFilterSection
        isMore={isMoreFilters}
        dataSource={dataSource}
        setFilteredDataSource={setFilteredDataSource}
        collabStatus={collabStatus}
      />
      <Table
        locale={{
          emptyText: (
            <Empty
              description={
                <p className="font-gilroy-semibold text-secondary text-[14px]">
                  {L.translate("Base.no_data")}
                </p>
              }
            />
          ),
        }}
        showSorterTooltip={false}
        columns={isMobile ? mobileColumns : columns}
        dataSource={filteredDataSource}
        loading={isLoading || isGettingProfile || isRemindingUser}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => onClickedUser(record),
          };
        }}
        pagination={{
          total: filteredDataSource.length,
          onChange: onPageChange,
          position: ["bottomCenter"],
          defaultPageSize: LIST_PAGE_SIZE_50,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};

export default CollaboratorsListView;
