export const PencilIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_708)">
        <path
          d="M19.0442 0.956659C18.4776 0.390989 17.7098 0.0732727 16.9092 0.0732727C16.1086 0.0732727 15.3407 0.390989 14.7742 0.956659L1.22085 14.51C0.832722 14.8959 0.524981 15.355 0.315428 15.8607C0.105874 16.3663 -0.00133165 16.9085 1.24844e-05 17.4558V19.1667C1.24844e-05 19.3877 0.0878099 19.5996 0.24409 19.7559C0.40037 19.9122 0.612332 20 0.833346 20H2.54418C3.09148 20.0015 3.63365 19.8945 4.13931 19.6851C4.64496 19.4757 5.10406 19.168 5.49001 18.78L19.0442 5.22583C19.6096 4.65932 19.9271 3.89163 19.9271 3.09124C19.9271 2.29085 19.6096 1.52316 19.0442 0.956659ZM4.31168 17.6017C3.84168 18.0685 3.20665 18.3314 2.54418 18.3333H1.66668V17.4558C1.66584 17.1274 1.73014 16.8021 1.85588 16.4987C1.98161 16.1953 2.16628 15.9199 2.39918 15.6883L12.685 5.40249L14.6017 7.31916L4.31168 17.6017ZM17.865 4.04749L15.7767 6.13666L13.86 4.22416L15.9492 2.13499C16.075 2.00942 16.2244 1.90986 16.3887 1.842C16.553 1.77414 16.7291 1.73932 16.9069 1.73951C17.0847 1.7397 17.2607 1.77491 17.4249 1.84313C17.5891 1.91134 17.7382 2.01123 17.8638 2.13708C17.9893 2.26293 18.0889 2.41228 18.1568 2.5766C18.2246 2.74093 18.2594 2.91701 18.2592 3.0948C18.2591 3.27258 18.2238 3.44859 18.1556 3.61277C18.0874 3.77695 17.9875 3.92608 17.8617 4.05166L17.865 4.04749Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_708">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
