import { FC, useContext, useEffect, useState } from "react";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { LIST_PAGE_SIZE, WebCastingStatus } from "src/lib/constants";
import { CastingDataType, CollabDataType } from "src/lib/data-types";
import { Dropdown, Empty, MenuProps, Space, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  capitalizeFirstLetter,
  getWebCastingStatusColor,
  getWebCastingStatusName,
  makeFormattedDateString,
} from "src/lib/utils";
import { useNavigate } from "react-router-dom";
import { Event, SearchQueryOptions } from "src/api/types";
import GlobalContext from "src/context/global-context";
import { useMutation } from "react-query";
import client from "../../api";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import TailwindDatePicker from "src/components/ui/date-picker";
import InputWithoutLabel from "src/components/ui/input-without-label";
import L from "i18n-react";
import MonthPicker from "src/components/ui/month-picker";
import { SortIcon } from "src/components/icons/sort-icon";
import Button from "src/components/ui/button";
import { makeColumnHeader } from "src/components/table-columns/make-column-header";
import { CopyIcon } from "src/components/icons/copy-icon";
import { ConfigValue } from "src/config";
import routes from "src/config/routes";
import { PlusIcon } from "src/components/icons/plus-icon";
import { FileEditIcon } from "src/components/icons/file-edit-icon";
import { PencilIcon } from "src/components/icons/pencil-icon";
import { ExchangeIcon } from "src/components/icons/exchange-icon";
import { ExclamationIcon } from "src/components/icons/exclamation-icon";
import { useModalAction } from "src/components/modal-views/context";
import { EyeIcon } from "src/components/icons/eye-icon";
import { CrossIcon } from "src/components/icons/cross-icon";
import { LeafHeartIcon } from "src/components/icons/leaf-heart-icon";

const CastingPage: FC = () => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();
  const { openModal } = useModalAction();

  const [searchParams, setSearchParams] = useState<SearchQueryOptions>({
    per_page: LIST_PAGE_SIZE,
    page: 1,
    sort: "created_at",
    direction: "desc",
    event_name: "",
    event_date: "",
    client_name: "",
    status: -1,
  });
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<CastingDataType[]>([]);
  const { setAlertText, setAlertTitle, setIsAlertOpened, geoPosition } =
    useContext(GlobalContext);

  useEffect(() => {
    if (!isMounted) return;

    const delayDebounceFn = setTimeout(() => {
      getList(searchParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    isMounted,
    searchParams.page,
    searchParams.sort,
    searchParams.direction,
    searchParams.event_name,
    searchParams.event_date,
    searchParams.client_name,
    searchParams.status,
  ]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 960 ? true : false);
  }

  const onClickedEvent = (event_id: number, event: Event) => {
    if (event.status == 4) {
      setAlertTitle("Alert.error");
      setAlertText("Errors.event_archived");
      setIsAlertOpened(true);

      return;
    }

    if (event.status == 10) {
      setAlertTitle("Alert.error");
      setAlertText("Errors.event_deleted");
      setIsAlertOpened(true);

      return;
    }

    getEvent(event_id);
  };

  const { mutate: getList, isLoading } = useMutation(client.webCasting.list, {
    onSuccess: (data) => {
      setTotal(data.count);
      setDataSource(
        data.results.map((item, index) => ({
          key: index,
          id: item.id,
          event_id: item.event.id,
          event_name: capitalizeFirstLetter(item.event.name),
          client_name: item.event.client.name,
          status: item.status,
          code: item.code,
          event_start_date: item.event.start_date ?? "",
          event_end_date: item.event.end_date ?? "",
          event: item.event,
          created_at: item.created_at,
        }))
      );
    },
    onError: (error: any) => {
      setAlertTitle("Alert.error");
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        setAlertText(String(L.translate("Errors.failed_load_casting_codes")));
      }
      setIsAlertOpened(true);
    },
  });

  const { mutate: getEvent, isLoading: isGettingEvent } = useMutation(
    client.events.get,
    {
      onSuccess: (data) => {
        navigate(routes.eventPreview, { state: data.data });
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.event_not_found")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: resetCasting, isLoading: isReseting } = useMutation(
    client.webCasting.reset,
    {
      onSuccess: (data) => {
        setAlertTitle("Alert.sucess");
        setAlertText("Success.reset_casting");
        setIsAlertOpened(true);

        getList(searchParams);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_reset_casting")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: archiveCasting, isLoading: isArchiving } = useMutation(
    client.webCasting.archive,
    {
      onSuccess: (data) => {
        setAlertTitle("Alert.sucess");
        setAlertText("Success.archive_casting");
        setIsAlertOpened(true);

        getList(searchParams);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_archive_casting")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: deleteCasting, isLoading: isDeleting } = useMutation(
    client.webCasting.delete,
    {
      onSuccess: (data) => {
        setAlertTitle("Alert.sucess");
        setAlertText("Success.delete_casting");
        setIsAlertOpened(true);

        getList(searchParams);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_delete_casting")));
        }
        setIsAlertOpened(true);
      },
    }
  );
  const onRefreshList = () => {
    getList(searchParams);
  };

  const onResetCasting = (nCastingID: number) => {
    openModal("CONFIRM", {
      payload: nCastingID,
      callback: onResetConfirm,
    });
  };

  const onResetConfirm = (nCastingID: number) => {
    //reset process
    resetCasting(nCastingID);
  };

  const onDelete = (nCastingID: number) => {
    openModal("CONFIRM", { payload: nCastingID, callback: onConfirmDelete });
  };

  const onConfirmDelete = (nCastingID: number) => {
    deleteCasting(nCastingID);
  };

  const onArchive = (nCastingID: number) => {
    openModal("CONFIRM", { payload: nCastingID, callback: onConfirmArchive });
  };

  const onConfirmArchive = (nCastingID: number) => {
    archiveCasting(nCastingID);
  };

  const onUpdateCastingPhotos = (nCastingID: number) => {
    openModal("CASTING_PHOTOS", {
      payload: nCastingID,
      refreshCallback: onRefreshList,
    });
  };

  const onEditCasting = (event: Event) => {
    navigate(routes.eventPreview, { state: event });
  };

  const onCopyCode = (code: string) => {
    window.open(`${ConfigValue.WEB_CASTING_BASE_URL}/${code}`, "_blank");
    /*
    navigator.clipboard
      .writeText(`${ConfigValue.WEB_CASTING_BASE_URL}/${code}`)
      .then(() => {
        setAlertTitle("Alert.sucess");
        setAlertText("Success.web_casting_link_copied");
        setIsAlertOpened(true);
      });
      */
  };

  const onCreateNew = () => {
    navigate(routes.createCasting);
  };

  const onPageChange = (page: number, pageSize: number) => {
    setSearchParams({ ...searchParams, page });
  };

  const columns: ColumnsType<CastingDataType> = [
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.event_name")),
          "event_name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "event_name",
      className: "w-auto",
      key: "event_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.client_name")),
          "client_name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "client_name",
      className: "w-auto",
      key: "client_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("Base.created")}
        </p>
      ),
      dataIndex: "created_at",
      className: "w-[100px]",
      key: "created_at",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {makeFormattedDateString(value)}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.event_start_date")),
          "event_start_date",
          searchParams,
          setSearchParams
        ),
      dataIndex: "event_start_date",
      className: "w-[160px]",
      key: "event_start_date",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {makeFormattedDateString(value)}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.event_end_date")),
          "event_end_date",
          searchParams,
          setSearchParams
        ),
      dataIndex: "event_end_date",
      className: "w-[140px]",
      key: "event_end_date",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {makeFormattedDateString(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.status")}
        </p>
      ),
      dataIndex: "status",
      className: "w-[100px]",
      key: "status",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p
            className="font-gilroy text-white text-[13px] sm:text-[14px] rounded-[8px] px-2"
            style={{
              backgroundColor: getWebCastingStatusColor(Number(value)),
            }}
          >
            {L.translate(getWebCastingStatusName(Number(value)))}
          </p>
        </div>
      ),
    },
    {
      title: () => <p className="hidden" />,
      dataIndex: "action",
      className: "w-[50px]",
      render: (
        _,
        record: { key: React.Key; id: number; code: string; event: Event }
      ) => {
        return (
          <div className="flex flex-row items-center justify-center gap-2">
            <Button
              variant="icon"
              className="bg-error !text-white hover:!bg-red-400 text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(record.id);
              }}
            >
              <CrossIcon className="text-white w-4 h-4" />
              <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
                {L.translate("Base.delete")}
              </span>
            </Button>
            <Button
              variant="icon"
              className="bg-active !text-white hover:!bg-main text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onCopyCode(record.code);
              }}
            >
              <CopyIcon className="text-white w-4 h-4" />
              <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
                {L.translate("Base.preview")}
              </span>
            </Button>
            <Button
              variant="icon"
              className="!text-white hover:bg-red-300 bg-error text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onArchive(record.id);
              }}
            >
              <LeafHeartIcon className="text-white w-4 h-4" />
              <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
                {L.translate("Base.archive")}
              </span>
            </Button>
            {/*
              <Button
                variant="icon"
                className="bg-warning !text-white hover:!bg-warning-100 text-[14px] font-gilroy p-2 rounded-[12px]"
                onClick={(e) => {
                  e.stopPropagation();
                  onEditCasting(record.event);
                }}
              >
                <FileEditIcon className="text-white w-4 h-4" />
              </Button>
            */}
            <Button
              variant="icon"
              className="bg-warning !text-white hover:!bg-warning-100 text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onUpdateCastingPhotos(record.id);
              }}
            >
              <EyeIcon className="text-white w-4 h-4" />
              <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
                {L.translate("CommonForm.edit_photo")}
              </span>
            </Button>

            <Button
              variant="icon"
              className="bg-purple !text-white hover:!bg-red-400 text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onResetCasting(record.id);
              }}
            >
              <ExclamationIcon className="text-white w-4 h-4" />
              <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
                {L.translate("Base.reset")}
              </span>
            </Button>
          </div>
        );
      },
    },
  ];

  const mobileColumns: ColumnsType<CastingDataType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[14px] font-medium">
          {L.translate("Base.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="w-full flex flex-col sm:flex-row gap-4 items-start justify-between">
              <div className="w-full flex flex-col gap-4 items-start justify-center">
                <div className="flex flex-row items-center justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.event_name")}:`}
                  </p>
                  <p className="text-primary text-[12px] font-gilroy font-medium">
                    {capitalizeFirstLetter(selData.event_name)}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.client_name")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {capitalizeFirstLetter(selData.client_name)}
                  </p>
                </div>{" "}
                <div className="flex flex-row items-center justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.status")}:`}
                  </p>
                  <div className="flex flex-row gap-2 items-center">
                    <p
                      className="font-gilroy text-white text-[14px] rounded-[8px] px-2"
                      style={{
                        backgroundColor: getWebCastingStatusColor(
                          Number(selData.status)
                        ),
                      }}
                    >
                      {L.translate(
                        getWebCastingStatusName(Number(selData.status))
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col gap-4 items-start justify-center">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("Base.created")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {makeFormattedDateString(selData.created_at)}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.event_start_date")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {makeFormattedDateString(selData.event_start_date)}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.event_end_date")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {makeFormattedDateString(selData.event_end_date)}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-start justify-center gap-2 w-full">
              <Button
                variant="text"
                className="text-[14px] font-gilroy !text-white w-full h-[40px] rounded-[8px] hover:bg-red-400 bg-error"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(selData.id);
                }}
              >
                <CrossIcon className="text-white w-4 h-4" />
                {L.translate("Base.delete")}
              </Button>
              <Button
                variant="text"
                className="bg-active text-[14px] w-full font-gilroy px-8 !text-white h-[40px] rounded-[8px] hover:bg-main"
                onClick={(e) => {
                  e.stopPropagation();
                  onCopyCode(selData.code);
                }}
              >
                <CopyIcon className="text-white w-4 h-4" />
                {L.translate("Base.preview")}
              </Button>
              <Button
                variant="icon"
                className="text-[14px] font-gilroy w-full px-8 !text-white h-[40px] rounded-[8px] hover:bg-green-100 bg-green-700"
                onClick={(e) => {
                  e.stopPropagation();
                  onArchive(selData.id);
                }}
              >
                <LeafHeartIcon className="text-white w-4 h-4" />
                <span className="text-[14px] font-gilroy">
                  {L.translate("Base.archive")}
                </span>
              </Button>
              {/*
                <Button
                  variant="icon"
                  className="bg-warning !text-white px-8 hover:!bg-warning-100 h-[40px] text-[14px] font-gilroy p-2 rounded-[8px]"
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditCasting(selData.event);
                  }}
                >
                  <FileEditIcon className="text-white w-4 h-4" />
                  {L.translate("Base.edit")}
                </Button>
              */}
              <Button
                variant="icon"
                className="bg-warning !text-white w-full px-8 hover:!bg-warning-100 h-[40px] text-[14px] font-gilroy p-2 rounded-[8px]"
                onClick={(e) => {
                  e.stopPropagation();
                  onUpdateCastingPhotos(selData.id);
                }}
              >
                <EyeIcon className="text-white w-4 h-4" />
                {L.translate("CommonForm.edit_photo")}
              </Button>
              <Button
                variant="icon"
                className="bg-purple !text-white w-full px-8 hover:!bg-red-400 h-[40px]  text-[14px] font-gilroy p-2 rounded-[8px]"
                onClick={(e) => {
                  e.stopPropagation();
                  onResetCasting(selData.id);
                }}
              >
                <ExclamationIcon className="text-white w-4 h-4" />
                {L.translate("Base.reset")}
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full h-full bg-whole-gray">
      <div className="bg-white p-4 rounded-[8px] mb-4">
        <div className="w-full sm:w-auto flex flex-row items-center justify-between gap-4">
          <div className="text-primary text-[24px] font-gilroy-semibold">
            {L.translate("Sidebar.casting")}
          </div>
          <Button
            variant="icon"
            className="bg-active p-2 rounded-[8px] hover:bg-main hidden"
            onClick={onCreateNew}
          >
            <PlusIcon className="text-white w-[20px] h-[20px]" />
          </Button>
        </div>
        <div className="mt-4 flex flex-col items-center justify-between bg-white rounded-[8px] mb-4">
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full gap-4">
            <InputWithoutLabel
              placeholder={String(L.translate("CommonForm.event_name"))}
              className="w-full"
              value={searchParams.event_name}
              showClose={searchParams.event_name!.length > 0}
              clickedClose={() =>
                setSearchParams({ ...searchParams, event_name: "", page: 1 })
              }
              onChange={(e) =>
                setSearchParams({
                  ...searchParams,
                  event_name: e.target.value,
                  page: 1,
                })
              }
            />
            <MonthPicker
              placeholder={String(L.translate("CommonForm.filter_by_month"))}
              value={searchParams.event_date ?? ""}
              onChange={(strDate?: string) =>
                setSearchParams({
                  ...searchParams,
                  event_date: strDate ?? "",
                  page: 1,
                })
              }
            />
            {/*
              <TailwindDatePicker
                placeholder={String(L.translate("CommonForm.event_date"))}
                className="w-full z-30"
                value={searchParams.event_date ?? ""}
                onChange={(strDate?: string) =>
                  setSearchParams({
                    ...searchParams,
                    event_date: strDate ?? "",
                  })
                }
              />
            */}
            <InputWithoutLabel
              placeholder={String(L.translate("CommonForm.client_name"))}
              className="w-full"
              showClose={searchParams.client_name!.length > 0}
              clickedClose={() =>
                setSearchParams({ ...searchParams, client_name: "", page: 1 })
              }
              value={searchParams.client_name}
              onChange={(e) =>
                setSearchParams({
                  ...searchParams,
                  client_name: e.target.value,
                  page: 1,
                })
              }
            />
            <DropdownWithoutLabel
              placeholder={String(L.translate("CommonForm.status"))}
              className="w-full"
              data={[
                { id: -1, name: String(L.translate("CommonForm.status")) },
                ...WebCastingStatus.map((item) => ({
                  ...item,
                  name: String(L.translate(item.name)),
                })),
              ]}
              onChange={(e: number) =>
                setSearchParams({ ...searchParams, status: e, page: 1 })
              }
            />
          </div>

          <div className="mt-4 relative w-full">
            <Table
              locale={{
                emptyText: (
                  <Empty
                    description={
                      <p className="font-gilroy-semibold text-secondary text-[14px]">
                        {L.translate("Base.no_data")}
                      </p>
                    }
                  />
                ),
              }}
              showSorterTooltip={false}
              columns={isMobile ? mobileColumns : columns}
              dataSource={dataSource}
              loading={
                isLoading ||
                isGettingEvent ||
                isReseting ||
                isDeleting ||
                isArchiving
              }
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) =>
                    onClickedEvent(record.event_id, record.event),
                };
              }}
              pagination={{
                total,
                current: searchParams.page,
                onChange: onPageChange,
                position: ["bottomCenter"],
                defaultPageSize: LIST_PAGE_SIZE,
                showSizeChanger: false,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CastingPage;
