import { forwardRef } from "react";
import classNames from "classnames";
import { CrossIcon } from "../icons/cross-icon";

type InputWithoutLabelProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  required?: boolean;
  showInfo?: boolean;
  info?: string;
  showClose?: boolean;
  allCaps?: boolean;
  clickedClose?: any;
};

const InputWithoutLabel = forwardRef<HTMLInputElement, InputWithoutLabelProps>(
  (
    {
      label,
      error,
      type = "text",
      className,
      inputClassName = "bg-gray-modern-100",
      required,
      showInfo = false,
      showClose = false,
      allCaps = false,
      clickedClose,
      info,
      ...props
    },
    ref
  ) => {
    return (
      <div className={className}>
        <label className="block text-16px font-gilroy font-medium text-secondary relative">
          {label && (
            <span className="block cursor-pointer pb-2 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
              {required && (
                <span className="text-[14px] text-error font-gilroy">* </span>
              )}
              {label}
            </span>
          )}
          <input
            type={type}
            ref={ref}
            {...props}
            className={classNames(
              "h-[44px] placeholder-gray-modern-400 border-none w-full appearance-none text-[14px] font-gilroy font-medium rounded-[8px] px-4 py-2 text-primary ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px] lg:px-5",
              showClose && classNames("!pr-10"),
              allCaps && "uppercase",
              inputClassName
            )}
          />
          {showClose && (
            <button
              className="absolute bottom-0 right-0 h-[44px] w-[44px] flex items-center justify-center"
              onClick={() => clickedClose && clickedClose()}
            >
              <CrossIcon className="w-3 h-3 text-secondary" />
            </button>
          )}
        </label>
        {showInfo && (
          <span
            role="info"
            className="block pt-2 text-[12px] text-secondary font-gilroy font-medium"
          >
            {info}
          </span>
        )}
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[12px] text-error font-gilroy"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

InputWithoutLabel.displayName = "InputWithoutLabel";
export default InputWithoutLabel;
