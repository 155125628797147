export const shakeElement = (elementRef: React.RefObject<HTMLDivElement>) => {
  if (elementRef.current) {
    elementRef.current.classList.add("animate-shake");

    // Remove the animation class after the animation is complete
    setTimeout(() => {
      elementRef.current?.classList.remove("animate-shake");
    }, 500);
  }
};
