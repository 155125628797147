import { FC, useContext, useEffect, useState } from "react";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import L from "i18n-react";
import { Dropdown, Empty, MenuProps, Space, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useModalAction } from "../modal-views/context";
import { AdsSearchQueryOptions } from "src/api/types";
import { LIST_PAGE_SIZE } from "src/lib/constants";
import { AdsType } from "src/lib/data-types";
import GlobalContext from "src/context/global-context";
import { useMutation } from "react-query";
import client from "../../api";
import { makeColumnHeader } from "../table-columns/make-column-header";
import { capitalizeFirstLetter, makeFormattedDateString } from "src/lib/utils";

const JobAdsPastList = ({ isRefreshing }: { isRefreshing: boolean }) => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { openModal } = useModalAction();
  const [searchParams, setSearchParams] = useState<AdsSearchQueryOptions>({
    per_page: LIST_PAGE_SIZE,
    page: 1,
    sort: "order",
    direction: "desc",
  });

  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<AdsType[]>([]);
  const { setAlertText, setAlertTitle, setIsAlertOpened, language } =
    useContext(GlobalContext);

  useEffect(() => {
    if (!isMounted) return;

    const delayDebounceFn = setTimeout(() => {
      getList(searchParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    isMounted,
    searchParams.page,
    searchParams.sort,
    searchParams.direction,
    isRefreshing,
  ]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 720 ? true : false);
  }

  const { mutate: getList, isLoading } = useMutation(client.ads.list, {
    onSuccess: (data) => {
      setTotal(data.count);

      setDataSource(
        data.results.map((item, index) => ({
          key: index,
          id: item.event.id,
          order: item.order,
          send_option: item.send_option,
          event_name: item.event.name,
          details: item.details,
          collab_amount: item.collab_amount,
          created_at: item.created_at,
        }))
      );
    },
    onError: (error: any) => {
      setAlertTitle("Alert.error");
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        setAlertText(String(L.translate("Errors.failed_load_ads")));
      }
      setIsAlertOpened(true);
    },
  });

  const onPageChange = (page: number, pageSize: number) => {
    setSearchParams({ ...searchParams, page });
  };

  const onClickedAds = (data: AdsType) => {
    openModal("ADS_STATUS", {
      payload: { adsData: data },
    });
  };

  const columns: ColumnsType<AdsType> = [
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.event")),
          "event_name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "event_name",
      className: "w-auto",
      key: "event_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.send_option")}
        </p>
      ),
      dataIndex: "send_option",
      className: "w-auto",
      key: "send_option",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value == 0
              ? L.translate("Base.whatsapp")
              : L.translate("Base.email")}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("Base.details")}
        </p>
      ),
      dataIndex: "details",
      className: "w-auto",
      key: "details",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center group relative cursor-pointer">
          <p
            className="font-gilroy text-primary text-[13px] sm:text-[14px]"
            dangerouslySetInnerHTML={{
              __html: value.includes("\\n")
                ? value.replace(/\\n/g, "<br />")
                : value.replace(/\n/g, "<br />"),
            }}
          />
          {/*
            <>
              <p
                className="font-gilroy text-primary text-[13px] sm:text-[14px] line-clamp-3"
                dangerouslySetInnerHTML={{
                  __html: value.includes("\\n")
                    ? value.replace(/\\n/g, "<br />")
                    : value.replace(/\n/g, "<br />"),
                }}
              />
              <span
                className="absolute top-[60px] left-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-16px]"
                dangerouslySetInnerHTML={{
                  __html: value.includes("\\n")
                    ? value.replace(/\\n/g, "<br />")
                    : value.replace(/\n/g, "<br />"),
                }}
              />
            </>
          */}
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.number_of_collaborators")}
        </p>
      ),
      dataIndex: "collab_amount",
      className: "w-auto",
      key: "collab_amount",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.date")),
          "created_at",
          searchParams,
          setSearchParams
        ),
      dataIndex: "created_at",
      className: "w-auto",
      key: "created_at",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? makeFormattedDateString(value, true) : "-"}
          </p>
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<AdsType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[14px] font-medium">
          {L.translate("Base.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="flex flex-col sm:flex-row items-start justify-between w-full">
              <div className="flex flex-col items-start justify-between w-full">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.event")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {capitalizeFirstLetter(selData.event_name)}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.send_option")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.send_option == 0
                      ? L.translate("Base.whatsapp")
                      : L.translate("Base.email")}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.date")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.created_at
                      ? makeFormattedDateString(selData.created_at, true)
                      : "-"}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.number_of_collaborators")}`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.collab_amount}
                  </p>
                </div>

                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("Base.details")}`}
                  </p>
                  <p
                    className="text-primary text-[14px] font-gilroy font-medium"
                    dangerouslySetInnerHTML={{
                      __html: selData.details.includes("\\n")
                        ? selData.details.replace(/\\n/g, "<br />")
                        : selData.details.replace(/\n/g, "<br />"),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-8">
      <div className="text-primary text-[24px] font-gilroy-semibold">
        {L.translate("CommonForm.job_ads_list")}
      </div>
      <div className="relative w-full">
        <Table
          locale={{
            emptyText: (
              <Empty
                description={
                  <p className="font-gilroy-semibold text-secondary text-[14px]">
                    {L.translate("Base.no_data")}
                  </p>
                }
              />
            ),
          }}
          showSorterTooltip={false}
          columns={isMobile ? mobileColumns : columns}
          dataSource={dataSource}
          loading={isLoading}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => onClickedAds(record),
            };
          }}
          pagination={{
            total,
            current: searchParams.page,
            onChange: onPageChange,
            position: ["bottomCenter"],
            defaultPageSize: LIST_PAGE_SIZE,
            showSizeChanger: false,
          }}
        />
      </div>
    </div>
  );
};

export default JobAdsPastList;
