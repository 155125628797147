import { FC, useContext, useEffect, useState } from "react";
import { CalendarPlusIcon } from "src/components/icons/calendar-plus-icon";
import Button from "src/components/ui/button";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import InputWithoutLabel from "src/components/ui/input-without-label";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import TailwindDatePicker from "src/components/ui/date-picker";
import { BidStatus, LIST_PAGE_SIZE } from "src/lib/constants";
import {
  CollaboratorBidType,
  EventDataType,
  NewCollaboratorBidType,
} from "src/lib/data-types";
import { Dropdown, Empty, MenuProps, Space, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  capitalizeFirstLetter,
  getBidStatusColor,
  getBidStatusName,
  getEventStatusColor,
  getEventStatusName,
  getPaidStatusColor,
  getPaidStatusName,
  makeFormattedDateString,
  makeNumberWithFloatingDigits,
} from "src/lib/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "../../config/routes";
import { useMutation } from "react-query";
import client from "../../api";
import { Event, SearchQueryOptions } from "src/api/types";
import GlobalContext from "src/context/global-context";
import { SortIcon } from "src/components/icons/sort-icon";
import moment from "moment";
import L from "i18n-react";
import MonthPicker from "src/components/ui/month-picker";
import { useModalAction } from "src/components/modal-views/context";
import { makeColumnHeader } from "src/components/table-columns/make-column-header";
import { FileEditIcon } from "src/components/icons/file-edit-icon";
import { DownloadIcon } from "src/components/icons/download-icon";

const CollaboratorEventsPage: FC = () => {
  const isMounted = useIsMounted();
  const [queryParams] = useSearchParams();
  const [selectedEventID, setSelectedEventID] = useState<number | undefined>(
    undefined
  );
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();
  const { openModal } = useModalAction();
  const [searchParams, setSearchParams] = useState<SearchQueryOptions>({
    per_page: LIST_PAGE_SIZE,
    page: 1,
    sort: "event_date",
    direction: "desc",
    event_name: "",
    event_date: "",
    client_name: "",
    bid_status: -1,
  });
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<NewCollaboratorBidType[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const { setAlertText, setAlertTitle, setIsAlertOpened, language } =
    useContext(GlobalContext);

  useEffect(() => {
    if (!isMounted) return;

    queryParams.forEach((value, key) => {
      if (key == "id" && Number(value)) getInfo(Number(value));
    });

    getEvents();

    const delayDebounceFn = setTimeout(() => {
      getList(searchParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    isMounted,
    searchParams.page,
    searchParams.sort,
    searchParams.direction,
    searchParams.event_name,
    searchParams.event_date,
    searchParams.client_name,
    searchParams.bid_status,
  ]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 860 ? true : false);
  }

  const onViewContractPDF = (pdfLink: string) => {
    window.open(pdfLink, "_blank");
  };

  const onClickedEvent = (eventID: number) => {
    let selEvents = events.filter((item) => item.id == eventID);

    if (selEvents.length > 0) {
      openModal("EVENT_BID_DETAILS", {
        payload: { event: selEvents[0] },
      });
    }
  };

  const { mutate: getInfo, isLoading: isLoadingEach } = useMutation(
    client.events.get,
    {
      onSuccess: (data) => {
        if (data.data.bid_contract_signed != 1) {
          setAlertTitle("Alert.error");
          setAlertText(String(L.translate("Errors.sign_contract_error")));
          setIsAlertOpened(true);

          return;
        }

        setTimeout(
          () =>
            openModal("SIGN_CONTRACT", {
              payload: {
                event: {
                  key: 0,
                  id: data.data.id,
                  name: data.data.name,
                  invoice_no: data.data.invoice_no ?? "-",
                  description: data.data.description,
                  account: data.data.account,
                  quote_no: data.data.quote_no,
                  invoice_descr: data.data.invoice_descr,
                  city: data.data.city,
                  contract: data.data.contract,
                  contract_received: data.data.contract_received,
                  number_of_collaborators: data.data.number_of_collaborators,
                  numbers_per_day: data.data.numbers_per_day,
                  contract_status: data.data.number_of_collaborators
                    ? `${data.data.contract_received}/${data.data.number_of_collaborators}`
                    : "",
                  location: data.data.location ?? "",
                  coordinate: data.data.coordinate ?? "",
                  start_date: data.data.start_date ?? "",
                  end_date: data.data.end_date ?? "",
                  status: data.data.status,
                  bid_id: data.data.bid_id,
                  bid_status: data.data.bid_status,
                  bid_comp: data.data.bid_comp ? `${data.data.bid_comp}` : "-",
                  bid_reimburse: data.data.bid_reimburse
                    ? `${data.data.bid_reimburse}`
                    : "-",
                  bid_notes: data.data.bid_notes ?? "-",
                  bid_contract_signed: data.data.bid_contract_signed,
                  bid_event_date: data.data.bid_event_date,
                  bid_payment_date: data.data.bid_payment_date,
                  client_name: data.data.client.name,
                  client: data.data.client,
                  created_at: data.data.created_at,
                  updated_at: data.data.updated_at,
                },
              },
              refreshCallback: onRefresh,
            }),
          300
        );
      },
    }
  );

  const { mutate: getEvents, isLoading: isLoadingEvents } = useMutation(
    client.collaborators.events,
    {
      onSuccess: (data) => {
        setEvents(data.results);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_load_events")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getList, isLoading } = useMutation(
    client.collaborators.getAllBids,
    {
      onSuccess: (data) => {
        setTotal(data.count);
        setDataSource(
          data.results.map((item, index) => ({
            key: index,
            id: item.id,
            status: item.status,
            comp: item.comp,
            reimburse: item.reimburse,
            contract_signed: item.contract_signed,
            contract_pdf: item.contract_pdf,
            payment_date: item.payment_date,
            event_id: item.event_id,
            event_name: item.event_name,
            start_date: item.start_date,
            end_date: item.end_date,
            full_date: `${makeFormattedDateString(
              item.start_date
            )} ~ ${makeFormattedDateString(item.end_date)}`,
          }))
        );
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_load_bids")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onPageChange = (page: number, pageSize: number) => {
    setSearchParams({ ...searchParams, page });
  };

  const onRefresh = () => {
    getList(searchParams);
  };

  const onSignContract = (eventID: number) => {
    let selEvents = events.filter((item) => item.id == eventID);

    if (selEvents.length > 0) {
      openModal("SIGN_CONTRACT", {
        payload: { event: selEvents[0] },
        refreshCallback: onRefresh,
      });
    }
  };

  const columns: ColumnsType<NewCollaboratorBidType> = [
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.event")),
          "event_name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "event_name",
      className: "w-auto",
      key: "event_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[11px]">{value}</p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.event_date")}
        </p>
      ),
      dataIndex: "full_date",
      className: "w-auto",
      key: "full_date",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? value : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.comp")),
          "comp",
          searchParams,
          setSearchParams
        ),
      dataIndex: "comp",
      className: "w-auto",
      key: "comp",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {Number(value) ? makeNumberWithFloatingDigits(value, 2) : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.reimburse")),
          "reimburse",
          searchParams,
          setSearchParams
        ),
      dataIndex: "reimburse",
      className: "w-auto",
      key: "reimburse",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {Number(value) ? makeNumberWithFloatingDigits(value, 2) : "-"}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.payment_date")}
        </p>
      ),
      dataIndex: "payment_date",
      className: "w-auto",
      key: "payment_date",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value ? makeFormattedDateString(value) : "-"}
          </p>
        </div>
      ),
    },
    /*
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.status")}
        </p>
      ),
      dataIndex: "status",
      className: "w-auto",
      key: "status",
      render: (value, record) => (
        <div className="flex flex-row gap-2 items-center cursor-pointer ">
          <p
            className="font-gilroy text-white text-[13px] sm:text-[14px] rounded-[8px] px-2 "
            style={{ backgroundColor: getBidStatusColor(value) }}
          >
            {L.translate(getBidStatusName(value))}
          </p>
        </div>
      ),
    },
    */
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.contract_sign_status")}
        </p>
      ),
      dataIndex: "contract_signed",
      className: "w-[136px]",
      key: "contract_signed",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p
            className="font-gilroy text-white text-[13px] sm:text-[14px] rounded-[8px] px-2"
            style={{ backgroundColor: getPaidStatusColor(value) }}
          >
            {L.translate(getPaidStatusName(value))}
          </p>
        </div>
      ),
    },

    {
      title: () => <p className="hidden" />,
      dataIndex: "action",
      className: "w-[50px]",
      render: (
        _,
        record: {
          key: React.Key;
          id: number;
          contract_signed: number;
          contract_pdf: string;
          event_id?: number;
        }
      ) => (
        <div className="flex flex-row items-center justify-center gap-2">
          {record.contract_signed == 1 && (
            <Button
              variant="icon"
              className="bg-warning !text-white hover:!bg-warning-100 text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onSignContract(record.event_id!);
              }}
            >
              <FileEditIcon className="text-white w-4 h-4" />
              <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
                {L.translate("CommonForm.sign_contract")}
              </span>
            </Button>
          )}
          {record.contract_signed >= 2 && (
            <Button
              variant="icon"
              className="bg-error !text-white hover:!bg-error-100 text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onViewContractPDF(record.contract_pdf);
              }}
            >
              <DownloadIcon className="text-white w-4 h-4" />
              <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
                {L.translate("CommonForm.view_signed_contract")}
              </span>
            </Button>
          )}
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<NewCollaboratorBidType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[14px] font-medium">
          {L.translate("Base.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="flex flex-col sm:flex-row items-start justify-between w-full">
              <div className="flex flex-col items-start justify-between w-full">
                <div className="flex flex-row items-center justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.event_name")}:`}
                  </p>
                  <p className="text-primary text-[12px] font-gilroy font-medium">
                    {selData.event_name}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.event_date")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.full_date ? selData.full_date : "-"}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.payment_date")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {selData.payment_date
                      ? makeFormattedDateString(selData.payment_date)
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-start justify-between w-full">
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.comp")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {Number(selData.comp)
                      ? `€ ${makeNumberWithFloatingDigits(selData.comp, 2)}`
                      : "-"}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.reimburse")}:`}
                  </p>
                  <p className="text-primary text-[14px] font-gilroy font-medium">
                    {Number(selData.reimburse)
                      ? `€ ${makeNumberWithFloatingDigits(
                          selData.reimburse,
                          2
                        )}`
                      : "-"}
                  </p>
                </div>
                <div className="flex flex-row items-start justify-center gap-2">
                  <p className="text-secondary text-[14px] font-gilroy font-medium">
                    {`${L.translate("CommonForm.contract_sign_status")}`}
                  </p>
                  <div className="flex flex-row gap-2 items-center">
                    <p
                      className="font-gilroy text-white text-[14px] rounded-[8px] px-2"
                      style={{
                        backgroundColor: getPaidStatusColor(
                          selData.contract_signed
                        ),
                      }}
                    >
                      {L.translate(getPaidStatusName(selData.contract_signed))}
                    </p>
                  </div>
                </div>
                {/*
                  <div className="flex flex-row items-start justify-center gap-2">
                    <p className="text-secondary text-[14px] font-gilroy font-medium">
                      {`${L.translate("CommonForm.status")}:`}
                    </p>
                    <div className="flex flex-row gap-2 items-center">
                      <p
                        className="font-gilroy text-white text-[14px] rounded-[8px] px-2"
                        style={{
                          backgroundColor: getBidStatusColor(selData.status),
                        }}
                      >
                        {L.translate(getBidStatusName(selData.status))}
                      </p>
                    </div>
                  </div>
                  */}
              </div>
            </div>
            {selData.contract_signed == 1 && (
              <div className="flex flex-row items-start justify-center gap-2">
                <Button
                  variant="text"
                  className="text-[14px] font-gilroy px-8 !text-white h-[44px] rounded-[8px] hover:bg-warning-100 bg-warning"
                  onClick={(e) => {
                    e.stopPropagation();
                    onSignContract(selData.event_id!);
                  }}
                >
                  <FileEditIcon className="text-white w-4 h-4" />
                  {L.translate("CommonForm.sign_contract")}
                </Button>
              </div>
            )}
            {selData.contract_signed >= 2 && (
              <div className="flex flex-row items-start justify-center gap-2">
                <Button
                  variant="text"
                  className="text-[14px] font-gilroy px-8 !text-white h-[44px] rounded-[8px] hover:bg-error-100 bg-error"
                  onClick={(e) => {
                    e.stopPropagation();
                    onViewContractPDF(selData.contract_pdf);
                  }}
                >
                  <DownloadIcon className="text-white w-4 h-4" />
                  {L.translate("CommonForm.view_signed_contract")}
                </Button>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full h-full bg-whole-gray">
      <div className="bg-white p-4 rounded-[8px] mb-4">
        <div className="flex flex-row items-center justify-between gap-4">
          <div className="text-primary text-[24px] font-gilroy-semibold">
            {L.translate("Sidebar.current_jobs")}
          </div>
        </div>
        <div className="mt-8 flex flex-col items-center justify-between bg-white">
          <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-4">
            {/* grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full gap-4 */}
            <InputWithoutLabel
              placeholder={String(L.translate("CommonForm.event_name"))}
              className="w-full"
              value={searchParams.event_name}
              showClose={searchParams.event_name!.length > 0}
              clickedClose={() =>
                setSearchParams({ ...searchParams, event_name: "", page: 1 })
              }
              onChange={(e) =>
                setSearchParams({
                  ...searchParams,
                  event_name: e.target.value,
                  page: 1,
                })
              }
            />
            {/*
            <TailwindDatePicker
              placeholder={String(L.translate("CommonForm.event_date"))}
              className="w-full z-30"
              value={searchParams.event_date ?? ""}
              onChange={(strDate?: string) =>
                setSearchParams({ ...searchParams, event_date: strDate ?? "" })
              }
            />
          */}
            <MonthPicker
              placeholder={String(L.translate("CommonForm.filter_by_month"))}
              value={searchParams.event_date ?? ""}
              onChange={(strDate?: string) =>
                setSearchParams({
                  ...searchParams,
                  event_date: strDate ?? "",
                  page: 1,
                })
              }
            />
            {/*
              <InputWithoutLabel
                placeholder={String(L.translate("CommonForm.client_name"))}
                className="w-full"
                showClose={searchParams.client_name!.length > 0}
                clickedClose={() =>
                  setSearchParams({ ...searchParams, client_name: "" })
                }
                value={searchParams.client_name}
                onChange={(e) =>
                  setSearchParams({
                    ...searchParams,
                    client_name: e.target.value,
                  })
                }
              />
            */}
            <DropdownWithoutLabel
              placeholder={String(L.translate("CommonForm.status"))}
              className="w-full"
              data={[
                { id: -1, name: String(L.translate("CommonForm.status")) },
                ...BidStatus.map((item) => ({
                  ...item,
                  name: String(L.translate(item.name)),
                })),
              ]}
              onChange={(e: number) =>
                setSearchParams({ ...searchParams, bid_status: e, page: 1 })
              }
            />
          </div>
          <div className="mt-4 relative w-full">
            <Table
              locale={{
                emptyText: (
                  <Empty
                    description={
                      <p className="font-gilroy-semibold text-secondary text-[14px]">
                        {L.translate("Base.no_data")}
                      </p>
                    }
                  />
                ),
              }}
              showSorterTooltip={false}
              columns={isMobile ? mobileColumns : columns}
              dataSource={dataSource}
              loading={isLoading || isLoadingEvents}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => onClickedEvent(record.event_id),
                };
              }}
              pagination={{
                total,
                current: searchParams.page,
                onChange: onPageChange,
                position: ["bottomCenter"],
                defaultPageSize: LIST_PAGE_SIZE,
                showSizeChanger: false,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollaboratorEventsPage;
