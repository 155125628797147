import { FC, useContext, useEffect, useState } from "react";
import Button from "src/components/ui/button";
import InputWithoutLabel from "src/components/ui/input-without-label";
import PasswordWithoutLabel from "src/components/ui/password-without-label";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import client from "../../api";
import GlobalContext from "src/context/global-context";
import useAuth from "src/hooks/use-auth";
import TemplateDocSelection from "src/components/contract/template-selection";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { HTMLEditorOptions } from "src/lib/constants";
import { SunEditorOptions } from "suneditor/src/options";
import { makeDefaultHTML } from "src/lib/utils";
import L from "i18n-react";
import { Spin } from "antd";

const ContractSettingsPage: FC = () => {
  const { getCurrentUser, setCurrentUser } = useAuth();
  const { setAlertText, setAlertTitle, setIsAlertOpened, language } =
    useContext(GlobalContext);

  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const [standardFile, setStandardFile] = useState<File | null>(null);
  const [vatFile, setVatFile] = useState<File | null>(null);

  const [standardFilename, setStandardFilename] = useState("");
  const [vatFilename, setVatFilename] = useState("");
  const [emailHTML, setEmailHTML] = useState("");

  const { mutate: loadInfo, isLoading } = useMutation(client.contract.get, {
    onSuccess: (data) => {
      console.log(data, data.data.email);
      setStandardFilename(data.data.standard_contract);
      setVatFilename(data.data.vat_number);
      setEmailHTML(data.data.email);
    },
    onError: (error: any) => {
      setAlertTitle("Alert.error");
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
      }
      setIsAlertOpened(true);
    },
  });

  const { mutate: updateTemplate, isLoading: isUpdatingTemplate } = useMutation(
    client.contract.updateTemplates,
    {
      onSuccess: (data) => {
        setStandardFilename(data.data.standard_contract);
        setVatFilename(data.data.vat_number);
        setEmailHTML(data.data.email);
        setStandardFile(null);
        setVatFile(null);

        setAlertTitle("Alert.sucess");
        setAlertText("Success.updated_contract_templates");
        setIsAlertOpened(true);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: updateEmailText, isLoading: isUpdatingText } = useMutation(
    client.contract.updateEmail,
    {
      onSuccess: (data) => {
        setStandardFilename(data.data.standard_contract);
        setVatFilename(data.data.vat_number);
        setEmailHTML(data.data.email);

        setAlertTitle("Alert.sucess");
        setAlertText("Success.updated_contract_email");
        setIsAlertOpened(true);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("GlobalErrors.APISomethingWrong")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    if (!isMounted) return;

    loadInfo();
  }, [isMounted]);

  const onHTMLChange = (content: string) => {
    setEmailHTML(content);
  };

  const submitDocs = () => {
    updateTemplate({
      standard: standardFile,
      standard_extension: standardFile
        ? standardFile?.name.split(".").at(-1) ?? ""
        : "",
      vat: vatFile,
      vat_extension: vatFile ? vatFile?.name.split(".").at(-1) ?? "" : "",
    });
  };

  const submitEmailText = () => {
    updateEmailText({ email: emailHTML });
  };

  return (
    <div className="w-full h-full bg-whole-gray">
      <div className="bg-white p-4 rounded-[8px] mb-4 relative">
        {isLoading && (
          <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
        )}
        <div className="text-primary text-[24px] font-gilroy-semibold">
          {String(L.translate("CommonForm.modify_contracts"))}
        </div>
        <div className="mt-4 flex w-full flex-col md:flex-row md:w-[70%] items-start justify-center gap-4">
          <TemplateDocSelection
            key="standard"
            fileName={standardFilename}
            onFileChange={(file: File | null) => setStandardFile(file)}
            title={String(L.translate("CommonForm.standard_contract"))}
          />
          <TemplateDocSelection
            key="vat"
            fileName={vatFilename}
            onFileChange={(file: File | null) => setVatFile(file)}
            title={String(L.translate("CommonForm.vat_number_contract"))}
          />
        </div>
        <div className="mt-4 flex items-start gap-4">
          <Button
            variant="validate"
            disabled={!standardFile && !vatFile}
            isLoading={isUpdatingTemplate}
            className="rounded-[10px] font-gilroy text-[14px] font-bold leading-[24px] h-[44px] w-full sm:w-auto sm:!px-12"
            onClick={submitDocs}
          >
            {L.translate("Base.submit")}
          </Button>
        </div>
      </div>
      <div className="bg-white p-4 rounded-[8px] mb-4 relative">
        {isLoading && (
          <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
        )}
        <div className="text-primary text-[24px] font-gilroy-semibold">
          {String(L.translate("CommonForm.modify_contract_text"))}
        </div>
        <div className="mt-4 flex w-full flex-col md:flex-row md:w-[70%] items-start justify-center gap-0 md:gap-4">
          <SunEditor
            defaultValue={emailHTML}
            setContents={emailHTML}
            lang={language == "EN" ? "en" : "it"}
            height={"360px"}
            onChange={onHTMLChange}
            setOptions={
              { ...HTMLEditorOptions, value: emailHTML } as SunEditorOptions
            }
          />
        </div>
        <div className="mt-4 flex items-start gap-4">
          <Button
            variant="validate"
            className="rounded-[10px] font-gilroy text-[14px] font-bold leading-[24px] h-[44px] w-full sm:w-auto sm:!px-12"
            isLoading={isUpdatingText}
            disabled={emailHTML.length == 0}
            onClick={submitEmailText}
          >
            {L.translate("Base.submit")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContractSettingsPage;
