import { FC, useContext, useEffect, useState } from "react";
import { PlusSmallIcon } from "src/components/icons/plus-small-icon";
import Button from "src/components/ui/button";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { addPlusIntoPhoneNumber, capitalizeFirstLetter } from "src/lib/utils";
import { LIST_PAGE_SIZE } from "src/lib/constants";
import { Divider, Dropdown, Empty, MenuProps, Space, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { useModalAction } from "src/components/modal-views/context";
import { MenuDotsVerticalIcon } from "src/components/icons/menu-dots-vertical-icon";
import { PencilIcon } from "src/components/icons/pencil-icon";
import { CrossIcon } from "src/components/icons/cross-icon";
import { SearchQueryOptions } from "src/api/types";
import GlobalContext from "src/context/global-context";
import { useMutation } from "react-query";
import client from "../../api";
import { ClientDataType } from "src/lib/data-types";
import { SortIcon } from "src/components/icons/sort-icon";
import L from "i18n-react";
import { makeColumnHeader } from "src/components/table-columns/make-column-header";
import InputWithoutLabel from "src/components/ui/input-without-label";

const ClientsPage: FC = () => {
  const isMounted = useIsMounted();
  const { openModal } = useModalAction();

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchQueryOptions>({
    per_page: LIST_PAGE_SIZE,
    page: 1,
    sort: "name",
    direction: "desc",
    client_name: "",
  });
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<ClientDataType[]>([]);
  const { setAlertText, setAlertTitle, setIsAlertOpened, geoPosition } =
    useContext(GlobalContext);

  useEffect(() => {
    if (!isMounted) return;

    getList(searchParams);
  }, [isMounted, searchParams.page, searchParams.sort, searchParams.direction]);

  useEffect(() => {
    if (!isMounted) return;

    const delayDebounceFn = setTimeout(() => {
      getList(searchParams);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    isMounted,
    searchParams.page,
    searchParams.sort,
    searchParams.direction,
    searchParams.client_name,
  ]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 920 ? true : false);
  }

  const { mutate: getList, isLoading } = useMutation(client.clients.list, {
    onSuccess: (data) => {
      console.log(data);
      setTotal(data.count);
      setDataSource(
        data.results.map((item, index) => ({
          key: index,
          id: item.id,
          name: item.name ?? "",
          full_address: `${item.address}, ${item.city}, ${item.prov}`,
          address: item.address,
          city: item.city,
          prov: item.prov,
          zip: item.zip,
          email: item.email,
          pec_email: item.pec_email,
          telephone_number: item.telephone_number ?? "-",
          vat_number: item.vat_number,
          foreign_number: item.foreign_number,
          recipient_code: item.recipient_code,
          status: item.status,
        }))
      );
    },
    onError: (error: any) => {
      setAlertTitle("Alert.error");
      if (error.code === "ERR_NETWORK") {
        setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
      } else {
        setAlertText(String(L.translate("Errors.failed_load_clients")));
      }
      setIsAlertOpened(true);
    },
  });

  const { mutate: deleteClient, isLoading: isDeletingClient } = useMutation(
    client.clients.delete,
    {
      onSuccess: (data) => {
        setTimeout(() => onRefresh(), 300);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          setAlertText(String(L.translate("Errors.failed_delete_client")));
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onRefresh = () => {
    getList(searchParams);
  };

  const onAddClient = () => {
    openModal("ADD_CLIENT", { refreshCallback: onRefresh });
  };

  const onEdit = (clientId: number) => {
    const client = dataSource.find((item) => item.id == clientId);
    openModal("EDIT_CLIENT", {
      payload: client,
      refreshCallback: onRefresh,
    });
  };

  const onDelete = (clientId: number) => {
    openModal("CONFIRM", { payload: clientId, callback: onConfirmDelete });
  };

  const onConfirmDelete = (clientId: number) => {
    //delete process
    deleteClient(clientId);
  };

  const onClickedClient = (client: ClientDataType) => {
    openModal("EDIT_CLIENT", {
      payload: client,
      refreshCallback: onRefresh,
    });
  };

  const onPageChange = (page: number, pageSize: number) => {
    setSearchParams({ ...searchParams, page });
  };

  const columns: ColumnsType<ClientDataType> = [
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.name")),
          "name",
          searchParams,
          setSearchParams
        ),
      dataIndex: "name",
      className: "w-auto",
      key: "name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () =>
        makeColumnHeader(
          String(L.translate("CommonForm.address")),
          "address",
          searchParams,
          setSearchParams
        ),
      dataIndex: "full_address",
      className: "w-auto",
      key: "full_address",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.zipcode")}
        </p>
      ),
      dataIndex: "zip",
      className: "w-auto",
      key: "zip",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CommonForm.email")}
        </p>
      ),
      dataIndex: "email",
      className: "w-auto",
      key: "email",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {value}
          </p>
        </div>
      ),
    },

    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
          {L.translate("CollaboratorProfile.telephone_number")}
        </p>
      ),
      dataIndex: "telephone_number",
      className: "w-auto",
      key: "telephone_number",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-gilroy text-primary text-[13px] sm:text-[14px]">
            {addPlusIntoPhoneNumber(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => <p className="hidden" />,
      dataIndex: "action",
      className: "w-[50px]",
      render: (_, record: { key: React.Key; id: number }) => {
        return (
          <div className="flex flex-row items-center justify-center gap-2">
            <Button
              variant="icon"
              className="bg-error !text-white hover:!bg-red-400 text-[14px] font-gilroy p-2 rounded-[12px] group relative cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDelete(record.id);
              }}
            >
              <CrossIcon className="text-white w-4 h-4" />
              <span className="whitespace-nowrap absolute top-[-36px] right-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
                {L.translate("Base.delete")}
              </span>
            </Button>
          </div>
        );
      },
    },
  ];

  const mobileColumns: ColumnsType<ClientDataType> = [
    {
      title: () => (
        <p className="font-gilroy-bold text-secondary text-[14px] font-medium">
          {L.translate("Base.info")}
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            <div className="flex flex-row items-start justify-center gap-2">
              <p className="text-secondary text-[14px] font-gilroy font-medium">
                {`${L.translate("CommonForm.name")}:`}
              </p>
              <p className="text-primary text-[14px] font-gilroy font-medium">
                {selData.name}
              </p>
            </div>
            <div className="flex flex-row items-start justify-center gap-2">
              <p className="text-secondary text-[14px] font-gilroy font-medium">
                {`${L.translate("CommonForm.address")}:`}
              </p>
              <p className="text-primary text-[14px] font-gilroy font-medium">
                {selData.full_address}
              </p>
            </div>
            <div className="flex flex-row items-start justify-center gap-2">
              <p className="text-secondary text-[14px] font-gilroy font-medium">
                {`${L.translate("CommonForm.email")}:`}
              </p>
              <p className="text-primary text-[14px] font-gilroy font-medium">
                {selData.email}
              </p>
            </div>
            <div className="flex flex-row items-start justify-center gap-2">
              <p className="text-secondary text-[14px] font-gilroy font-medium">
                {`${L.translate("CommonForm.zipcode")}:`}
              </p>
              <p className="text-primary text-[14px] font-gilroy font-medium">
                {selData.zip}
              </p>
            </div>
            <div className="flex flex-row items-start justify-center gap-2">
              <p className="text-secondary text-[14px] font-gilroy font-medium">
                {`${L.translate("CollaboratorProfile.telephone_number")}:`}
              </p>
              <p className="text-primary text-[14px] font-gilroy font-medium">
                {addPlusIntoPhoneNumber(selData.telephone_number)}
              </p>
            </div>
            <div className="flex flex-row items-start justify-center gap-2">
              <Button
                variant="text"
                className="bg-error text-[14px] font-gilroy px-8 !text-white h-[40px] rounded-[8px] hover:bg-red-400"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(selData.id);
                }}
              >
                {L.translate("Base.delete")}
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  const makeExpandContent = (data: ClientDataType) => {
    return (
      <div className="flex flex-wrap gap-4 pl-12 pr-4">
        <div className="flex flex-row items-start justify-center gap-2">
          <p className="text-secondary text-[14px] font-gilroy font-medium">
            {`${L.translate("CommonForm.pec_email")}:`}
          </p>
          <p className="text-primary text-[14px] font-gilroy font-medium">
            {data.pec_email}
          </p>
        </div>
        <div className="flex flex-row items-start justify-center gap-2">
          <p className="text-secondary text-[14px] font-gilroy font-medium">
            {`${L.translate("CommonForm.recipient_code")}:`}
          </p>
          <p className="text-primary text-[14px] font-gilroy font-medium">
            {data.recipient_code}
          </p>
        </div>
        <div className="flex flex-row items-start justify-center gap-2">
          <p className="text-secondary text-[14px] font-gilroy font-medium">
            {`${L.translate("CommonForm.vat_number")}:`}
          </p>
          <p className="text-primary text-[14px] font-gilroy font-medium">
            {data.vat_number}
          </p>
        </div>
        <div className="flex flex-row items-start justify-center gap-2">
          <p className="text-secondary text-[14px] font-gilroy font-medium">
            {`${L.translate("CommonForm.foreign_number")}:`}
          </p>
          <p className="text-primary text-[14px] font-gilroy font-medium">
            {data.foreign_number.length > 0 ? data.foreign_number : "-"}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-full bg-whole-gray">
      <div className="bg-white p-4 rounded-[8px] mb-4">
        <div className="flex flex-row items-center justify-between gap-4">
          <div className="text-primary text-[24px] font-gilroy-semibold">
            {L.translate("Sidebar.clients")}
          </div>
          <Button
            variant="icon"
            className="bg-active p-2 rounded-[8px] hover:bg-main"
            onClick={onAddClient}
          >
            <PlusSmallIcon className="text-white w-[20px] h-[20px]" />
          </Button>
        </div>
        <div className="w-full mt-4">
          <InputWithoutLabel
            placeholder={String(L.translate("CommonForm.search_clients"))}
            inputClassName="bg-gray-modern-100 rounded-[8px] focus:!ring-0"
            value={searchParams.client_name!}
            showClose={searchParams.client_name!.length > 0}
            clickedClose={() =>
              setSearchParams({ ...searchParams, client_name: "", page: 1 })
            }
            onChange={(e) =>
              setSearchParams({
                ...searchParams,
                client_name: e.target.value,
                page: 1,
              })
            }
          />
        </div>
        <div className={`relative w-full ${!isMobile && "mt-4"}`}>
          <Table
            locale={{
              emptyText: (
                <Empty
                  description={
                    <p className="font-gilroy-semibold text-secondary text-[14px]">
                      {L.translate("Base.no_data")}
                    </p>
                  }
                />
              ),
            }}
            showSorterTooltip={false}
            loading={isLoading || isDeletingClient}
            columns={isMobile ? mobileColumns : columns}
            dataSource={dataSource}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => onClickedClient(record),
              };
            }}
            pagination={{
              total,
              current: searchParams.page,
              onChange: onPageChange,
              position: ["bottomCenter"],
              defaultPageSize: LIST_PAGE_SIZE,
              showSizeChanger: false,
            }}
            /*
            expandable={{
              expandedRowRender: (record) => makeExpandContent(record),
            }}
              */
          />
        </div>
      </div>
    </div>
  );
};

export default ClientsPage;
