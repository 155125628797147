import { FC, useContext, useEffect, useRef, useState } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfDay,
  lastDayOfMonth,
  addDays,
  isSameDay,
  getDay,
  startOfWeek,
  endOfWeek,
  lastDayOfWeek,
  getDaysInMonth,
  startOfMonth,
  getWeeksInMonth,
  subWeeks,
  getWeek,
  addWeeks,
} from "date-fns";
import { it } from "date-fns/locale/it";
import { UserIcon } from "../icons/user-icon";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Event } from "src/api/types";
import moment from "moment";
import "moment/locale/it";
import {
  capitalizeFirstLetter,
  generateRandomString,
  getContractStatusBackgroundColor,
  getContractStatusTextColor,
  getEventDurationString,
} from "src/lib/utils";
import { WeekPicker } from "./week-picker";
import L from "i18n-react";
import GlobalContext from "src/context/global-context";

interface WeekCalendarProps {
  info: { [key: number]: Event[] };
  events: Event[];
  onSelectEvent?: any;
  onSelectStartDate: any;
  onSelectEndDate: any;
}

const WeekCalendar: FC<WeekCalendarProps> = ({
  info,
  events,
  onSelectEvent,
  onSelectStartDate,
  onSelectEndDate,
}) => {
  const isMounted = useIsMounted();
  const { language } = useContext(GlobalContext);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [tooltipWidth, setTooltipWidth] = useState(0);

  const daysRef = useRef<HTMLDivElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
    setTooltipWidth(window.innerWidth - 80);
  }

  const [currentWeek, setCurrentWeek] = useState({
    firstDay: startOfWeek(new Date(), { weekStartsOn: 1 }),
    lastDay: endOfWeek(new Date(), { weekStartsOn: 1 }),
  });

  const onChange = (week: { firstDay: Date; lastDay: Date }) => {
    setCurrentWeek(week);

    onSelectStartDate(format(week.firstDay, "yyyy-MM-dd"));
    onSelectEndDate(format(week.lastDay, "yyyy-MM-dd"));
  };

  const onClickedDate = (item: Event) => {
    onSelectEvent(item);
  };

  const renderDays = () => {
    const days = [];
    let startDate = currentWeek.firstDay;
    for (let i = 0; i < 7; i++) {
      const selectedDay = addDays(startDate, i);
      const divDay = (
        <div
          key={`day_${i}`}
          className="flex flex-row items-end gap-1 min-w-[90px]"
        >
          <div className="text-primary font-gilroy font-semibold text-[18px]">
            {capitalizeFirstLetter(
              language == "EN"
                ? format(selectedDay, "EEE")
                : format(selectedDay, "EEE", { locale: it })
            )}
          </div>
          <div className="text-primary font-gilroy font-semibold text-[12px] mb-[2px]">
            {`${format(selectedDay, "d")}.`}
          </div>
        </div>
      );
      days.push(divDay);
    }
    return (
      <div className="flex flex-row items-center gap-4 h-[44px]">{days}</div>
    );
  };

  const renderEvents = () => {
    const eventsUI = events.map((item) => (
      <div
        key={`event_${item.id}_${generateRandomString()}`}
        className="flex flex-col items-start bg-gray-modern-50 rounded-[8px] gap-1 p-2 sm:gap-2 sm:p-2 max-w-[114px] min-w-[114px]  sm:max-w-[300px] sm:min-w-[300px] group relative cursor-pointer h-[84px] sm:h-[69px]"
      >
        <div className="text-primary font-gilroy font-semibold text-[12px] sm:text-[14px] line-clamp-1">
          {item.name}
        </div>
        <div className="text-secondary font-gilroy text-[11px] sm:text-[12px]">
          {getEventDurationString(
            language,
            item.start_date,
            item.end_date,
            item.dates
          )}
        </div>
        <span className="absolute top-[86px] sm:top-[72px] left-0 z-50 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 font-gilroy text-[16px]">
          {item.name}
        </span>
      </div>
    ));

    return <div className="flex flex-col gap-4">{eventsUI}</div>;
  };

  const renderInfo = () => {
    const eventsUI = events.map((item) => {
      const days = [];

      let startDate = currentWeek.firstDay;
      for (let i = 0; i < 7; i++) {
        const selectedDate = moment(addDays(startDate, i)).format("YYYY-MM-DD");
        let collaboratorsPerDay = item.number_of_collaborators;
        if (item.numbers_per_day) {
          const subInfos = item.numbers_per_day.split(",");
          if (subInfos.length > 0) {
            for (let nIdx = 0; nIdx < subInfos.length; nIdx++) {
              const subItems = subInfos[nIdx].split(":");
              if (subItems.length > 0) {
                if (subItems[0] == selectedDate) {
                  collaboratorsPerDay = Number(subItems[1]);
                  break;
                }
              }
            }
          }
        }

        const bidsPerDay = item.bids_list.filter(
          (bid_date) => bid_date == selectedDate
        ).length;

        const strAttendStatus = `${bidsPerDay}/${collaboratorsPerDay}`;

        const selectedDay = Number(format(addDays(startDate, i), "d")) ?? -1;
        let nStatus = 0;

        if (
          selectedDay > 0 &&
          info[selectedDay] &&
          info[selectedDay].length > 0
        ) {
          const dayEvents = info[selectedDay];
          const nFoundIdx = dayEvents.findIndex(
            (subItem) => subItem.id == item.id
          );

          if (nFoundIdx >= 0) nStatus = 1;
        }

        const bgColor =
          nStatus == 0
            ? "bg-gray-modern-50"
            : getContractStatusBackgroundColor(strAttendStatus);
        const textColor = getContractStatusTextColor(strAttendStatus);

        const divDay = (
          <button
            key={`weekday_${i}_${item.id}`}
            className={`${bgColor} flex flex-row gap-1 min-w-[90px] h-[84px] sm:h-[69px] rounded-[8px] p-2 hover:bg-whole-gray`}
            disabled={nStatus == 0 ? true : false}
            onClick={() => onClickedDate(item)}
          >
            <UserIcon
              id={`clip_${i}_${item.id}`}
              className={`${textColor} mt-[4px] font-gilroy w-3 h-3 ${
                nStatus == 0 && "invisible"
              }`}
            />
            <div
              className={`${textColor} font-gilroy text-[14px] ${
                nStatus == 0 && "invisible"
              }`}
            >
              {strAttendStatus}
            </div>
          </button>
        );
        days.push(divDay);
      }

      return (
        <div key={`events_week_${item.id}`} className="flex flex-row gap-4">
          {days}
        </div>
      );
    });

    return <div className="flex flex-col gap-4">{eventsUI}</div>;
  };

  const onDaysScroll = () => {
    if (daysRef.current && infoRef.current) {
      infoRef.current.scrollLeft = daysRef.current?.scrollLeft;
    }
  };

  const onInfoScroll = () => {
    if (daysRef.current && infoRef.current) {
      daysRef.current.scrollLeft = infoRef.current.scrollLeft;
    }
  };

  return (
    <div>
      <div className="sticky top-[64px] sm:top-[70px] bg-white py-4 z-30 mt-[-16px]">
        <div className="flex flex-col sm:flex-row w-full gap-2 sm:gap-8">
          <div className="text-primary font-gilroy font-semibold text-[16px] text-center w-full sm:w-auto">
            <WeekPicker
              week={currentWeek}
              setWeek={setCurrentWeek}
              onChange={onChange}
            />
          </div>
          <div
            className="flex flex-col overflow-x-auto ml-[122px] sm:ml-0 scrollbar-thin"
            ref={daysRef}
            onScroll={onDaysScroll}
          >
            {renderDays()}
          </div>
        </div>
      </div>
      {events.length > 0 ? (
        <div className="flex flex-row w-full gap-2 sm:gap-8 mb-2 relative">
          {renderEvents()}
          <div
            className="flex flex-col overflow-x-auto scrollbar-hide"
            ref={infoRef}
            onScroll={onInfoScroll}
          >
            {renderInfo()}
          </div>
        </div>
      ) : (
        <div className="flex flex-row w-full gap-2 sm:gap-8 mb-2 relative">
          <label className="mt-12 w-full text-center h-20 font-gilroy text-[20px] text-secondary">
            {L.translate("CommonForm.no_events")}
          </label>
        </div>
      )}
    </div>
  );
};

export default WeekCalendar;
