import { forwardRef } from "react";
import classNames from "classnames";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  required?: boolean;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error,
      type = "text",
      className,
      inputClassName = "bg-gray-modern-100",
      required,
      ...props
    },
    ref
  ) => {
    return (
      <div className={className}>
        <label className="block text-16px font-gilroy font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2.5 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
              {required && (
                <span className="text-[14px] text-error font-gilroy">* </span>
              )}
              {label}
            </span>
          )}
          <input
            type={type}
            ref={ref}
            {...props}
            className={classNames(
              "h-[50px] sm:h-[64px] placeholder-other w-full appearance-none text-[16px] font-gilroy font-medium rounded-[12px] border border-border px-4 py-2 text-dark ring-[0.5px] ring-light-500 focus:ring-[0.5px] dark:border-dark-600 dark:text-light dark:ring-dark-600 lg:px-5",
              error
                ? "focus:border-error !border-error focus:ring-error"
                : "focus:border-active focus:ring-active",
              inputClassName
            )}
          />
        </label>
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[12px] text-error font-gilroy"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";
export default Input;
