export const CalendarPlusIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_30_486)">
        <path
          d="M15.8333 2.07487H15V1.24154C15 0.781536 14.6267 0.408203 14.1667 0.408203C13.7067 0.408203 13.3333 0.781536 13.3333 1.24154V2.07487H6.66667V1.24154C6.66667 0.781536 6.29333 0.408203 5.83333 0.408203C5.37333 0.408203 5 0.781536 5 1.24154V2.07487H4.16667C1.86917 2.07487 0 3.94404 0 6.24154V16.2415C0 18.539 1.86917 20.4082 4.16667 20.4082H15.8333C18.1308 20.4082 20 18.539 20 16.2415V6.24154C20 3.94404 18.1308 2.07487 15.8333 2.07487ZM4.16667 3.74154H15.8333C17.2117 3.74154 18.3333 4.8632 18.3333 6.24154V7.07487H1.66667V6.24154C1.66667 4.8632 2.78833 3.74154 4.16667 3.74154ZM15.8333 18.7415H4.16667C2.78833 18.7415 1.66667 17.6199 1.66667 16.2415V8.74154H18.3333V16.2415C18.3333 17.6199 17.2117 18.7415 15.8333 18.7415ZM13.3333 13.7415C13.3333 14.2015 12.96 14.5749 12.5 14.5749H10.8333V16.2415C10.8333 16.7015 10.46 17.0749 10 17.0749C9.54 17.0749 9.16667 16.7015 9.16667 16.2415V14.5749H7.5C7.04 14.5749 6.66667 14.2015 6.66667 13.7415C6.66667 13.2815 7.04 12.9082 7.5 12.9082H9.16667V11.2415C9.16667 10.7815 9.54 10.4082 10 10.4082C10.46 10.4082 10.8333 10.7815 10.8333 11.2415V12.9082H12.5C12.96 12.9082 13.3333 13.2815 13.3333 13.7415Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_30_486">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.408203)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
