import Datepicker from "react-tailwindcss-datepicker";
import classNames from "classnames";
import { forwardRef, Fragment, useEffect, useState, useRef } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { CheckboxIcon } from "../icons/checkbox-icon";
import { SquareIcon } from "../icons/square-icon";

interface PositionSelectProps {
  label?: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  options: string[];
  value?: string;
  onChange?: any;
  required?: boolean;
  showInfo?: boolean;
  info?: string;
}
const PositionSelect = forwardRef<HTMLDivElement, PositionSelectProps>(
  (
    {
      label,
      error,
      className,
      inputClassName = "bg-gray-modern-100",
      onChange,
      options,
      value,
      required = false,
      showInfo = false,
      info,
      ...props
    },
    ref
  ) => {
    const [otherValue, setOtherValue] = useState("");
    const [selectedOptions, setSelectedOptions] = useState<number[]>([]);

    useEffect(() => {
      if (value) {
        let values: number[] = [];
        value.split(",").forEach((item) => {
          if (!isNaN(Number(item))) {
            values = [...values, Number(item)];
          } else {
            setOtherValue(item);
          }
        });
        setSelectedOptions(values);
      } else {
        setSelectedOptions([]);
      }
    }, [value]);

    useEffect(() => {
      if (!onChange) return;

      const sortedOptions = selectedOptions.sort((a, b) => a - b);
      if (sortedOptions.includes(options.length) && otherValue.length > 0) {
        onChange([...sortedOptions, otherValue].join(","));
      } else {
        onChange(sortedOptions.join(","));
      }
    }, [selectedOptions, otherValue]);

    const onSelectedOption = (index: number) => {
      if (selectedOptions.includes(index)) {
        setSelectedOptions(selectedOptions.filter((item) => item != index));
      } else {
        setSelectedOptions([...selectedOptions, index]);
      }
    };

    return (
      <div className={className}>
        <label className="block text-16px font-gilroy font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
              {required && (
                <span className="text-[14px] text-error font-gilroy">* </span>
              )}
              {label}
            </span>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {options.map((eachOption, index) => (
              <div
                key={`option_${index}`}
                className="flex flex-row items-center justify-start gap-2"
                onClick={() => onSelectedOption(index)}
              >
                {selectedOptions.includes(index) ? (
                  <CheckboxIcon className="w-[16px] h-[16px] text-primary" />
                ) : (
                  <SquareIcon className="w-[16px] h-[16px] text-primary" />
                )}
                <label className="text-[14px] font-gilroy text-primary">
                  {eachOption}
                </label>
              </div>
            ))}
            <div
              key={`option_${options.length}`}
              className="flex flex-row items-center justify-start gap-2"
            >
              {selectedOptions.includes(options.length) ? (
                <CheckboxIcon
                  className="w-[16px] h-[16px] text-primary"
                  onClick={() => onSelectedOption(options.length)}
                />
              ) : (
                <SquareIcon
                  className="w-[16px] h-[16px] text-primary"
                  onClick={() => onSelectedOption(options.length)}
                />
              )}
              <input
                value={otherValue}
                onChange={(e) => {
                  if (e.target.value.length == 0) {
                    setSelectedOptions(
                      selectedOptions.filter((item) => item != options.length)
                    );
                  } else {
                    if (!selectedOptions.includes(options.length)) {
                      setSelectedOptions([...selectedOptions, options.length]);
                    }
                  }
                  setOtherValue(e.target.value);
                }}
                placeholder={"Other"}
                className={classNames(
                  "h-[40px] placeholder-gray-modern-400 border-none w-full appearance-none text-[14px] font-gilroy font-medium rounded-[8px] px-4 py-2 text-primary ring-0 ring-bg-gray-modern-100 focus:ring-[0.5px] lg:px-5",
                  inputClassName
                )}
              />
            </div>
          </div>
        </label>
        {showInfo && (
          <span
            role="info"
            className="block pt-2 text-[12px] text-secondary font-gilroy font-medium"
          >
            {info}
          </span>
        )}
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[12px] text-error font-gilroy"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

PositionSelect.displayName = "PositionSelect";
export default PositionSelect;
