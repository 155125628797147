import { SearchQueryOptions } from "src/api/types";
import Button from "../ui/button";
import { SortIcon } from "../icons/sort-icon";

export const makeColumnHeader = (
  name: string,
  property: string,
  searchParams: SearchQueryOptions,
  setSearchParams: any
) => {
  const onSortChange = () => {
    let updatedSearchParams = { ...searchParams };
    updatedSearchParams.sort = property;
    if (property === searchParams.sort) {
      updatedSearchParams.direction =
        searchParams.direction === "asc" ? "desc" : "asc";
    } else {
      updatedSearchParams.direction = "asc";
    }

    setSearchParams(updatedSearchParams);
  };

  return (
    <Button
      className="flex flex-row w-full items-center justify-between bg-transparent !px-0"
      onClick={onSortChange}
    >
      <p className="font-gilroy-bold text-secondary text-[13px] sm:text-[14px]">
        {name}
      </p>
      <div className="flex flex-col items-center justify-between">
        <SortIcon
          className={`${
            searchParams.sort === property
              ? searchParams.direction === "asc"
                ? "block"
                : "invisible"
              : "block"
          } w-[8px] h-[8px] text-secondary rotate-180`}
        />
        <SortIcon
          className={`${
            searchParams.sort === property
              ? searchParams.direction === "desc"
                ? "block"
                : "invisible"
              : "block"
          } w-[8px] h-[8px] text-secondary`}
        />
      </div>
    </Button>
  );
};
