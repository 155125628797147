import { ConfigValue } from "src/config";

export const LIST_PAGE_SIZE: number = 10;
export const LIST_PAGE_SIZE_50: number = 50;
export const MAX_CHARACTERS_LENGTH: number = 255;
export const LONG_CHARACTERS_LENGTH: number = 8000;

export const HTMLEditorOptions = {
  mode: "classic",
  rtl: false,
  katex: "window.katex",
  font: ["Arial", "tahoma", "Courier New,Courier"],
  fontSize: [8, 10, 12, 14, 16, 18, 20, 24, 28, 30, 32, 36],
  formats: ["p", "blockquote", "h1", "h2", "h3"],
  colorList: [
    "#000000",
    "#ff0000",
    "#ff5e00",
    "#ffe400",
    "#abf200",
    "#00d8ff",
    "#0055ff",
    "#6600ff",
    "#ff00dd",
  ],
  videoFileInput: false,
  tabDisable: false,
  lineHeights: [
    {
      text: "Single",
      value: 1,
    },
    {
      text: "Double",
      value: 2,
    },
  ],
  paragraphStyles: [
    "spaced",
    {
      name: "Box",
      class: "__se__customClass",
    },
  ],
  textStyles: [
    "translucent",
    {
      name: "Emphasis",
      style: "-webkit-text-emphasis: filled;",
      tag: "span",
    },
  ],
  buttonList: [
    [
      "undo",
      "redo",
      "font",
      "fontSize",
      "formatBlock",
      "paragraphStyle",
      "blockquote",
      "bold",
      "underline",
      "italic",
      "strike",
      "subscript",
      "superscript",
      "fontColor",
      "hiliteColor",
      "textStyle",
      "removeFormat",
      "outdent",
      "indent",
      "align",
      "horizontalRule",
      "list",
      "lineHeight",
      "table",
      "showBlocks",
      "codeView",
    ],
  ],
};

export const BidStatusForAdmin = [
  { id: 0, name: "Base.applied", color: "#202939" },
  { id: 1, name: "Base.ready", color: "#12B669" },
  { id: 4, name: "Base.reverse", color: "#F79009" },
  { id: 5, name: "Base.discard", color: "#e93af7" },
];

export const BidStatus = [
  { id: 0, name: "Base.applied", color: "#202939" },
  { id: 1, name: "Base.ready", color: "#12B669" },
  { id: 2, name: "Base.shortlisted", color: "#2970FE" },
  { id: 3, name: "Base.declined", color: "#F04437" },
  { id: 4, name: "Base.reverse", color: "#F79009" },
  { id: 5, name: "Base.discard", color: "#e93af7" },
];

export const EventStatus = [
  { id: 0, name: "Base.created", color: "#202939" },
  { id: 1, name: "Base.planned", color: "#2970FE" },
  {
    id: 2,
    name: "Base.waiting_for_approval",
    color: "#F04437",
  },
  { id: 3, name: "Base.ready", color: "#12B669" },
  { id: 4, name: "Base.archive", color: "#8d257c" },
  { id: 10, name: "Base.deleted", color: "#000000" },
];

export const PaidStatus = [
  { id: 0, name: "Base.pending", color: "#202939" },
  { id: 1, name: "Base.sent", color: "#F04437" },
  { id: 2, name: "Base.signed", color: "#e93af7" },
  { id: 3, name: "Base.paid", color: "#12B669" },
];

export const PaymentStatus = [
  { id: 0, name: "Base.pending", color: "#202939" },
  { id: 1, name: "Base.sent", color: "#F04437" },
  { id: 2, name: "Base.signed", color: "#e93af7" },
  { id: 3, name: "Base.paid", color: "#12B669" },
];

export const InvoiceStatus = [
  { id: 0, name: "Base.unpaid", color: "#F04437" },
  { id: 1, name: "Base.paid", color: "#12B669" },
  { id: 2, name: "Base.overdue", color: "#F79009" },
  { id: 3, name: "Base.archive", color: "#8d257c" },
];

export const WebCastingStatus = [
  { id: 0, name: "Base.pending", color: "#202939" },
  { id: 1, name: "Base.approved", color: "#12B669" },
  { id: 2, name: "Base.archive", color: "#8d257c" },
];

export const ShoeSizes = [
  { id: 1, name: "34" },
  { id: 2, name: "36" },
  { id: 3, name: "38" },
  { id: 4, name: "40" },
  { id: 5, name: "42" },
  { id: 6, name: "44" },
  { id: 7, name: "46" },
  { id: 8, name: "48" },
  { id: 9, name: "50" },
  { id: 10, name: "52" },
];

export const Sexs = [
  { id: 0, name: "Male" },
  { id: 1, name: "Female" },
];

export const Sexs_IT = [
  { id: 0, name: "Maschio" },
  { id: 1, name: "Femmina" },
];

export const HairColors = [
  { id: 1, name: "Brown" },
  { id: 2, name: "Blonde" },
  { id: 3, name: "Blacks" },
  { id: 4, name: "Rossi" },
  { id: 5, name: "Gray" },
];

export const HairColors_IT = [
  { id: 1, name: "Castani" },
  { id: 2, name: "Biondo" },
  { id: 3, name: "Neri" },
  { id: 4, name: "Rossi" },
  { id: 4, name: "Brizzolati" },
];

export const EyeColors = [
  { id: 1, name: "Brown" },
  { id: 2, name: "Green" },
  { id: 3, name: "Blue" },
  { id: 4, name: "Black" },
  { id: 5, name: "Gray" },
];

export const EyeColors_IT = [
  { id: 1, name: "Castani" },
  { id: 2, name: "Verdi" },
  { id: 3, name: "Azzurri" },
  { id: 4, name: "Neri" },
  { id: 4, name: "Grigi" },
];

export const Langauges = [
  { id: 1, name: "Italian" },
  { id: 2, name: "English" },
  { id: 3, name: "French" },
  { id: 4, name: "German" },
  { id: 5, name: "Spanish" },
];

export const Langauges_IT = [
  { id: 1, name: "Italiano" },
  { id: 2, name: "Inglese" },
  { id: 3, name: "Francese" },
  { id: 4, name: "Tedesco" },
  { id: 5, name: "Spagnolo" },
];

export const LanguageLevels = [
  { id: 0, name: "Native" },
  { id: 1, name: "C2 - Fluent" },
  { id: 2, name: "C1 - Advanced" },
  { id: 3, name: "B2 - Excellent" },
  { id: 4, name: "B1 - Intermediate" },
  { id: 5, name: "A2 - Elementary" },
  { id: 6, name: "A1 - Beginner" },
];

export const LanguageLevels_IT = [
  { id: 0, name: "Madrelingua" },
  { id: 1, name: "C2 - Fluente" },
  { id: 2, name: "C1 - Avanzato" },
  { id: 3, name: "B2 - Ottimo" },
  { id: 4, name: "B1 - Intermedio" },
  { id: 5, name: "A2 - Elementare" },
  { id: 6, name: "A1 - Principiante" },
];

export const ShortLanguageLevels = [
  "Native",
  "C2",
  "C1",
  "B2",
  "B1",
  "A2",
  "A1",
];
export const ShortLanguageLevels_IT = [
  "Madrelingua",
  "C2",
  "C1",
  "B2",
  "B1",
  "A2",
  "A1",
];

export const AccountNames = [
  { id: 1, name: "Luana" },
  { id: 2, name: "Claudio" },
  { id: 3, name: "Marta" },
  { id: 4, name: "Olga" },
  { id: 5, name: "Melissa" },
];

export const Permissions = [
  "Clients",
  "Collaborators",
  "Events",
  "Job Ads",
  "Plan job",
  "Calendar",
  "Casting",
  "Payments",
  "Invoices",
  "Report",
  "Profile",
];

export const Permissions_IT = [
  "Clienti",
  "Collaboratori",
  "Eventi",
  "Annunci",
  "Pianifica",
  "Calendario",
  "Casting",
  "Pagamenti",
  "Fatture",
  "Rapporto",
  "Profilo",
];

export const Positions = [
  "Fitter",
  "Animator",
  "Dancer",
  "Barman",
  "Coordinatore",
  "Driver",
  "Photographer - Videographer",
  "Hostess",
  "Steward",
  "ECM/Congress hostesses",
  "Interpreter",
  "Model(s)",
  "Runner/Porter",
  "Security",
  "Organizational Secretary",
  "SpeakerPresenter",
  "Umbrella Girl",
  "Promoter",
];

export const Positions_IT = [
  "Allestitore",
  "Animatore",
  "Ballerino",
  "Barman",
  "Coordinatore",
  "Autista",
  "Fotografo - Videomaker",
  "Hostess",
  "Steward",
  "Hostess per ECM/Congressi",
  "Interprete",
  "Modello(i)",
  "Runner/Facchino",
  "Addetto alla sicurezza",
  "Segretaria organizzativa",
  "Relatore/Presentatore",
  "Ombrellina",
  "Promoter",
];

export const Provinces = [
  "Agrigento",
  "Alessandria",
  "Ancona",
  "Aosta",
  "Arezzo",
  "Ascoli Piceno",
  "Asti",
  "Avellino",
  "Bari",
  "Barletta-Andria-Trani",
  "Belluno",
  "Benevento",
  "Bergamo",
  "Biella",
  "Bologna",
  "Bolzano",
  "Brescia",
  "Brindisi",
  "Cagliari",
  "Caltanissetta",
  "Campobasso",
  "Carbonia-Iglesias",
  "Caserta",
  "Catania",
  "Catanzaro",
  "Chieti",
  "Como",
  "Cosenza",
  "Cremona",
  "Crotone",
  "Cuneo",
  "Enna",
  "Fermo",
  "Ferrara",
  "Firenze",
  "Foggia",
  "Forlì-Cesena",
  "Frosinone",
  "Genova",
  "Gorizia",
  "Grosseto",
  "Imperia",
  "Isernia",
  "L'Aquila",
  "La Spezia",
  "Latina",
  "Lecce",
  "Lecco",
  "Livorno",
  "Lodi",
  "Lucca",
  "Macerata",
  "Mantova",
  "Massa-Carrara",
  "Matera",
  "Medio Campidano",
  "Messina",
  "Milano",
  "Modena",
  "Napoli",
  "Novara",
  "Nuoro",
  "Olbia-Tempio",
  "Oristano",
  "Padova",
  "Palermo",
  "Parma",
  "Pavia",
  "Perugia",
  "Pesaro e Urbino",
  "Pescara",
  "Piacenza",
  "Pisa",
  "Pistoia",
  "Pordenone",
  "Potenza",
  "Prato",
  "Ragusa",
  "Ravenna",
  "Reggio Calabria",
  "Reggio Emilia",
  "Rieti",
  "Rimini",
  "Roma",
  "Rovigo",
  "Salerno",
  "Sassari",
  "Savona",
  "Siena",
  "Siracusa",
  "Sondrio",
  "Taranto",
  "Teramo",
  "Terni",
  "Torino",
  "Trapani",
  "Trento",
  "Treviso",
  "Trieste",
  "Udine",
  "Varese",
  "Venezia",
  "Verbano-Cusio-Ossola",
  "Vercelli",
  "Verona",
  "Vibo Valentia",
  "Vicenza",
  "Viterbo",
];

export const EmailFooter = `<img src='https://gestionale.withyouagency.it/email_signature_final.png' alt='email signature' width='482pt'/>`;
