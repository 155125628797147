import { forwardRef, useEffect, useState } from "react";
import classNames from "classnames";
import { CheckboxIcon } from "../icons/checkbox-icon";
import { SquareIcon } from "../icons/square-icon";
import { arraysAreEqual } from "src/lib/utils";

type CheckboxOptionsProps = {
  label?: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  value?: number;
  onChange?: any;
  required?: boolean;
  showInfo?: boolean;
  info?: string;
  options: string[];
};

const CheckboxOptions = forwardRef<HTMLDivElement, CheckboxOptionsProps>(
  (
    {
      label,
      error,
      className,
      inputClassName = "bg-gray-modern-100",
      required,
      showInfo = false,
      info,
      options,
      value,
      onChange,
      ...props
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState<number | undefined>(
      undefined
    );

    useEffect(() => {
      setSelectedValue(value);
    }, [value]);

    const onSelectedOption = (index: number) => {
      setSelectedValue(index);
      onChange(index);
    };

    return (
      <div className={className}>
        <label className="block text-16px font-gilroy font-medium text-secondary">
          {label && (
            <span className="block cursor-pointer pb-2 text-[14px] font-gilroy font-medium text-secondary dark:text-light/70">
              {required && (
                <span className="text-[14px] text-error font-gilroy">* </span>
              )}
              {label}
            </span>
          )}

          <div className="flex flex-row items-start gap-4">
            {options.map((eachOption, index) => (
              <div
                key={`option_${index}`}
                className="flex flex-row items-center justify-start gap-2"
                onClick={() => onSelectedOption(index)}
              >
                {selectedValue == index ? (
                  <CheckboxIcon className="w-[16px] h-[16px] text-primary" />
                ) : (
                  <SquareIcon className="w-[16px] h-[16px] text-primary" />
                )}
                <label className="text-[14px] font-gilroy text-primary">
                  {eachOption}
                </label>
              </div>
            ))}
          </div>
        </label>
        {showInfo && (
          <span
            role="info"
            className="block pt-2 text-[12px] text-secondary font-gilroy font-medium"
          >
            {info}
          </span>
        )}
        {error && (
          <span
            role="alert"
            className="block pt-2 text-[12px] text-error font-gilroy"
          >
            {`* ${error}`}
          </span>
        )}
      </div>
    );
  }
);

CheckboxOptions.displayName = "CheckboxOptions";
export default CheckboxOptions;
