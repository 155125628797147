import { FC, useContext, useEffect, useState, useRef } from "react";
import CheckboxOptions from "src/components/ui/checkbox-options";
import DropdownWithoutLabel from "src/components/ui/dropdown-without-label";
import Input from "src/components/ui/input";
import InputWithoutLabel from "src/components/ui/input-without-label";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ReactPhone } from "src/components/ui/phone-input";
import classNames from "classnames";
import TailwindDatePicker from "src/components/ui/date-picker";
import AddressInput from "src/components/ui/address-input";
import {
  HairColors,
  HairColors_IT,
  LanguageLevels,
  LanguageLevels_IT,
  Positions,
  Positions_IT,
  Provinces,
  ShoeSizes,
} from "src/lib/constants";
import SelectInput from "src/components/ui/select-input";
import PositionSelect from "src/components/ui/position-select";
import Button from "src/components/ui/button";
import { CheckboxIcon } from "src/components/icons/checkbox-icon";
import { SquareIcon } from "src/components/icons/square-icon";
import routes from "src/config/routes";
import { shakeElement } from "src/lib/shake-element";
import { useMutation } from "react-query";
import client from "../../api";
import { Event } from "src/api/types";
import { capitalizeFirstLetter, formatFileSize } from "src/lib/utils";
import L from "i18n-react";
import { Spin } from "antd";
import GlobalContext from "src/context/global-context";
import { SpinnerIcon } from "src/components/icons/spinner-icon";
import { useNavigate, useSearchParams } from "react-router-dom";
import EventDetailsView from "src/components/events/details";
import Textarea from "src/components/ui/textarea";
import useAuth from "src/hooks/use-auth";
import InputNoSpaceWithoutLabel from "src/components/ui/input-nospace-without-label";
import { ExclamationIcon } from "src/components/icons/exclamation-icon";
import { useModalAction } from "src/components/modal-views/context";
import { Modal as AntModal } from "antd";
import { CrossIcon } from "src/components/icons/cross-icon";
import { CheckIcon } from "src/components/icons/check-icon";
import moment from "moment";

const DatabasePage: FC = () => {
  const isMounted = useIsMounted();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const phoneRef = useRef<HTMLDivElement>(null);
  const shakeRef = useRef<HTMLDivElement>(null);
  const { setAlertText, setAlertTitle, setIsAlertOpened, language } =
    useContext(GlobalContext);
  const navigate = useNavigate();
  const { getCurrentUser, setCurrentUser } = useAuth();
  const [queryParams] = useSearchParams();

  const { openModal } = useModalAction();

  const [isConfirmModal, setConfirmModal] = useState(false);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  const [searchKeyword, setSearchKeyword] = useState("");

  const [events, setEvents] = useState<Event[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
  const [isUseNew, setUseNew] = useState(false);
  //const [notes, setNotes] = useState("");

  const [phoneDivWidth, setPhoneDivWidth] = useState(0);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [second_name, setSecondName] = useState("");
  const [french, setFrench] = useState<number | undefined>(undefined);
  const [german, setGerman] = useState<number | undefined>(undefined);
  const [spanish, setSpanish] = useState<number | undefined>(undefined);
  const [otherLang, setOtherLang] = useState("");
  const [provinces, setProvinces] = useState("");

  const cvRef = useRef<HTMLInputElement>(null);
  const [cvFile, setCVFile] = useState<File | null>(null);

  const mainPhotoRef = useRef<HTMLInputElement>(null);
  const [mainPhotoFile, setMainPhotoFile] = useState<File | null>(null);

  const photoRef2 = useRef<HTMLInputElement>(null);
  const [photoFile2, setPhotoFile2] = useState<File | null>(null);
  const photoRef3 = useRef<HTMLInputElement>(null);
  const [photoFile3, setPhotoFile3] = useState<File | null>(null);
  const photoRef4 = useRef<HTMLInputElement>(null);
  const [photoFile4, setPhotoFile4] = useState<File | null>(null);
  const photoRef5 = useRef<HTMLInputElement>(null);
  const [photoFile5, setPhotoFile5] = useState<File | null>(null);

  const schema = yup
    .object({
      event_id: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      first_name: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      last_name: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      sex: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      live_aboard: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      email: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required")))
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          String(L.translate("ValidationErrors.invalid_email"))
        ),
      confirm_email: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required")))
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          String(L.translate("ValidationErrors.invalid_email"))
        )
        .oneOf(
          [yup.ref("email")],
          String(L.translate("ValidationErrors.mails_not_match"))
        ),
      instagram: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      foreign_name: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      tax_residence_outside_italiy: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      tax_id_code: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      telephone_number: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      born_aboard: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      birth_place: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      birth_county: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      date_of_birth: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      home_address: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      residence_domicile: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      residence_address: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      have_vat_number: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      vat_number: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      IBAN: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      current_account_holder: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      height: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      italian_size: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      shoe_size: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      hair_color: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      have_driving_license: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      have_car: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      have_HACCP_certificate: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      wear_eyeglasses: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      italian: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      english: yup
        .number()
        .required(String(L.translate("ValidationErrors.field_required"))),
      positions: yup
        .string()
        .required(String(L.translate("ValidationErrors.field_required"))),
      cv: yup
        .boolean()
        .required(String(L.translate("ValidationErrors.field_required"))),
      main_photo: yup
        .boolean()
        .required(String(L.translate("ValidationErrors.field_required"))),
      photo2: yup
        .boolean()
        .required(String(L.translate("ValidationErrors.field_required"))),
      photo3: yup
        .boolean()
        .required(String(L.translate("ValidationErrors.field_required"))),
      photo4: yup
        .boolean()
        .required(String(L.translate("ValidationErrors.field_required"))),
      photo5: yup
        .boolean()
        .required(String(L.translate("ValidationErrors.field_required"))),
    })
    .required();
  type FormData = yup.InferType<typeof schema>;
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    watch,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      event_id: undefined,
      first_name: getCurrentUser().first_name ?? "",
      last_name: getCurrentUser().last_name ?? "",
      email: getCurrentUser().email ?? "",
      confirm_email: getCurrentUser().email ?? "",
      foreign_name: "none",
      vat_number: "none",
      home_address: "none",
      residence_address: "none",
    },
  });

  const live_aboard = watch("live_aboard");
  const home_address = watch("home_address");
  const residence_domicile = watch("residence_domicile");
  const have_vat_number = watch("have_vat_number");
  const vat_number = watch("vat_number");
  const foreign_name = watch("foreign_name");
  const residence_address = watch("residence_address");
  const eventID = watch("event_id");

  const { mutate: getEvents, isLoading: isLoadingEvents } = useMutation(
    client.events.all,
    {
      onSuccess: (data) => {
        const tempResult = data.results.filter((item) => {
          if (item.end_date) {
            if (moment(item.end_date).isBefore(moment())) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        });
        const sortedDates = tempResult.sort((a, b) =>
          (a?.start_date ?? "").localeCompare(b?.start_date ?? "")
        );
        setEvents(sortedDates);

        setTimeout(() => {
          queryParams.forEach((value, key) => {
            if (key == "id" && Number(value)) {
              const foundEvents = data.results.filter(
                (item) => item.id == Number(value)
              );

              if (foundEvents.length > 0) {
                setValue("event_id", Number(value), { shouldValidate: true });
                setUseNew(true);
              } else {
                setAlertTitle("Alert.error");
                setAlertText("Errors.already_applied");
                setIsAlertOpened(true);

                setValue("event_id", -1, { shouldValidate: true });
                setUseNew(false);
              }
            }
          });
        }, 600);
      },
    }
  );

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  useEffect(() => {
    if (!isMounted) return;

    getEvents("available");
  }, [isMounted]);

  useEffect(() => {
    const width = phoneRef.current ? phoneRef.current.offsetWidth : 0;
    setPhoneDivWidth(width);
  }, [phoneRef.current]);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  useEffect(() => {
    let tempResult = [...events];

    setValue("event_id", -1, { shouldValidate: true });
    setUseNew(false);

    if (searchKeyword.trim().length > 0) {
      tempResult = tempResult.filter((item) =>
        (item.name ?? "")
          .toLowerCase()
          .includes(searchKeyword.trim().toLowerCase())
      );
    }

    setFilteredEvents(tempResult);
  }, [events, searchKeyword]);

  const { mutate: submitBidWithProfile, isLoading: isLoadingBidWithProfile } =
    useMutation(client.collaborators.bidWithProfile, {
      onSuccess: (data) => {
        setAlertTitle("Alert.sucess");
        setAlertText("Success.proposal_submitted");
        setIsAlertOpened(true);

        setSearchKeyword("");
        setValue("event_id", -1, { shouldValidate: true });
        setUseNew(false);

        setTimeout(() => getEvents("available"), 300);
        //navigate(routes.events);
      },
      onError: (error: any) => {
        setAlertTitle("Alert.error");
        if (error.code === "ERR_NETWORK") {
          setAlertText(String(L.translate("GlobalErrors.NetworkDisconnect")));
        } else {
          if (error.response) {
            if (
              error.response.data &&
              error.response.data.message === "profile_not_found"
            ) {
              setAlertText(String(L.translate("Errors.fill_info")));
            } else {
              setAlertText(
                String(L.translate("Errors.failed_submit_proposal"))
              );
            }
          } else {
            setAlertText(String(L.translate("Errors.failed_submit_proposal")));
          }
        }
        setIsAlertOpened(true);
      },
    });

  const onPickedDates = (dates: string[]) => {
    setTimeout(() => {
      setConfirmModal(true);
      setSelectedDates(dates);
    }, 300);
  };

  const onProcess = () => {
    setConfirmModal(false);
    setTimeout(() => {
      submitBidWithProfile({ event_id: eventID, dates: selectedDates });
    }, 300);
  };

  const onBidWithProfile = () => {
    openModal("COLLAB_PICK_DATES", {
      payload: {
        event: filteredEvents.filter((item) => item.id == eventID)[0],
        fromDatabase: true,
      },
      callback: onPickedDates,
    });
  };

  return (
    <div className="w-full h-full bg-whole-gray relative">
      {isLoadingEvents && (
        <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
      )}

      {isLoadingBidWithProfile && (
        <div className="fixed inset-0 z-50 backdrop-blur-[20px] w-[100vw] h-[100vh] flex items-center justify-center gap-4">
          <SpinnerIcon className="h-auto w-8 animate-spin text-active" />
          <span className="font-gilroy text-[20px] text-active">
            {L.translate("Base.sending_proposal")}
          </span>
        </div>
      )}

      <div className="bg-white rounded-[8px] mb-4">
        <div className="flex flex-col gap-2 w-full items-start sticky top-[64px] md:top-[70px] rounded-tl-[8px] bg-white p-4">
          <div className="text-primary text-[24px] font-gilroy-semibold">
            {L.translate("CommonForm.apply_for_jobs")}
          </div>
          <div className="w-full md:w-[70%] 2xl:w-[50%] bg-gray-modern-50">
            <InputWithoutLabel
              placeholder={String(L.translate("CommonForm.search_for_jobs"))}
              inputClassName="bg-gray-modern-100"
              value={searchKeyword}
              showClose={searchKeyword.length > 0}
              clickedClose={() => setSearchKeyword("")}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
        </div>
        <div className="p-4 flex flex-col items-start justify-between gap-8 mb-2">
          <div className="flex flex-col w-full md:w-[70%] 2xl:w-[50%] gap-4">
            {events.length == 0 && (
              <div className="flex items-center justify-start gap-4">
                <ExclamationIcon className="h-5 w-5 text-primary" />
                <span className="font-gilroy text-[16px] text-primary">
                  {L.translate("CommonForm.no_available_events")}
                </span>
              </div>
            )}
            {filteredEvents.map((item, index) => (
              <>
                <div
                  key={`event-${index}`}
                  onClick={() => {
                    setValue("event_id", item.id, { shouldValidate: true });
                    setUseNew(true);
                  }}
                  className={`py-3 px-4 w-full shadow-card rounded-[8px] cursor-pointer ${
                    eventID == item.id
                      ? "bg-active text-white"
                      : "bg-white text-primary"
                  }`}
                >
                  <p className="text-left text-[14px] font-gilroy">
                    {item.name}
                  </p>
                </div>
                {eventID == item.id && isUseNew && (
                  <div className="w-full mb-4" key={`event-database-${index}`}>
                    <label className="text-primary text-[16px] font-gilroy-semibold">
                      {L.translate("CommonForm.event_details")}
                    </label>
                    <EventDetailsView
                      key={`event_details_${item.id}`}
                      event={
                        filteredEvents.filter((item) => item.id == eventID)[0]
                      }
                    />
                    <div className="w-full flex-col md:w-[70%] flex md:flex-row items-start gap-2 md:gap-8 mt-4">
                      <Button
                        variant="text"
                        className="h-[44px] bg-success hover:bg-green-300 text-white rounded-[8px] w-full"
                        onClick={onBidWithProfile}
                      >
                        {L.translate("CommonForm.send_profile")}
                      </Button>
                    </div>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>

      <AntModal
        open={isConfirmModal}
        centered
        closable={false}
        width={"auto"}
        footer={null}
        onCancel={() => setConfirmModal(false)}
      >
        <div
          className={`${
            isMobile ? "rounded-t-[22px] overflow-y-auto" : "rounded-[12px]"
          } relative w-full max-h-[90%] md:h-full md:w-[320px] px-4 py-10 bg-white gap-4`}
        >
          <button
            aria-label="Close panel"
            onClick={() => setConfirmModal(false)}
            className="absolute top-5 text-primary right-5"
          >
            <CrossIcon className="h-4 w-4 text-primary" />
          </button>

          <div className="h-full w-full flex flex-col items-center justify-center gap-4">
            <div className="w-[54px] h-[54px] rounded-full bg-active flex items-center justify-center">
              <CheckIcon className="w-[20px] h-[20px] text-white" />
            </div>
            <p className="font-gilroy-semibold text-[24px] font-medium text-primary text-center">
              {L.translate("CommonForm.available_event_prompt")}
            </p>
            <div className="flex flex-row items-center justify-between gap-4">
              <Button
                variant="outline"
                className="!border-active !text-active px-8 text-[16px] font-gilroy leading-[24px] h-[44px] hover:border-primary  !rounded-[8px]"
                onClick={() => setConfirmModal(false)}
              >
                {L.translate("Base.no")}
              </Button>
              <Button
                variant="solid"
                className="w-full rounded-[8px] font-gilroy px-8 text-[16px] font-bold leading-[24px] h-[44px] bg-active text-white hover:bg-main focus:ring-offset-0 focus:ring-0 focus:ring-transparent"
                onClick={onProcess}
              >
                {L.translate("Base.yes")}
              </Button>
            </div>
          </div>
        </div>
      </AntModal>
    </div>
  );
};

export default DatabasePage;
