export const MarkerIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_648)">
        <path
          d="M10 5C9.34075 5 8.69629 5.1955 8.14812 5.56177C7.59996 5.92804 7.17272 6.44863 6.92042 7.05772C6.66813 7.66681 6.60212 8.33703 6.73074 8.98363C6.85935 9.63024 7.17682 10.2242 7.643 10.6904C8.10917 11.1565 8.70312 11.474 9.34972 11.6026C9.99633 11.7312 10.6665 11.6652 11.2756 11.4129C11.8847 11.1606 12.4053 10.7334 12.7716 10.1852C13.1379 9.63707 13.3334 8.9926 13.3334 8.33333C13.3334 7.44928 12.9822 6.60143 12.357 5.97631C11.7319 5.35119 10.8841 5 10 5ZM10 10C9.67039 10 9.34815 9.90225 9.07407 9.71912C8.79999 9.53598 8.58637 9.27568 8.46022 8.97114C8.33408 8.6666 8.30107 8.33149 8.36538 8.00818C8.42969 7.68488 8.58842 7.38791 8.82151 7.15482C9.0546 6.92174 9.35157 6.763 9.67487 6.69869C9.99817 6.63438 10.3333 6.66739 10.6378 6.79353C10.9424 6.91968 11.2027 7.1333 11.3858 7.40738C11.5689 7.68147 11.6667 8.0037 11.6667 8.33333C11.6667 8.77536 11.4911 9.19928 11.1785 9.51184C10.866 9.82441 10.4421 10 10 10Z"
          fill="currentColor"
        />
        <path
          d="M10 20C9.2983 20.0036 8.60594 19.839 7.9809 19.52C7.35587 19.2011 6.81635 18.737 6.40752 18.1667C3.23168 13.7858 1.62085 10.4925 1.62085 8.3775C1.62085 6.15521 2.50365 4.02394 4.07505 2.45254C5.64645 0.881139 7.77772 -0.00166321 10 -0.00166321C12.2223 -0.00166321 14.3536 0.881139 15.925 2.45254C17.4964 4.02394 18.3792 6.15521 18.3792 8.3775C18.3792 10.4925 16.7684 13.7858 13.5925 18.1667C13.1837 18.737 12.6442 19.2011 12.0191 19.52C11.3941 19.839 10.7017 20.0036 10 20ZM10 1.8175C8.26036 1.81949 6.59253 2.51144 5.36241 3.74156C4.13229 4.97168 3.44033 6.63952 3.43835 8.37917C3.43835 10.0542 5.01585 13.1517 7.87918 17.1008C8.12226 17.4357 8.44116 17.7082 8.80978 17.8961C9.1784 18.084 9.58627 18.1819 10 18.1819C10.4138 18.1819 10.8216 18.084 11.1903 17.8961C11.5589 17.7082 11.8778 17.4357 12.1209 17.1008C14.9842 13.1517 16.5617 10.0542 16.5617 8.37917C16.5597 6.63952 15.8677 4.97168 14.6376 3.74156C13.4075 2.51144 11.7397 1.81949 10 1.8175Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_648">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
