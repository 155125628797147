export const CalendarStarIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_17_1305)">
        <path
          d="M15.8333 2.07487H15V1.24154C15 0.781536 14.6267 0.408203 14.1667 0.408203C13.7067 0.408203 13.3333 0.781536 13.3333 1.24154V2.07487H6.66667V1.24154C6.66667 0.781536 6.29333 0.408203 5.83333 0.408203C5.37333 0.408203 5 0.781536 5 1.24154V2.07487H4.16667C1.86917 2.07487 0 3.94404 0 6.24154V16.2415C0 18.539 1.86917 20.4082 4.16667 20.4082H15.8333C18.1308 20.4082 20 18.539 20 16.2415V6.24154C20 3.94404 18.1308 2.07487 15.8333 2.07487ZM4.16667 3.74154H15.8333C17.2117 3.74154 18.3333 4.8632 18.3333 6.24154V7.07487H1.66667V6.24154C1.66667 4.8632 2.78833 3.74154 4.16667 3.74154ZM15.8333 18.7415H4.16667C2.78833 18.7415 1.66667 17.6199 1.66667 16.2415V8.74154H18.3333V16.2415C18.3333 17.6199 17.2117 18.7415 15.8333 18.7415ZM13.6642 13.2015C13.6642 13.5174 13.4442 13.7832 13.1925 13.9232L11.8892 14.6482L12.4675 16.2257C12.5792 16.5315 12.4783 16.874 12.2183 17.0699C11.9525 17.2699 11.5858 17.2674 11.3225 17.064L10.0258 16.0615L8.72917 17.064C8.46583 17.2674 8.09917 17.2699 7.83333 17.0699C7.57333 16.874 7.4725 16.5315 7.58417 16.2257L8.1625 14.6482L6.85917 13.9232C6.6075 13.7832 6.3875 13.5174 6.3875 13.2015C6.3875 12.934 6.62 12.6382 6.99667 12.6382H8.86917L9.36583 10.7357C9.44417 10.434 9.715 10.224 10.0258 10.2182C10.3367 10.224 10.6075 10.434 10.6858 10.7357L11.1825 12.6382H13.055C13.4317 12.6382 13.6642 12.9332 13.6642 13.2015Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_1305">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.408203)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
