export const StarFillIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3_687)">
        <path
          d="M1.10581 10.3333L4.07248 12.5L2.94581 15.9892C2.76374 16.5303 2.76143 17.1158 2.93924 17.6584C3.11704 18.201 3.46543 18.6716 3.93248 19C4.39152 19.339 4.9478 19.5206 5.51844 19.5177C6.08907 19.5149 6.64351 19.3277 7.09914 18.9842L9.99998 16.8492L12.9016 18.9817C13.3599 19.3187 13.9131 19.5018 14.482 19.5046C15.0508 19.5073 15.6058 19.3297 16.0673 18.9971C16.5288 18.6645 16.8729 18.1942 17.0502 17.6537C17.2275 17.1133 17.2289 16.5305 17.0541 15.9892L15.9275 12.5L18.8941 10.3333C19.3518 9.99876 19.6919 9.52813 19.8661 8.98866C20.0403 8.44919 20.0395 7.86849 19.8639 7.32949C19.6883 6.79049 19.3468 6.32077 18.8883 5.98742C18.4298 5.65407 17.8777 5.47414 17.3108 5.47334H13.6666L12.5608 2.02667C12.3869 1.48413 12.0452 1.01085 11.585 0.675057C11.1247 0.339267 10.5697 0.158325 9.99998 0.158325C9.43025 0.158325 8.87525 0.339267 8.415 0.675057C7.95475 1.01085 7.61304 1.48413 7.43914 2.02667L6.33331 5.47334H2.69248C2.1256 5.47414 1.57347 5.65407 1.11496 5.98742C0.656451 6.32077 0.315012 6.79049 0.139412 7.32949C-0.0361873 7.86849 -0.0369645 8.44919 0.137191 8.98866C0.311348 9.52813 0.651528 9.99876 1.10914 10.3333H1.10581Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_687">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
